.header-top .outer-container a{
  text-decoration: none;
}

.header-style-two.sticky {
  background-color: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

body {
  margin: 0;
  font-family: 'Arial', sans-serif;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  padding: 15px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

.nav-content {
  display: flex;
  justify-content: flex-end;
}

.hamburger-menu {
  display: none;
  cursor: pointer;
  flex-direction: column;
}

.bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px 0;
}

.nav-links {
  display: flex;
}

.nav-links ul {
  list-style: none;
  padding: 0;
  display: flex;
}

.nav-links li {
  margin: 0 10px;
}

.nav-links a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .hamburger-menu {
    display: flex;
  }

  .nav-links {
    opacity: 0;
    transform: translateX(100%);
    flex-direction: column;
    position: absolute;
    top: 80px; /* Adjust according to your navbar height */
    right: 0;
    background-color: #333;
    width: 100%;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }

  .nav-links.show {
    opacity: 1;
    transform: translateX(0);
    display: flex;
  }

  .nav-links ul {
    flex-direction: column;
    align-items: flex-start;
  }

  .nav-links li {
    margin: 10px 0;
  }
}
