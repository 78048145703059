/* src/components/FloatingIcons.css */
.floating-icons {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .iconz {
    font-size: 2.5rem;
    color: white;
    background-color: #25D366; /* Default WhatsApp color */
    padding: 5px;
    border-radius: 50%;
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    position: relative;
  }
  
  .whatsapp {
    background-color: #25D366;
    animation: blink 1s infinite;
  }
  
  .youtube {
    background-color: #FF0000;
  }
  
  .phone {
    background-color: #F54C4C;
  }
  
  .iconz:hover {
    transform: scale(1.1) rotateY(20deg) rotateX(10deg);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  }
  
  .tooltip {
    position: absolute;
    bottom: 110%; /* Adjusted to move above the icon */
    left: -90%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.75);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, transform 0.3s, visibility 0.3s;
    font-size: 0.875rem;
  }
  
  .phone:hover .tooltip {
    opacity: 1;
    visibility: visible;
    transform: translateX(-50%) translateY(-10px);
  }
  
  /* @keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
   */