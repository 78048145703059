.whoweare2-section {
    padding: 60px 0;
}

.whoweare2-section a{
    text-decoration: none;
}

.whoweare-text .icon {
    color: #D73439;
    font-size: 16px;
    line-height: 26px;
    font-weight: 800;
    margin-bottom: 14px;
}

.whoweare-text .text {
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 26px;
    font-weight: 800;
    margin-bottom: 14px;
    text-transform: uppercase;
    padding-left: 10px;
    color: #D73439;
}

.whoweare2-section .glimpses h2{
    font-family: 'Outfit';
    font-size: 48px;
    color: #D73439;
    line-height: 58px;
}

.whoweare2-section ul {
    padding: 0;
}

/* .whoweare2-section li {
    margin-top: 30px;
} */

.funfact-section{
    position: relative;
    padding: 120px 0px;
  }
  
  .funfact-section .content-box{
    position: relative;
    display: block;
    margin-right: 150px;
    background: #0c0c0c;
    border-radius: 25px;
    padding: 62px 50px 67px 50px;
  }
  
  .funfact-section .content-box .text-box p{
    color: #aca7a7;
    margin-bottom: 32px;
  }
  
  .funfact-section .content-box .sec-title{
    margin-bottom: 21px;
  }
  
  .funfact-section .big-text{
    position: absolute;
    top: -90px;
    opacity:60%;
    right: -125px;
    font-size: 110px;
    line-height: 100px;
        font-family: 'Outfit', sans-serif;
    color: #fff;
    font-weight: 900;
    text-transform: uppercase;
    text-shadow: 3px 0px 0px #e4dcd8;
    writing-mode: vertical-lr;
  }
  
  .funfact-block-one{
    position: relative;
    padding-bottom: 40px;
  }
  
  .funfact-block-one .inner-box{
    position: relative;
    background: #F8EFEA !important;
    display: block;
    text-align: center;
    border: 1px dashed #c9c8c3;
    border-radius: 25px;
    padding: 31px 20px 0px 20px;
  }
  
  .funfact-block-one .inner-box .count-outer{
    position: relative;
    display: inline-block;
    font-size: 48px;
    line-height: 50px;
        font-family: 'Outfit', sans-serif;
    font-weight: 700;
    color: #8B8B8B ;
    margin-bottom: 2px;
  }
  
  .funfact-block:first-child .funfact-block-one:first-child .inner-box .count-outer{
    color: #fd6145;
  }
  
  .funfact-block:first-child .funfact-block-one:last-child .inner-box .count-outer{
    color: #fb991a;
  }
  
  .funfact-block:first-child .funfact-block-one:first-child .inner-box{
    margin-bottom: 30px;
  }
  
  .funfact-block-one .inner-box .count-outer .symble{
    position: relative;
    font-size: 30px;
    top: -16px;
  }
  
  .funfact-block-one .inner-box .icon-box{
    position: relative;
    display: inline-block;
    width: 70px;
    height: 70px;
    line-height: 76px;
    background: #fff;
    font-size: 30px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06);
    margin-bottom: -15px;
    border-radius: 50%;
    color: #8B8B8B;
    bottom: -20px;
  }
  
  .funfact-block:first-child .funfact-block-one:first-child .inner-box .icon-box{
    color: #fd6145;
  }
  
  .funfact-block:first-child .funfact-block-one:last-child .inner-box .icon-box{
    color: #fb991a;
  }
  
  .funfact-block-one .inner-box p{
    position: relative;
    display: block;
    margin-bottom: 2px;
  }
  
  
  /** about-section **/
  
  .about-section{
    position: relative;
    padding: 120px 0px;
  }
  
  .about-section .image-box{
    position: relative;
    display: block;
    padding: 239px 120px 0px 0px;
    margin-right: 20px;
  }
  
  .about-section .image-box img{
    width: 100%;
  }
  
  .about-section .image-box .image-1{
    position: relative;
    border-radius: 25px;
  }
  
  .about-section .image-box .image-1 img,
  .about-section .image-box .image-2 img{
    border-radius: 25px;
  }
  
  .about-section .image-box .image-2{
    position: absolute;
    top: 0px;
    right: 0px;
    border-radius: 25px;
  }
  
  .about-section .image-box .image-3{
    position: absolute;
    right: 0px;
    bottom: 57px;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px dashed #dcd9e4;
  }
  
  .about-section .image-box .image-3 img{
    border-radius: 10px;
  }
  
  .about-section .image-box .image-4{
    position: absolute;
    top: 75px;
    left: 0px;
  }
  
  .about-section .image-box .image-shape .shape{
    position: absolute;
    border-radius: 50%;
    border: 1px dashed #fc7f5a;
  }
  
  .about-section .image-box .image-shape .shape-1{
    left: -103px;
    top: 0px;
    width: 440px;
    height: 440px;
  }
  
  .about-section .image-box .image-shape .shape-2{
    left: -83px;
    top: 20px;
    width: 400px;
    height: 400px;
  }
  
  .about-section .image-box .image-shape .shape-3{
    left: -63px;
    top: 40px;
    width: 360px;
    height: 360px;
  }
  
  .about-section .content-box{
    position: relative;
    display: block;
  }
  
  .about-section .content-box .sec-title{
    margin-bottom: 30px;
  }
  
  .about-section .content-box .text-box{
    margin-bottom: 40px;
  }
  
  .about-section .content-box .text-box p{
    margin-bottom: 32px;
  }
  
  .highlights-list li{
    position: relative;
    display: block;
    padding-left: 60px;
    margin-bottom: 15px;
  }
  
  .highlights-list li:last-child{
    margin-bottom: 0px;
  }
  
  .highlights-list li i{
    position: absolute;
    display: inline-block;
    left: 0px;
    top: 0px;
    width: 52px;
    height: 52px;
    line-height: 58px;
    background: #faf1ef;
    text-align: center;
    font-size: 24px;
    border-radius: 15px;
    text-align: center;
  }
  
  .highlights-list li:nth-child(2) i{
    color: #fb991a;
    background: #faeddc;
  }
  
  .highlights-list li:last-child i{
    color: #8B8B8B;
    background: #faf1ef;
  }
  
  .highlights-list li a{
    position: relative;
    display: inline-block;
    font-size: 16px;
    line-height: 26px;
        font-family: 'Outfit', sans-serif;
    color: #0c0c0c;
    font-weight: 800;
    padding: 12px 20px;
    border: 1px dashed #d8d2d0;
    border-radius: 15px;
    text-transform: uppercase;
    text-decoration: none;
    }

  
  .highlights-list li:nth-child(2) a:hover{
    border-color: #fb991a;
  }
  
  .highlights-list li:last-child a:hover{
    border-color: #8B8B8B;
  }
  
  .highlights-list li a span{
    position: relative;
    transition: all 500ms ease;
  }
  
  /* .highlights-list li a:hover span{
    padding-right: 34px;
  } */
  
  /* .highlights-list li a span:before{
    position: absolute;
    content: "\f10e";
    font-family: 'flaticon';
    font-size: 30px;
    top: -3px;
    right: 0px;
    opacity: 0;
    font-weight: 400;
    transition: all 500ms ease;
  } */
  
  .highlights-list li a:hover span:before{
    opacity: 1;
  }
  
  .highlights-list li:nth-child(2) a span:before{
    color: #fb991a;
  }
  
  .highlights-list li:last-child a span:before{
    color: #8B8B8B;
  }
  
  
  /** categories-section **/
  
  .categories-section{
    position: relative;
  }
  
  .categories-block-one .inner-box{
    position: relative;
    display: block;
    height: 500px;
    background: #ffffff;
    border-radius: 25px;
    padding: 32px 20px 0px 20px;
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
    margin-bottom: 60px;
    overflow: hidden;
    transition: all 500ms ease;
  }
  
  .categories-block-one .inner-box .shape{
    position: absolute;
    top: 0px;
    right: 0px;
    width: 139px;
    height: 139px;
    background-repeat: no-repeat;
    transition: all 500ms ease;
  }
  
  .categories-block-one .inner-box:hover .shape{
    opacity: 0;
  }
  
  .categories-block-one .inner-box .overlay-shape{
    position: absolute;
    top: 0px;
    right: 0px;
    width: 139px;
    height: 139px;
    opacity: 0;
    background-repeat: no-repeat;
    transition: all 500ms ease;
  }
  
  .categories-block-one .inner-box:hover .overlay-shape{
    opacity: 1;
  }
  
  .categories-block-one .inner-box:hover{
    transform: translateY(-10px);
  }
  
  .categories-block-one .inner-box h3{
    position: relative;
    display: block;
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    margin-bottom: 8px;
  }
  
  .categories-block-one .inner-box h3 a{
    position: relative;
    display: inline-block;
    color: #0c0c0c;
    text-decoration: none;
  }
  

  
  .categories-block-one .inner-box p{
    display: block;
    margin-bottom: 32px;
  }
  
  .categories-block-one .inner-box .image-box{
    position: relative;
    display: inline-block;
    max-width: 220px;
    width: 100%;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 40px !important;
  }
  
  .categories-block-one .inner-box .image-box img{
    width: 100%;
    /* border-radius: 50%; */
    transition: all 500ms ease;
  }
  
  .categories-block-one .inner-box .image-box:before,
  .categories-block-one .inner-box .image-box:after{
    position: absolute;
    content: '';
    background: #fff;
    width: 5px;
    height: 100%;
    top: 0px;
    z-index: 1;
  }
  
  .categories-block-one .inner-box .image-box:before{
    left: 0px;
  }
  
  .categories-block-one .inner-box .image-box:after{
    right: 0px;
  }
  
  .categories-block-one .inner-box .link-box{
    position: relative;
    display: block;
    padding: 17px 0px 18px 0px;
  }
  
  .categories-block-one .inner-box .link-box:before{
    position: absolute;
    content: '';
    border-top: 1px dashed #d8d2d0;
    width: calc(100% + 40px);
    height: 1px;
    left: -20px;
    top: 0px;
  }
  
  .categories-block-one .inner-box .link-box a{
    position: relative;
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
        font-family: 'Outfit', sans-serif;
    color: #0c0c0c;
    font-weight: 800;
    text-transform: uppercase;
    padding-left: 32px;
  }
  
  
  .categories-block-one .inner-box .link-box a:before {
    position: absolute;
    content: "\f10e";
    font-family: 'flaticon';
    font-size: 24px;
    top: -1px;
    left: 0px;
    font-weight: 400;
    transition: all 500ms ease;
  }
  
  
  /** project-section **/
  
  .project-section{
    position: relative;
    padding: 115px 0px 90px 0px;
  }
  
  .sec-title .btn-box{
    position: absolute;
    right: 0px;
    bottom: 12px;
  }
  
  .project-block-one .inner-box{
    position: relative;
    display: block;
    margin-bottom: 30px;
  }
  
  .project-block-one .inner-box:before{
    position: absolute;
    content: '';
    border: 1px dashed #d8d2d0;
    border-radius: 25px;
    width: calc(100% + 30px);
    height: calc(100% + 16px);
    left: -15px;
    bottom: -1px;
    opacity: 0;
    transition: all 500ms ease;
  }
  
  .project-block-one .inner-box:hover:before{
    opacity: 1;
  }
  
  .project-block-one .inner-box .image-box{
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 25px;
  }
  
  .project-block-one .inner-box .image-box img{
    width: 100%;
    border-radius: 25px;
    transition: all 500ms ease;
  }
  
  .project-block-one .inner-box:hover .image-box img{
    transform: scale(1.05);
  }
  
  .project-block-one .inner-box .content-box{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
  }
  
  .project-block-one .inner-box h6{
    position: absolute;
    display: inline-block;
    font-size: 14px;
    line-height: 24px;
    color: #fff;
    font-weight: 800;
    text-transform: uppercase;
    padding: 6px 15px 2px 15px;
    border-radius: 25px;
    left: 0px;
    top: 0px;
    opacity: 0;
    transition: all 500ms ease;
  }
  
  .project-block-one .inner-box:hover h6{
    opacity: 1;
    left: 20px;
    top: 20px;
  }
  
  .project-block-one .inner-box h3{
    position: absolute;
    left: 0px;
    bottom: -20px;
    width: 100%;
    text-align: center;
    background: #fff;
    padding: 17px 15px;
    font-size: 24px;
    line-height: 34px;
    font-weight: 700;
    border-radius: 0px 0px 5px 5px;
    opacity: 0;
    transition: all 500ms ease;
  }
  
  .project-block-one .inner-box:hover h3{
    bottom: 0px;
    opacity: 1;
  }
  
  .project-block-one .inner-box h3 a{
    display: inline-block;
    color: #0c0c0c;
  }
  

  
  .project-block-one .inner-box .link-btn{
    position: absolute;
    right: 20px;
    bottom: 88px;
  }
  
  .project-block-one .inner-box .link-btn a{
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 48px;
    background: #fff;
    text-align: center;
    font-size: 30px;
    color: #0c0c0c;
    border-radius: 50%;
    font-weight: 400;
    transform: scale(0,0);
  }
  
  .project-block-one .inner-box .link-btn a:hover{
    color: #fff;
  }
  
  .project-block-one .inner-box:hover .link-btn a{
    transform: scale(1);
  }
  
  .project-block-one .inner-box .view-btn{
    position: absolute;
    right: 66px;
    bottom: 91px;
  }
  
  .project-block-one .inner-box .view-btn a{
    position: relative;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 44px;
    background: #fff;
    text-align: center;
    font-size: 16px;
    color: #0c0c0c;
    border-radius: 50%;
    font-weight: 400;
    transform: scale(0,0);
  }
  
  .project-block-one .inner-box .view-btn a:hover{
    color: #fff;
  }
  
  .project-block-one .inner-box:hover .view-btn a{
    transform: scale(1);
  }
  
  .project-section .sortable-masonry .filters{
    position: relative;
    display: block;
    overflow-x: auto;
    max-width: 1170px;
  }
  
  .sortable-masonry .filters-box{
    position: relative;
    margin-bottom: 0;
  }
  
  .project-section .sortable-masonry .filters-box:before{
    position: absolute;
    content: '';
    width: 50px;
    height: 100%;
    top: 0px;
    right: 0px;
    box-shadow: inset -25px 0px 20px 0px rgb(255 255 255 / 90%);
    z-index: 1;
  }
  
  .project-section .sortable-masonry .filter-tabs{
    position: relative;
  }
  
  .project-section .sortable-masonry .filter-tabs li{
    position: relative;
    display: inline-block;
    float: left;
    margin-right: 11px;
    padding-right: 41px;
    font-size: 20px;
    line-height: 34px;
        font-family: 'Outfit', sans-serif;
    font-weight: 600;
    color: #afa7a7;
    cursor: pointer;
    transition: all 500ms ease;
  }
  
  .project-section .sortable-masonry .filter-tabs li:last-child{
    padding: 0px !important;
    margin: 0px !important;
  }
  
  .sortable-masonry ::-webkit-scrollbar {
    display: none !important;
  }
  
  .project-section .sortable-masonry .filter-tabs li:before{
    position: absolute;
    content: "\f117";
    font-family: 'flaticon';
    font-size: 30px;
    top: 2px;
    right: 0px;
    color: #b0a8a8;
    font-weight: 400;
  }
  
  .project-section .sortable-masonry .filter-tabs li:last-child:before{
    display: none;
  }
  
  .project-section .sec-title{
    margin-bottom: 42px;
  }
  
  .bg-color-1{
    background-color: #faeddd;
  }