.whysumago-section {
    background-color: #FFDDDD;
    padding-top: 40px;
}

.whysumago-section .sec-title {
    justify-content: center;
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 20px;
}

.whysumago-section .cardText .firstCardP {
    margin-top: 42px;
}

.whysumago-section .cardText {
    min-height: 200px;
}

@media only screen and (max-width: 768px) { 
    .whysumago-section .cardText .firstCardP {
        margin-top: 0;
    }

    .whysumago-section .cardText {
        min-height: 0;
    }
}