.technologies-section {
    margin: 40px 0;
}

.technologies-section .top-text {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.technologies-section .top-text h2 {
    font-family: 'Outfit';
    font-size: 30px;
    line-height: 34px;
    color: #0c0c0c;
}

.technologies-section .testimonialLeftArrow {
  position: absolute;
  top: 40%;
  right: 1%;
  z-index: 1;
  /* background-color: #0c0c0c; */
  border-radius: 50%;
  padding: 10px 18px;
  opacity: 80%;
}

.technologies-section .testimonialLeftArrow i {
  font-size: 32px;
}
.question-cards:hover{    scale: 1.1;
  transition: 0.3s ease-in-out;}

.technologies-section .testimonialRightArrow {
  position: absolute;
  top: 40%;
  left: 1%;
  z-index: 1;
  /* background-color: #0c0c0c; */
  border-radius: 50%;
  padding: 10px 18px;
  opacity: 80%;
}

.technologies-section .testimonialRightArrow i {
  font-size: 32px;
}

.technologies-section .img-container img {
  width: 100%;
  height: auto;
  padding: 8px;
  
}
  
  @media only screen and (min-width: 540px) and (max-width: 990px) {
  .technologies-section .slider-container {
    padding: 0;
  }
}

@media only screen and (min-width: 990px) {
  .technologies-section .slider-container {
    padding: 0 140px 0 140px;
  }
}

.slider-container .slick-prev::before, .slick-next::before {
  color: black !important;
}
