/*--------------------------theme check----------------------------------*/
	
	#wp-calendar {
		width: 100%;
	}
	#wp-calendar caption {
		text-align: center; 
		color: #333; 
		font-size: 16px; 
		padding-bottom:0px;
		font-weight:700;
	}
	#wp-calendar thead { 
		font-size: 14px;
	}
	#wp-calendar thead th { 
		padding: 8px;
		border: 1px solid #eaeaea;
		text-align: center;
		border-radius: 0px;
		color: #fff;
		background: #fd6145;
	}
	#wp-calendar tbody { 
		color: #aaa; 
	}
	#wp-calendar tbody td { 
		background: #f0f0f0;
		border: 1px solid #ffffff;
		text-align: center;
		padding: 8px;
		color: #555;
	}
	#wp-calendar tbody td:hover { 
		background: #fff; 
	}
	#wp-calendar tbody .pad { 
		background: none; 
	}
	#wp-calendar tfoot tr{
		background:#fd6145;
	}
	#wp-calendar tfoot #next { 
		font-size: 14px; 
		text-transform: uppercase; 
		text-align: right;
		padding: 10px; 
	}
	#wp-calendar tfoot #prev { 
		font-size: 14px; 
		text-transform: uppercase; 
		padding: 10px; 
	}
	.wp-caption {
	text-align: center;
	background-color: #f3f3f3;
	padding-top: 4px;
	margin: 10px;
	-moz-border-radius: 3px;
	-khtml-border-radius: 3px;
	-webkit-border-radius: 3px;
	border-radius: 3px;
	}
	.wp-caption p.wp-caption-text {
	font-size: 11px;
	line-height: 17px;
	padding: 0 4px 5px;
	margin: 0;
	}
	.gallery-caption {
		font-size:15px;
		color:#777777;
		line-height:1.8em;
		font-weight:400;
		background:#ffffff;
		-webkit-font-smoothing: antialiased;
		-moz-font-smoothing: antialiased;
		font-family: 'Lato', sans-serif;	
	}
	.bypostauthor{
		font-size:15px;
		color:#777777;
		line-height:1.8em;
		font-weight:400;
		background:#ffffff;
		-webkit-font-smoothing: antialiased;
		-moz-font-smoothing: antialiased;
		font-family: 'Lato', sans-serif;		
	}
	.alignright{ 
		text-align:right;
	}
	.alignleft{ 
		text-align:left;
	}
	.aligncenter{ 
		text-align:center;
	}	
	.alignnone {
    margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright {
    float:right;
    margin: 5px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

a img.alignnone {
    margin: 5px 20px 20px 0;
}

a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto
}

.wp-caption {
    background: #fff;
    max-width: 96%; /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}

.wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
}

/* Text meant only for screen readers. */
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000; /* Above WP toolbar. */
}
/*************************************Theme Unit Testing***********************/
.thm-unit-test .tag-sticky-2 .news-block-one .inner-box::before,
.thm-unit-test .sticky .news-block-one .inner-box:before{
	background:#fafafa;
	border:2px dashed #fd6145;
}
.tag-sticky-2 .news-block-one .inner-box .image-box .image-content.te-adjustment,
.sticky .news-block-one .inner-box .image-box .image-content.te-adjustment{
	border:2px dashed #fd6145;
}
.thm-unit-test h1,.thm-unit-test h2,.thm-unit-test h3,.thm-unit-test h4,.thm-unit-test h5,.thm-unit-test h6{
	display: block;
	margin-bottom:15px;
	font-weight:700;
}
.thm-unit-test table{
	margin-bottom:30px;
	width:100%;
}
.thm-unit-test table thead tr th{
	border: 1px solid #ddd;
    vertical-align: bottom;
	padding:8px;
}
.thm-unit-test table tbody tr th{
	border: 1px solid #ddd;
    line-height: 1.42857;
    padding: 8px;
    vertical-align: top;
}
.thm-unit-test table tbody tr th a,
.thm-unit-test table tbody tr td a{
	color:#fd6145;
	font-weight:400;
}
.thm-unit-test table tbody tr td{
	border: 1px solid #ddd;
    line-height: 1.42857;
    padding: 8px;
    vertical-align: top;
}
.thm-unit-test .woocommerce table tbody tr td,
.thm-unit-test .woocommerce table tbody tr th,
.thm-unit-test .woocommerce table thead tr th{
	border: none;
}
.thm-unit-test .text > ul,.thm-unit-test .text > ol,.thm-unit-test > ol,.thm-unit-test > ul{
	padding-left:18px;
	margin-bottom:20px;
}
.thm-unit-test .text > ul li ul,.thm-unit-test .text > ul li ul li ul,.thm-unit-test > ul li ul,.thm-unit-test > ul li ul li ul,
.thm-unit-test .text > ol li ol,.thm-unit-test .text > ol li ol li ol,.thm-unit-test > ol li ol,.thm-unit-test > ol li ol li ol{
	padding-left:40px;
}
.thm-unit-test .text > ul li,
.thm-unit-test > ul li{
	list-style-type:disc;
	display:list-item;
}
.thm-unit-test .text > ul li ul li,
.thm-unit-test > ul li ul li{
	list-style-type:circle;
}
.thm-unit-test .text > ul li ul li ul li,
.thm-unit-test > ul li ul li ul li{
	list-style-type:square;
}
.thm-unit-test ol li{
	list-style-type:decimal;
	display:list-item;
}
.thm-unit-test ol li ol li{
	list-style-type:lower-alpha;
}
.thm-unit-test ol li ol li ol li{
	list-style-type:upper-roman;
}
.thm-unit-test p img,.thm-unit-test figure img,.thm-unit-test img{
	max-width:100%;
	height:auto;
	clear:both;
}
figure{
	max-width:100%;
	display:block;
}

/****************Custom Widget*********************/
.widget select{
	width:100%;
	display:block;
	border:1px solid #ededed;
	padding:15px 25px;
	
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-repeat: no-repeat;
	background-position: right center;
}
.textwidget img {
    height: auto;
    max-width: 100%;
}
.widget ul li ul{
	padding:0px;
	margin:0px;
}

/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
    margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.alignright {
    float:right;
    margin: 5px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

a img.alignnone {
    margin: 5px 20px 20px 0;
}

a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.wp-caption {
    background: #fff;
    max-width: 96%; /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}

.wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0 4px 5px;
}

/* Text meant only for screen readers. */
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
	background-color: #eee;
	clip: auto !important;
	clip-path: none;
	color: #444;
	display: block;
	font-size: 1em;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
	/* Above WP toolbar. */
}
/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
	float: left;
	margin-right: 1.5em;
}

.alignright {
	float: right;
	margin-left: 1.5em;
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

/*********************WP Gallery*********************/
.gallery figure{
	display:inline-block;
}

.gallery-columns-1,.gallery-columns-2,.gallery-columns-3,.gallery-columns-4,.gallery-columns-5,
.gallery-columns-6,.gallery-columns-7,.gallery-columns-8,.gallery-columns-9{
	margin-left:-15px;
	margin-right:-15px;
}
.gallery-columns-1 .gallery-item,.gallery-columns-2 .gallery-item,.gallery-columns-3 .gallery-item,
.gallery-columns-4 .gallery-item,.gallery-columns-5 .gallery-item,.gallery-columns-6 .gallery-item,
.gallery-columns-7 .gallery-item,.gallery-columns-8 .gallery-item,.gallery-columns-9 .gallery-item{
	padding-left:15px;
	padding-right:15px;
}
.gallery-columns-1 .gallery-item{
	width:100%;
}
.gallery-columns-2 .gallery-item{
	width:50%;
}
.gallery-columns-3 .gallery-item{
	width: 33.33333333%;
}
.gallery-columns-4 .gallery-item{
	width: 25%;
}
.gallery-columns-5 .gallery-item{
	width: 20%;
}
.gallery-columns-6 .gallery-item{
	width:16.66666666%;
}
.gallery-columns-7 .gallery-item{
	width: 14.2857143%;
}
.gallery-columns-8 .gallery-item{
	width: 12.5%;
}
.gallery-columns-9 .gallery-item{
	width: 11.11111111%;
}

/************************** Widget Rss*****************************/
.widget_rss.footer-widget ul li{
	margin-bottom:20px;
}
.widget_rss.footer-widget ul li:before{
	display:none;
}
.widget_rss.widget ul li{
	padding-left:0px;
}
.widget_rss.widget ul li a,
.widget_rss.footer-widget ul li a{
	font-weight:800;
	padding-left:0px;
}
.widget_rss.footer-widget ul li a{
	font-size:18px;
	line-height:1.4em;
}
.widget_rss.widget ul li .rss-date,
.widget_rss.footer-widget ul li .rss-date{
	color:#fd6145;
	display:block;
	margin:10px 0;
}
.widget_rss.widget ul li:before{
	display:none;
}
.widget_rss.widget ul li .rssSummary{
	font-size:14px;
	line-height:1.6em;
	font-weight:400;
}
.widget_rss.footer-widget ul li .rssSummary{
	font-size:16px;
	color:#F3F3F3;
}
.widget_rss.widget ul li cite,
.widget_rss.footer-widget ul li cite{
	color:#fd6145;
	display:block;
	font-size:16px;
	margin-top:10px;
}
.widget_rss.footer-widget ul li cite{
	color:#fecd1a;
}
.widget_rss.footer-widget ul li{
	border:1px solid #fd6145;
	padding: 20px;
}
.widget_rss.footer-widget ul li .rss-date{
	margin-top:10px;
	color:#fecd1a;
}
.blog-link .left-btn a{
	margin:0 10px 0 0;
}

/*********************Custom Navigation Styling************************/
.paginate-links a,
.paginate-links > span {
    margin: 0 5px 7px 0;
    position: relative;
	border: 1px solid #fd6145;
    color: #fd6145;
    display: inline-block;
    font-size: 14px;
    font-weight: 800;
    line-height: 44px;
    min-width: 46px;
	height:46px;
    text-align: center;
    text-transform: capitalize;
    transition: all 500ms ease 0s;
}
.paginate-links a:hover,
.paginate-links > span{
	background: #fd6145 none repeat scroll 0 0;
    border-color: #fd6145;
    color: #ffffff;
}
.post-password-form label{
	margin-bottom:0px;
}
.post-password-form input[type="password"]{
	border:1px solid #fd6145;
	padding:5px 10px;
	display:block;
	margin-top:15px;
}
.post-password-form input[type="submit"]{
	background:#fd6145;
	color:#fff;
	padding:6px 15px; 
	display:inline-block;
	border:none;
	
}
.thm-unit-test .posts-nav{
	margin-top:50px;
	padding-top:50px;
	border-top:1px solid #e4e4e4;
}
.thm-unit-test .posts-nav .pull-left a,
.thm-unit-test .posts-nav .pull-right a{
	display:block;
}
.thm-unit-test .posts-nav .pull-right a{
	text-align:right;
}
.thm-unit-test .posts-nav a h6{
	margin-bottom:6px;
}
.thm-unit-test .posts-nav a span{
	position:absolute;
	left:0px;
	top:0px;
	color:#ffffff;
	font-size:14px;
	background:#fd6145;
}
.thm-unit-test .posts-nav .pull-right a span{
	left:auto;
	right:0px;
}
.thm-unit-test .posts-nav a:hover{
	color:#fd6145;
}
.thm-unit-test .posts-nav a:hover span{
	background:#fd6145;
}
.thm-unit-test .posts-nav .pull-left{
	float:left;
}
.thm-unit-test .posts-nav .pull-right{
	float:right;
}
.thm-unit-test .posts-nav a{
	font-size:15px;
	color:#333333;
	padding-top:9px;
}
.thm-unit-test .posts-nav a:hover{
	color:#fd6145;
}

/*********************Comments Styling*******************/
.sidebar-page-container .shina_theme .group-title h2,
.sidebar-page-container .group-title h2{
	margin-bottom:0px;
}

.pingback .thumb-box,.trackback .thumb-box{
	display:none !important;
}
.pingback,.trackback{
	padding-left:0px !important;
}
.pingback .text-holder, .trackback .text-holder{
	padding-left:0px !important;
}
.no-comments{
	display:inline-block;
	background:#fd6145;
	padding:8px 15px;
	color:#ffffff;
	margin-bottom: 0px;
    margin-top: 30px;
}

/*********************Custom Footer widget Styling************************/
.footer-widget ul li ul li a{
	line-height:20px;
}
.footer-widget select{
	width:100%;
	padding:10px 15px;
	color:#222;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background-repeat: no-repeat;
	background-position: right center;
	border:1px solid #777;
	background-color:#fbfbfb;
}
.footer-widget #wp-calendar caption{
	padding:0px;
	margin:15px 0 0px;
	color: #F3F3F3;
	font-size:14px;
}
.footer-widget #wp-calendar thead th{
	border:1px solid #fafafa;
	border-bottom:none;
	text-align:center;
	padding:0px;
	line-height:30px;
	color:#ffffff;
}
.footer-widget #wp-calendar tbody .pad{
	background:#fafafa;
}
.news-block-large .image img{
	max-width:100%;
	width:auto;
}
.blog-single .news-block-two {
    margin-bottom: 0px;
}
.page .paginate-links{
	margin-top:30px;
}
.thm-unit-test > p,
.thm-unit-test .text p{
	margin-bottom:25px;
}
.thm-unit-test > p:last-child,
.thm-unit-test .text p:last-child,
.thm-unit-test blockquote p{
	margin-bottom: 0px;
}
.footer-widget .tagcloud{
	display:flex;
	flex-wrap:wrap;
}
.footer-widget .tagcloud a{
	font-size:14px !important;
	padding:5px 20px;
	background:#F3F3F3;
	border:none;
	color:#071311;
	margin-right:5px;
	margin-bottom:5px;
	-webkit-transition:all 600ms ease;
	-ms-transition:all 600ms ease;
	-o-transition:all 600ms ease;
	-moz-transition:all 600ms ease;
	transition:all 600ms ease;
} 
.footer-widget .tagcloud a:hover{
	background:#fd6145;
	color:#ffffff;
}
/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
    margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 30px auto;
}

.alignright {
    float:right;
    margin: 5px 0 20px 20px;
}

.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.alignright {
    float: right;
    margin: 5px 0 20px 20px;
}

a img.alignnone {
    margin: 5px 20px 20px 0;
}

a img.alignleft {
    float: left;
    margin: 5px 20px 20px 0;
}

a img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.wp-caption {
    background: #fff;
    max-width: 96%; /* Image does not overflow the content area */
    padding: 5px 3px 10px;
    text-align: center;
}

.wp-caption.alignnone {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
    margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
    margin: 5px 0 20px 20px;
}

.wp-caption img {
    border: 0 none;
    height: auto;
    margin: 0;
    max-width: 98.5%;
    padding: 0;
    width: auto;
}

.wp-caption p.wp-caption-text {
    font-size: 11px;
    line-height: 17px;
    margin: 0;
    padding: 0px 4px 0px;
}

/* Text meant only for screen readers. */
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
	background-color: #eee;
	clip: auto !important;
	clip-path: none;
	color: #444;
	display: block;
	font-size: 1em;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
	/* Above WP toolbar. */
}
figcaption{
	padding:10px 0;
}
/**************New****************/
dd,dt{
	margin-bottom:10px;
}
.wp-block-cover p:not(.has-text-color){
	color:#ffffff;
}
.wp-block-button{
	margin-bottom:25px;
}
.thm-unit-test .text > ul li.blocks-gallery-item, .thm-unit-test > ul li.blocks-gallery-item{
	display:inline-block;
}

.sidebar-title h4 a,
.main-footer .footer-widget h4 a{
	color:inherit;
}
dl dd a{
	color:#fd6145;
}
#wp-calendar tfoot tr td a{
	color:#ffffff;
}
.single-footer-widget .title h3 a{
	color:inherit;
}
.footer-widget .wp-calendar-nav-prev a{
	color:#F3F3F3;
}
.footer-widget.widget_text .textwidget p{
	color:#F3F3F3;
}
.footer-widget .textwidget p.wp-caption-text{
	color:#222222;
}

.wp-block-group,
.wp-block-post-excerpt{
	margin-bottom:20px;
}

.wp-block-post-date{
	margin-bottom:10px;
}