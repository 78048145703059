.text-stroke,
.text-stroke--primary,
.text-stroke--secondary {
    -webkit-text-stroke-width: var(--wp--custom--typography--text-stroke-width, 2px) !important
}

.ext-absolute {
    position: absolute !important
}

.bring-to-front,
.ext .wp-block-image,
.ext-relative {
    position: relative !important
}

.ext-top-base {
    top: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-top-lg {
    top: var(--extendify--spacing--large, 3rem) !important
}

.ext--top-base {
    top: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
}

.ext--top-lg {
    top: calc(var(--extendify--spacing--large, 3rem) * -1) !important
}

.ext-right-base {
    right: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-right-lg {
    right: var(--extendify--spacing--large, 3rem) !important
}

.ext--right-base {
    right: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
}

.ext--right-lg {
    right: calc(var(--extendify--spacing--large, 3rem) * -1) !important
}

.ext-bottom-base {
    bottom: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-bottom-lg {
    bottom: var(--extendify--spacing--large, 3rem) !important
}

.ext--bottom-base {
    bottom: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
}

.ext--bottom-lg {
    bottom: calc(var(--extendify--spacing--large, 3rem) * -1) !important
}

.ext-left-base {
    left: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-left-lg {
    left: var(--extendify--spacing--large, 3rem) !important
}

.ext--left-base {
    left: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
}

.ext--left-lg {
    left: calc(var(--extendify--spacing--large, 3rem) * -1) !important
}

.ext-order-1 {
    order: 1 !important
}

.ext-order-2 {
    order: 2 !important
}

.ext-col-auto {
    grid-column: auto !important
}

.ext-col-span-1 {
    grid-column: span 1/span 1 !important
}

.ext-col-span-2 {
    grid-column: span 2/span 2 !important
}

.ext-col-span-3 {
    grid-column: span 3/span 3 !important
}

.ext-col-span-4 {
    grid-column: span 4/span 4 !important
}

.ext-col-span-5 {
    grid-column: span 5/span 5 !important
}

.ext-col-span-6 {
    grid-column: span 6/span 6 !important
}

.ext-col-span-7 {
    grid-column: span 7/span 7 !important
}

.ext-col-span-8 {
    grid-column: span 8/span 8 !important
}

.ext-col-span-9 {
    grid-column: span 9/span 9 !important
}

.ext-col-span-10 {
    grid-column: span 10/span 10 !important
}

.ext-col-span-11 {
    grid-column: span 11/span 11 !important
}

.ext-col-span-12 {
    grid-column: span 12/span 12 !important
}

.ext-col-span-full {
    grid-column: 1/-1 !important
}

.ext-col-start-1 {
    grid-column-start: 1 !important
}

.ext-col-start-2 {
    grid-column-start: 2 !important
}

.ext-col-start-3 {
    grid-column-start: 3 !important
}

.ext-col-start-4 {
    grid-column-start: 4 !important
}

.ext-col-start-5 {
    grid-column-start: 5 !important
}

.ext-col-start-6 {
    grid-column-start: 6 !important
}

.ext-col-start-7 {
    grid-column-start: 7 !important
}

.ext-col-start-8 {
    grid-column-start: 8 !important
}

.ext-col-start-9 {
    grid-column-start: 9 !important
}

.ext-col-start-10 {
    grid-column-start: 10 !important
}

.ext-col-start-11 {
    grid-column-start: 11 !important
}

.ext-col-start-12 {
    grid-column-start: 12 !important
}

.ext-col-start-13 {
    grid-column-start: 13 !important
}

.ext-col-start-auto {
    grid-column-start: auto !important
}

.ext-col-end-1 {
    grid-column-end: 1 !important
}

.ext-col-end-2 {
    grid-column-end: 2 !important
}

.ext-col-end-3 {
    grid-column-end: 3 !important
}

.ext-col-end-4 {
    grid-column-end: 4 !important
}

.ext-col-end-5 {
    grid-column-end: 5 !important
}

.ext-col-end-6 {
    grid-column-end: 6 !important
}

.ext-col-end-7 {
    grid-column-end: 7 !important
}

.ext-col-end-8 {
    grid-column-end: 8 !important
}

.ext-col-end-9 {
    grid-column-end: 9 !important
}

.ext-col-end-10 {
    grid-column-end: 10 !important
}

.ext-col-end-11 {
    grid-column-end: 11 !important
}

.ext-col-end-12 {
    grid-column-end: 12 !important
}

.ext-col-end-13 {
    grid-column-end: 13 !important
}

.ext-col-end-auto {
    grid-column-end: auto !important
}

.ext-row-auto {
    grid-row: auto !important
}

.ext-row-span-1 {
    grid-row: span 1/span 1 !important
}

.ext-row-span-2 {
    grid-row: span 2/span 2 !important
}

.ext-row-span-3 {
    grid-row: span 3/span 3 !important
}

.ext-row-span-4 {
    grid-row: span 4/span 4 !important
}

.ext-row-span-5 {
    grid-row: span 5/span 5 !important
}

.ext-row-span-6 {
    grid-row: span 6/span 6 !important
}

.ext-row-span-full {
    grid-row: 1/-1 !important
}

.ext-row-start-1 {
    grid-row-start: 1 !important
}

.ext-row-start-2 {
    grid-row-start: 2 !important
}

.ext-row-start-3 {
    grid-row-start: 3 !important
}

.ext-row-start-4 {
    grid-row-start: 4 !important
}

.ext-row-start-5 {
    grid-row-start: 5 !important
}

.ext-row-start-6 {
    grid-row-start: 6 !important
}

.ext-row-start-7 {
    grid-row-start: 7 !important
}

.ext-row-start-auto {
    grid-row-start: auto !important
}

.ext-row-end-1 {
    grid-row-end: 1 !important
}

.ext-row-end-2 {
    grid-row-end: 2 !important
}

.ext-row-end-3 {
    grid-row-end: 3 !important
}

.ext-row-end-4 {
    grid-row-end: 4 !important
}

.ext-row-end-5 {
    grid-row-end: 5 !important
}

.ext-row-end-6 {
    grid-row-end: 6 !important
}

.ext-row-end-7 {
    grid-row-end: 7 !important
}

.ext-row-end-auto {
    grid-row-end: auto !important
}

.ext-m-0:not([style*=margin]) {
    margin: 0 !important
}

.ext-m-auto:not([style*=margin]) {
    margin: auto !important
}

.ext-m-base:not([style*=margin]) {
    margin: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-m-lg:not([style*=margin]) {
    margin: var(--extendify--spacing--large, 3rem) !important
}

.ext--m-base:not([style*=margin]) {
    margin: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
}

.ext--m-lg:not([style*=margin]) {
    margin: calc(var(--extendify--spacing--large, 3rem) * -1) !important
}

.ext-mx-0:not([style*=margin]) {
    margin-left: 0 !important;
    margin-right: 0 !important
}

.ext .alignwide,
.ext-mx-auto:not([style*=margin]) {
    margin-left: auto !important;
    margin-right: auto !important
}

.ext-mx-base:not([style*=margin]) {
    margin-left: var(--wp--style--block-gap, 1.75rem) !important;
    margin-right: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-mx-lg:not([style*=margin]) {
    margin-left: var(--extendify--spacing--large, 3rem) !important;
    margin-right: var(--extendify--spacing--large, 3rem) !important
}

.ext--mx-base:not([style*=margin]) {
    margin-left: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important;
    margin-right: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
}

.ext--mx-lg:not([style*=margin]) {
    margin-left: calc(var(--extendify--spacing--large, 3rem) * -1) !important;
    margin-right: calc(var(--extendify--spacing--large, 3rem) * -1) !important
}

.ext .wp-block-group>*,
.ext-my-0:not([style*=margin]),
.wp-block-column.editor\:pointer-events-none {
    margin-top: 0 !important;
    margin-bottom: 0 !important
}

.ext-my-auto:not([style*=margin]) {
    margin-top: auto !important;
    margin-bottom: auto !important
}

.ext h2,
.ext-my-base:not([style*=margin]) {
    margin-top: var(--wp--style--block-gap, 1.75rem) !important;
    margin-bottom: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-my-lg:not([style*=margin]) {
    margin-top: var(--extendify--spacing--large, 3rem) !important;
    margin-bottom: var(--extendify--spacing--large, 3rem) !important
}

.ext--my-base:not([style*=margin]) {
    margin-top: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important;
    margin-bottom: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
}

.ext--my-lg:not([style*=margin]) {
    margin-top: calc(var(--extendify--spacing--large, 3rem) * -1) !important;
    margin-bottom: calc(var(--extendify--spacing--large, 3rem) * -1) !important
}

.ext .wp-block-columns+.wp-block-columns:not([class*=mt-]):not([class*=my-]):not([style*=margin]),
.ext-mt-0:not([style*=margin]),
[class*=fullwidth-cols] .wp-block-column:first-child,
[class*=fullwidth-cols] .wp-block-column:first-child>*,
[class*=fullwidth-cols] .wp-block-column>:first-child,
[class*=fullwidth-cols] .wp-block-group:first-child {
    margin-top: 0 !important
}

.ext-mt-auto:not([style*=margin]) {
    margin-top: auto !important
}

.ext-mt-base:not([style*=margin]) {
    margin-top: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-mt-lg:not([style*=margin]) {
    margin-top: var(--extendify--spacing--large, 3rem) !important
}

.ext--mt-base:not([style*=margin]) {
    margin-top: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
}

.ext--mt-lg:not([style*=margin]) {
    margin-top: calc(var(--extendify--spacing--large, 3rem) * -1) !important
}

.ext-mr-0.alignfull:not([style*=margin]):not([style*=margin]),
.ext-mr-0:not([style*=margin]) {
    margin-right: 0 !important
}

.ext-mr-auto:not([style*=margin]) {
    margin-right: auto !important
}

.ext-mr-base:not([style*=margin]) {
    margin-right: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-mr-lg:not([style*=margin]) {
    margin-right: var(--extendify--spacing--large, 3rem) !important
}

.ext--mr-base:not([style*=margin]) {
    margin-right: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
}

.ext--mr-lg:not([style*=margin]) {
    margin-right: calc(var(--extendify--spacing--large, 3rem) * -1) !important
}

.ext .is-not-stacked-on-mobile .wp-block-column,
.ext-mb-0:not([style*=margin]),
.ext.wp-block-group>:last-child,
.wp-block-columns[class*=fullwidth-cols].is-not-stacked-on-mobile>.wp-block-column,
[class*=fullwidth-cols] .wp-block-column:last-child,
[class*=fullwidth-cols] .wp-block-column>:last-child,
[class*=fullwidth-cols] .wp-block-group:last-child {
    margin-bottom: 0 !important
}

.ext-mb-auto:not([style*=margin]) {
    margin-bottom: auto !important
}

.ext-mb-base:not([style*=margin]),
.wp-block-columns[class*=fullwidth-cols]:not(.is-not-stacked-on-mobile)>.wp-block-column:not(:last-child) {
    margin-bottom: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-mb-lg:not([style*=margin]) {
    margin-bottom: var(--extendify--spacing--large, 3rem) !important
}

.ext--mb-base:not([style*=margin]) {
    margin-bottom: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
}

.ext--mb-lg:not([style*=margin]) {
    margin-bottom: calc(var(--extendify--spacing--large, 3rem) * -1) !important
}

.ext-ml-0:not([style*=margin]),
.ext-ml-0:not([style*=margin]):not([style*=margin]) {
    margin-left: 0 !important
}

.ext-ml-auto:not([style*=margin]) {
    margin-left: auto !important
}

.ext-ml-base:not([style*=margin]) {
    margin-left: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-ml-lg:not([style*=margin]) {
    margin-left: var(--extendify--spacing--large, 3rem) !important
}

.ext--ml-base:not([style*=margin]) {
    margin-left: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
}

.ext--ml-lg:not([style*=margin]) {
    margin-left: calc(var(--extendify--spacing--large, 3rem) * -1) !important
}

.editor\:no-resize .components-resizable-box__container,
.ext-block {
    display: block !important
}

.ext .wp-block-image img,
.ext-inline-block {
    display: inline-block !important
}

.ext-inline {
    display: inline !important
}

.ext-flex {
    display: flex !important
}

.ext-inline-flex {
    display: inline-flex !important
}

.ext .ext-grid>[class*="_inner-container"],
.ext-grid {
    display: grid !important
}

.ext-inline-grid {
    display: inline-grid !important
}

.block-editor-block-preview__content-iframe .ext [data-type="core/spacer"] .block-library-spacer__resize-container::before,
.editor\:no-caption .block-editor-rich-text__editable,
.editor\:no-inserter .wp-block-column:not(.is-selected)>.block-list-appender,
.editor\:no-inserter .wp-block-cover__inner-container>.block-list-appender,
.editor\:no-inserter .wp-block-group__inner-container>.block-list-appender,
.editor\:no-inserter>.block-list-appender,
.ext-hidden {
    display: none !important
}

.ext-w-auto {
    width: auto !important
}

.ext-w-full {
    width: 100% !important
}

.ext [class*=inner-container]>.alignwide [class*=inner-container]>*,
.ext [class*=inner-container]>[data-align=wide] [class*=inner-container]>*,
.ext-max-w-full {
    max-width: 100% !important
}

.ext-flex-1 {
    flex: 1 1 0% !important
}

.ext-flex-auto {
    flex: 1 1 auto !important
}

.ext-flex-initial {
    flex: 0 1 auto !important
}

.ext-flex-none {
    flex: none !important
}

.ext-flex-shrink-0 {
    flex-shrink: 0 !important
}

.ext-flex-shrink {
    flex-shrink: 1 !important
}

.ext-flex-grow-0 {
    flex-grow: 0 !important
}

.ext-flex-grow {
    flex-grow: 1 !important
}

.ext-list-none,
.is-style-inline-list li {
    list-style-type: none !important
}

.ext .ext-grid-cols-1>[class*="_inner-container"],
.ext-grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-2>[class*="_inner-container"],
.ext-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-3>[class*="_inner-container"],
.ext-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-4>[class*="_inner-container"],
.ext-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-5>[class*="_inner-container"],
.ext-grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-6>[class*="_inner-container"],
.ext-grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-7>[class*="_inner-container"],
.ext-grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-8>[class*="_inner-container"],
.ext-grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-9>[class*="_inner-container"],
.ext-grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-10>[class*="_inner-container"],
.ext-grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-11>[class*="_inner-container"],
.ext-grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-12>[class*="_inner-container"],
.ext-grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr)) !important
}

.ext .ext-grid-cols-none>[class*="_inner-container"],
.ext-grid-cols-none {
    grid-template-columns: none !important
}

.ext .ext-grid-rows-1>[class*="_inner-container"],
.ext-grid-rows-1 {
    grid-template-rows: repeat(1, minmax(0, 1fr)) !important
}

.ext .ext-grid-rows-2>[class*="_inner-container"],
.ext-grid-rows-2 {
    grid-template-rows: repeat(2, minmax(0, 1fr)) !important
}

.ext .ext-grid-rows-3>[class*="_inner-container"],
.ext-grid-rows-3 {
    grid-template-rows: repeat(3, minmax(0, 1fr)) !important
}

.ext .ext-grid-rows-4>[class*="_inner-container"],
.ext-grid-rows-4 {
    grid-template-rows: repeat(4, minmax(0, 1fr)) !important
}

.ext .ext-grid-rows-5>[class*="_inner-container"],
.ext-grid-rows-5 {
    grid-template-rows: repeat(5, minmax(0, 1fr)) !important
}

.ext .ext-grid-rows-6>[class*="_inner-container"],
.ext-grid-rows-6 {
    grid-template-rows: repeat(6, minmax(0, 1fr)) !important
}

.ext .ext-grid-rows-none>[class*="_inner-container"],
.ext-grid-rows-none {
    grid-template-rows: none !important
}

.ext-flex-row {
    flex-direction: row !important
}

.ext-flex-row-reverse {
    flex-direction: row-reverse !important
}

.ext-flex-col {
    flex-direction: column !important
}

.ext-flex-col-reverse {
    flex-direction: column-reverse !important
}

.ext-flex-wrap {
    flex-wrap: wrap !important
}

.ext-flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.ext-flex-nowrap {
    flex-wrap: nowrap !important
}

.ext .ext-items-start>[class*="_inner-container"],
.ext-items-start {
    align-items: flex-start !important
}

.ext .ext-items-end>[class*="_inner-container"],
.ext-items-end {
    align-items: flex-end !important
}

.ext .ext-items-center>[class*="_inner-container"],
.ext .is-style-angled>[class*="_inner-container"],
.ext-items-center {
    align-items: center !important
}

.ext .ext-items-baseline>[class*="_inner-container"],
.ext-items-baseline {
    align-items: baseline !important
}

.ext .ext-items-stretch>[class*="_inner-container"],
.ext-items-stretch {
    align-items: stretch !important
}

.ext-justify-start {
    justify-content: flex-start !important
}

.ext-justify-end {
    justify-content: flex-end !important
}

.ext-justify-center {
    justify-content: center !important
}

.ext-justify-between {
    justify-content: space-between !important
}

.ext-justify-around {
    justify-content: space-around !important
}

.ext-justify-evenly {
    justify-content: space-evenly !important
}

.ext-justify-items-start {
    justify-items: start !important
}

.ext-justify-items-end {
    justify-items: end !important
}

.ext-justify-items-center {
    justify-items: center !important
}

.ext-justify-items-stretch {
    justify-items: stretch !important
}

.ext-gap-0 {
    gap: 0 !important
}

.ext-gap-base {
    gap: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-gap-lg {
    gap: var(--extendify--spacing--large, 3rem) !important
}

.ext-gap-x-0 {
    -moz-column-gap: 0 !important;
    column-gap: 0 !important
}

.ext-gap-x-base {
    -moz-column-gap: var(--wp--style--block-gap, 1.75rem) !important;
    column-gap: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-gap-x-lg {
    -moz-column-gap: var(--extendify--spacing--large, 3rem) !important;
    column-gap: var(--extendify--spacing--large, 3rem) !important
}

.ext-gap-y-0 {
    row-gap: 0 !important
}

.ext-gap-y-base {
    row-gap: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-gap-y-lg {
    row-gap: var(--extendify--spacing--large, 3rem) !important
}

.ext-justify-self-auto {
    justify-self: auto !important
}

.ext-justify-self-start {
    justify-self: start !important
}

.ext-justify-self-end {
    justify-self: end !important
}

.ext-justify-self-center {
    justify-self: center !important
}

.ext-justify-self-stretch {
    justify-self: stretch !important
}

.ext-rounded-none {
    border-radius: 0 !important
}

.ext-rounded-full {
    border-radius: 9999px !important
}

.ext-rounded-t-none {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important
}

.ext-rounded-t-full {
    border-top-left-radius: 9999px !important;
    border-top-right-radius: 9999px !important
}

.ext-rounded-r-none {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important
}

.ext-rounded-r-full {
    border-top-right-radius: 9999px !important;
    border-bottom-right-radius: 9999px !important
}

.ext-rounded-b-none {
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important
}

.ext-rounded-b-full {
    border-bottom-right-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important
}

.ext-rounded-l-none {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important
}

.ext-rounded-l-full {
    border-top-left-radius: 9999px !important;
    border-bottom-left-radius: 9999px !important
}

.ext-rounded-tl-none {
    border-top-left-radius: 0 !important
}

.ext-rounded-tl-full {
    border-top-left-radius: 9999px !important
}

.ext-rounded-tr-none {
    border-top-right-radius: 0 !important
}

.ext-rounded-tr-full {
    border-top-right-radius: 9999px !important
}

.ext-rounded-br-none {
    border-bottom-right-radius: 0 !important
}

.ext-rounded-br-full {
    border-bottom-right-radius: 9999px !important
}

.ext-rounded-bl-none {
    border-bottom-left-radius: 0 !important
}

.ext-rounded-bl-full {
    border-bottom-left-radius: 9999px !important
}

.ext-border-0 {
    border-width: 0 !important
}

.ext-border-t-0 {
    border-top-width: 0 !important
}

.ext-border-r-0 {
    border-right-width: 0 !important
}

.ext-border-b-0 {
    border-bottom-width: 0 !important
}

.ext-border-l-0 {
    border-left-width: 0 !important
}

.ext .wp-block-group__inner-container,
.ext-p-0:not([style*=padding]) {
    padding: 0 !important
}

.ext-p-base:not([style*=padding]) {
    padding: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-p-lg:not([style*=padding]) {
    padding: var(--extendify--spacing--large, 3rem) !important
}

.ext .wp-block-columns .wp-block-column[style*=padding],
.ext-px-0:not([style*=padding]) {
    padding-left: 0 !important;
    padding-right: 0 !important
}

.ext-px-base:not([style*=padding]),
.ext.has-background {
    padding-left: var(--wp--style--block-gap, 1.75rem) !important;
    padding-right: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-px-lg:not([style*=padding]) {
    padding-left: var(--extendify--spacing--large, 3rem) !important;
    padding-right: var(--extendify--spacing--large, 3rem) !important
}

.ext-py-0:not([style*=padding]) {
    padding-top: 0 !important;
    padding-bottom: 0 !important
}

.ext-py-base:not([style*=padding]) {
    padding-top: var(--wp--style--block-gap, 1.75rem) !important;
    padding-bottom: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-py-lg:not([style*=padding]) {
    padding-top: var(--extendify--spacing--large, 3rem) !important;
    padding-bottom: var(--extendify--spacing--large, 3rem) !important
}

.ext-pt-0:not([style*=padding]) {
    padding-top: 0 !important
}

.ext-pt-base:not([style*=padding]) {
    padding-top: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-pt-lg:not([style*=padding]) {
    padding-top: var(--extendify--spacing--large, 3rem) !important
}

.ext-pr-0:not([style*=padding]) {
    padding-right: 0 !important
}

.ext-pr-base:not([style*=padding]) {
    padding-right: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-pr-lg:not([style*=padding]) {
    padding-right: var(--extendify--spacing--large, 3rem) !important
}

.ext-pb-0:not([style*=padding]) {
    padding-bottom: 0 !important
}

.ext-pb-base:not([style*=padding]) {
    padding-bottom: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-pb-lg:not([style*=padding]) {
    padding-bottom: var(--extendify--spacing--large, 3rem) !important
}

.ext-pl-0:not([style*=padding]),
.is-style-inline-list {
    padding-left: 0 !important
}

.ext-pl-base:not([style*=padding]) {
    padding-left: var(--wp--style--block-gap, 1.75rem) !important
}

.ext-pl-lg:not([style*=padding]) {
    padding-left: var(--extendify--spacing--large, 3rem) !important
}

.ext-text-left {
    text-align: left !important
}

.ext .wp-block-image,
.ext-text-center {
    text-align: center !important
}

.ext-text-right {
    text-align: right !important
}

.ext-leading-none {
    line-height: 1 !important
}

.ext-leading-tight {
    line-height: 1.25 !important
}

.ext-leading-snug {
    line-height: 1.375 !important
}

.ext-leading-normal {
    line-height: 1.5 !important
}

.ext-leading-relaxed {
    line-height: 1.625 !important
}

.ext-leading-loose {
    line-height: 2 !important
}

.ext-aspect-square img {
    aspect-ratio: 1/1 !important;
    -o-object-fit: cover !important;
    object-fit: cover !important
}

.ext-aspect-landscape img {
    aspect-ratio: 4/3 !important;
    -o-object-fit: cover !important;
    object-fit: cover !important
}

.ext-aspect-landscape-wide img {
    aspect-ratio: 16/9 !important;
    -o-object-fit: cover !important;
    object-fit: cover !important
}

.ext-aspect-portrait img {
    aspect-ratio: 3/4 !important;
    -o-object-fit: cover !important;
    object-fit: cover !important
}

.ext-aspect-landscape .components-resizable-box__container,
.ext-aspect-landscape-wide .components-resizable-box__container,
.ext-aspect-portrait .components-resizable-box__container,
.ext-aspect-square .components-resizable-box__container {
    height: auto !important
}

.clip-path--rhombus img {
    -webkit-clip-path: polygon(15% 6%, 80% 29%, 84% 93%, 23% 69%) !important;
    clip-path: polygon(15% 6%, 80% 29%, 84% 93%, 23% 69%) !important
}

.clip-path--diamond img {
    -webkit-clip-path: polygon(5% 29%, 60% 2%, 91% 64%, 36% 89%) !important;
    clip-path: polygon(5% 29%, 60% 2%, 91% 64%, 36% 89%) !important
}

.clip-path--rhombus-alt img {
    -webkit-clip-path: polygon(14% 9%, 85% 24%, 91% 89%, 19% 76%) !important;
    clip-path: polygon(14% 9%, 85% 24%, 91% 89%, 19% 76%) !important
}

.wp-block-columns[class*=fullwidth-cols] {
    margin-bottom: unset !important
}

.is-root-container.block-editor-block-list__layout>[data-align=full]:not(:first-of-type)>.ext-my-0,
.is-root-container.block-editor-block-list__layout>[data-align=full]:not(:first-of-type)>.wp-block-column.editor\:pointer-events-none,
.is-root-container.block-editor-block-list__layout>[data-align=wide]>.ext-my-0:not([style*=margin]),
.is-root-container.block-editor-block-list__layout>[data-align=wide]>.wp-block-column.editor\:pointer-events-none {
    margin-top: calc(-1 * var(--wp--style--block-gap, 28px)) !important
}

@media (min-width:600px) and (max-width:781px) {
    .wp-block-columns[class*=fullwidth-cols]:not(.is-not-stacked-on-mobile)>.wp-block-column:nth-child(2n) {
        margin-left: var(--wp--style--block-gap, 2em) !important
    }
}

@media (max-width:781px) {
    .tablet\:fullwidth-cols.wp-block-columns:not(.is-not-stacked-on-mobile) {
        flex-wrap: wrap !important
    }

    .tablet\:fullwidth-cols.wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column,
    .tablet\:fullwidth-cols.wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column:not([style*=margin]) {
        margin-left: 0 !important
    }

    .tablet\:fullwidth-cols.wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column {
        flex-basis: 100% !important
    }
}

@media (max-width:1079px) {
    .desktop\:fullwidth-cols.wp-block-columns:not(.is-not-stacked-on-mobile) {
        flex-wrap: wrap !important
    }

    .desktop\:fullwidth-cols.wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column,
    .desktop\:fullwidth-cols.wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column:not([style*=margin]) {
        margin-left: 0 !important
    }

    .desktop\:fullwidth-cols.wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column {
        flex-basis: 100% !important
    }

    .desktop\:fullwidth-cols.wp-block-columns:not(.is-not-stacked-on-mobile)>.wp-block-column:not(:last-child) {
        margin-bottom: var(--wp--style--block-gap, 1.75rem) !important
    }
}

.direction-rtl {
    direction: rtl !important
}

.direction-ltr {
    direction: ltr !important
}

.bring-to-front {
    z-index: 10 !important
}

.text-stroke {
    -webkit-text-stroke-color: var(--wp--preset--color--background) !important
}

.text-stroke--primary {
    -webkit-text-stroke-color: var(--wp--preset--color--primary) !important
}

.text-stroke--secondary {
    -webkit-text-stroke-color: var(--wp--preset--color--secondary) !important
}

.editor\:no-resize .components-resizable-box__handle,
.editor\:no-resize .components-resizable-box__handle::after,
.editor\:no-resize .components-resizable-box__side-handle::before {
    display: none !important;
    pointer-events: none !important
}

.editor\:pointer-events-none {
    pointer-events: none !important
}

.is-style-angled {
    align-items: center !important;
    justify-content: flex-end !important
}

.is-style-angled .wp-block-cover__image-background,
.is-style-angled .wp-block-cover__video-background {
    -webkit-clip-path: polygon(0 0, 30% 0%, 50% 100%, 0% 100%) !important;
    clip-path: polygon(0 0, 30% 0%, 50% 100%, 0% 100%) !important;
    z-index: 1 !important
}

.has-foreground-color {
    color: var(--wp--preset--color--foreground, #000) !important
}

.has-foreground-background-color {
    background-color: var(--wp--preset--color--foreground, #000) !important
}

.has-background-color {
    color: var(--wp--preset--color--background, #fff) !important
}

.has-background-background-color {
    background-color: var(--wp--preset--color--background, #fff) !important
}

.has-primary-color {
    color: var(--wp--preset--color--primary, #4b5563) !important
}

.has-primary-background-color {
    background-color: var(--wp--preset--color--primary, #4b5563) !important
}

.has-secondary-color {
    color: var(--wp--preset--color--secondary, #9ca3af) !important
}

.has-secondary-background-color {
    background-color: var(--wp--preset--color--secondary, #9ca3af) !important
}

.ext.has-text-color h1,
.ext.has-text-color h2,
.ext.has-text-color h3,
.ext.has-text-color h4,
.ext.has-text-color h5,
.ext.has-text-color h6,
.ext.has-text-color p {
    color: currentColor !important
}

.has-white-color {
    color: var(--wp--preset--color--white, #fff) !important
}

.has-black-color {
    color: var(--wp--preset--color--black, #000) !important
}

.has-ext-foreground-background-color {
    background-color: var(--wp--preset--color--foreground, var(--wp--preset--color--black, #000)) !important
}

.has-ext-primary-background-color {
    background-color: var(--wp--preset--color--primary, var(--wp--preset--color--cyan-bluish-gray, #000)) !important
}

.wp-block-button__link.has-black-background-color {
    border-color: var(--wp--preset--color--black, #000) !important
}

.wp-block-button__link.has-white-background-color {
    border-color: var(--wp--preset--color--white, #fff) !important
}

.has-ext-small-font-size {
    font-size: var(--wp--preset--font-size--ext-small) !important
}

.has-ext-medium-font-size {
    font-size: var(--wp--preset--font-size--ext-medium) !important
}

.has-ext-large-font-size {
    font-size: var(--wp--preset--font-size--ext-large) !important;
    line-height: 1.2 !important
}

.has-ext-x-large-font-size {
    font-size: var(--wp--preset--font-size--ext-x-large) !important;
    line-height: 1 !important
}

.has-ext-xx-large-font-size {
    font-size: var(--wp--preset--font-size--ext-xx-large) !important;
    line-height: 1 !important
}

.has-ext-x-large-font-size:not([style*=line-height]),
.has-ext-xx-large-font-size:not([style*=line-height]) {
    line-height: 1.1 !important
}

.ext .wp-block-group>*+* {
    margin-top: var(--wp--style--block-gap, 1.75rem) !important;
    margin-bottom: 0 !important
}

.has-ext-x-large-font-size+h3,
.has-ext-x-large-font-size+p {
    margin-top: .5rem !important
}

.ext .wp-block-buttons>.wp-block-button.wp-block-button__width-25 {
    width: calc(25% - var(--wp--style--block-gap, .5em) * .75) !important;
    min-width: 12rem !important
}

.ext>[class*="_inner-container"]>.ext-grid:not([class*=columns]),
.ext>[class*="_inner-container"]>.wp-block>.ext-grid:not([class*=columns]) {
    display: initial !important
}

.ext .ext-grid-cols-13>[class*="_inner-container"] {
    grid-template-columns: repeat(13, minmax(0, 1fr)) !important
}

.ext [class*=inner-container]>.alignwide [class*=inner-container],
.ext [class*=inner-container]>[data-align=wide] [class*=inner-container] {
    max-width: var(--responsive--alignwide-width, 120rem) !important
}

.ext .wp-block-image img {
    vertical-align: middle !important
}

body {
    --extendify--spacing--large: var(--wp--custom--spacing--large, clamp(2em, 8vw, 8em)) !important;
    --wp--preset--font-size--ext-small: 1rem !important;
    --wp--preset--font-size--ext-medium: 1.125rem !important;
    --wp--preset--font-size--ext-large: clamp(1.65rem, 3.5vw, 2.15rem) !important;
    --wp--preset--font-size--ext-x-large: clamp(3rem, 6vw, 4.75rem) !important;
    --wp--preset--font-size--ext-xx-large: clamp(3.25rem, 7.5vw, 5.75rem) !important;
    --wp--preset--color--black: #000 !important;
    --wp--preset--color--white: #fff !important
}

.ext * {
    box-sizing: border-box !important
}

.block-editor-block-preview__content-iframe .ext [data-type="core/spacer"] .components-resizable-box__container {
    background: 0 0 !important
}

.ext .wp-block-group__inner-container figure.wp-block-gallery.alignfull {
    margin-top: unset !important;
    margin-bottom: unset !important
}

.block-editor-block-preview__content-iframe .preview\:min-h-50 {
    min-height: 50vw !important
}

.block-editor-block-preview__content-iframe .preview\:min-h-60 {
    min-height: 60vw !important
}

.block-editor-block-preview__content-iframe .preview\:min-h-70 {
    min-height: 70vw !important
}

.block-editor-block-preview__content-iframe .preview\:min-h-80 {
    min-height: 80vw !important
}

.block-editor-block-preview__content-iframe .preview\:min-h-100 {
    min-height: 100vw !important
}

.is-root-container .wp-block[data-align=full]>.ext-mx-0:not([style*=margin]):not([style*=margin]) {
    margin-right: calc(1 * var(--wp--custom--spacing--outer, 0)) !important;
    margin-left: calc(1 * var(--wp--custom--spacing--outer, 0)) !important;
    overflow: hidden !important;
    width: unset !important
}

@media (min-width:782px) {
    .wp-block-columns[class*=fullwidth-cols]:not(.is-not-stacked-on-mobile)>.wp-block-column:not(:last-child) {
        margin-bottom: 0 !important
    }

    .is-style-inline-list li {
        margin-right: var(--wp--style--block-gap, 1.75rem) !important;
        display: inline !important
    }

    .is-style-inline-list li:first-child {
        margin-left: 0 !important
    }

    .is-style-inline-list li:last-child {
        margin-right: 0 !important
    }

    .is-style-angled .wp-block-cover__image-background,
    .is-style-angled .wp-block-cover__video-background {
        -webkit-clip-path: polygon(0 0, 55% 0%, 65% 100%, 0% 100%) !important;
        clip-path: polygon(0 0, 55% 0%, 65% 100%, 0% 100%) !important
    }

    .tablet\:ext-absolute {
        position: absolute !important
    }

    .tablet\:ext-relative {
        position: relative !important
    }

    .tablet\:ext-top-base {
        top: var(--wp--style--block-gap, 1.75rem) !important
    }

    .tablet\:ext-top-lg {
        top: var(--extendify--spacing--large, 3rem) !important
    }

    .tablet\:ext--top-base {
        top: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .tablet\:ext--top-lg {
        top: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .tablet\:ext-right-base {
        right: var(--wp--style--block-gap, 1.75rem) !important
    }

    .tablet\:ext-right-lg {
        right: var(--extendify--spacing--large, 3rem) !important
    }

    .tablet\:ext--right-base {
        right: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .tablet\:ext--right-lg {
        right: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .tablet\:ext-bottom-base {
        bottom: var(--wp--style--block-gap, 1.75rem) !important
    }

    .tablet\:ext-bottom-lg {
        bottom: var(--extendify--spacing--large, 3rem) !important
    }

    .tablet\:ext--bottom-base {
        bottom: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .tablet\:ext--bottom-lg {
        bottom: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .tablet\:ext-left-base {
        left: var(--wp--style--block-gap, 1.75rem) !important
    }

    .tablet\:ext-left-lg {
        left: var(--extendify--spacing--large, 3rem) !important
    }

    .tablet\:ext--left-base {
        left: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .tablet\:ext--left-lg {
        left: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .tablet\:ext-order-1 {
        order: 1 !important
    }

    .tablet\:ext-order-2 {
        order: 2 !important
    }

    .tablet\:ext-m-0:not([style*=margin]) {
        margin: 0 !important
    }

    .tablet\:ext-m-auto:not([style*=margin]) {
        margin: auto !important
    }

    .tablet\:ext-m-base:not([style*=margin]) {
        margin: var(--wp--style--block-gap, 1.75rem) !important
    }

    .tablet\:ext-m-lg:not([style*=margin]) {
        margin: var(--extendify--spacing--large, 3rem) !important
    }

    .tablet\:ext--m-base:not([style*=margin]) {
        margin: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .tablet\:ext--m-lg:not([style*=margin]) {
        margin: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .tablet\:ext-mx-0:not([style*=margin]) {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .tablet\:ext-mx-auto:not([style*=margin]) {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .tablet\:ext-mx-base:not([style*=margin]) {
        margin-left: var(--wp--style--block-gap, 1.75rem) !important;
        margin-right: var(--wp--style--block-gap, 1.75rem) !important
    }

    .tablet\:ext-mx-lg:not([style*=margin]) {
        margin-left: var(--extendify--spacing--large, 3rem) !important;
        margin-right: var(--extendify--spacing--large, 3rem) !important
    }

    .tablet\:ext--mx-base:not([style*=margin]) {
        margin-left: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important;
        margin-right: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .tablet\:ext--mx-lg:not([style*=margin]) {
        margin-left: calc(var(--extendify--spacing--large, 3rem) * -1) !important;
        margin-right: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .tablet\:ext-my-0:not([style*=margin]) {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .tablet\:ext-my-auto:not([style*=margin]) {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .tablet\:ext-my-base:not([style*=margin]) {
        margin-top: var(--wp--style--block-gap, 1.75rem) !important;
        margin-bottom: var(--wp--style--block-gap, 1.75rem) !important
    }

    .tablet\:ext-my-lg:not([style*=margin]) {
        margin-top: var(--extendify--spacing--large, 3rem) !important;
        margin-bottom: var(--extendify--spacing--large, 3rem) !important
    }

    .tablet\:ext--my-base:not([style*=margin]) {
        margin-top: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important;
        margin-bottom: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .tablet\:ext--my-lg:not([style*=margin]) {
        margin-top: calc(var(--extendify--spacing--large, 3rem) * -1) !important;
        margin-bottom: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .tablet\:ext-mt-0:not([style*=margin]) {
        margin-top: 0 !important
    }

    .tablet\:ext-mt-auto:not([style*=margin]) {
        margin-top: auto !important
    }

    .tablet\:ext-mt-base:not([style*=margin]) {
        margin-top: var(--wp--style--block-gap, 1.75rem) !important
    }

    .tablet\:ext-mt-lg:not([style*=margin]) {
        margin-top: var(--extendify--spacing--large, 3rem) !important
    }

    .tablet\:ext--mt-base:not([style*=margin]) {
        margin-top: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .tablet\:ext--mt-lg:not([style*=margin]) {
        margin-top: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .tablet\:ext-mr-0:not([style*=margin]) {
        margin-right: 0 !important
    }

    .tablet\:ext-mr-auto:not([style*=margin]) {
        margin-right: auto !important
    }

    .tablet\:ext-mr-base:not([style*=margin]) {
        margin-right: var(--wp--style--block-gap, 1.75rem) !important
    }

    .tablet\:ext-mr-lg:not([style*=margin]) {
        margin-right: var(--extendify--spacing--large, 3rem) !important
    }

    .tablet\:ext--mr-base:not([style*=margin]) {
        margin-right: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .tablet\:ext--mr-lg:not([style*=margin]) {
        margin-right: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .tablet\:ext-mb-0:not([style*=margin]) {
        margin-bottom: 0 !important
    }

    .tablet\:ext-mb-auto:not([style*=margin]) {
        margin-bottom: auto !important
    }

    .tablet\:ext-mb-base:not([style*=margin]) {
        margin-bottom: var(--wp--style--block-gap, 1.75rem) !important
    }

    .tablet\:ext-mb-lg:not([style*=margin]) {
        margin-bottom: var(--extendify--spacing--large, 3rem) !important
    }

    .tablet\:ext--mb-base:not([style*=margin]) {
        margin-bottom: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .tablet\:ext--mb-lg:not([style*=margin]) {
        margin-bottom: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .tablet\:ext-ml-0:not([style*=margin]) {
        margin-left: 0 !important
    }

    .tablet\:ext-ml-auto:not([style*=margin]) {
        margin-left: auto !important
    }

    .tablet\:ext-ml-base:not([style*=margin]) {
        margin-left: var(--wp--style--block-gap, 1.75rem) !important
    }

    .tablet\:ext-ml-lg:not([style*=margin]) {
        margin-left: var(--extendify--spacing--large, 3rem) !important
    }

    .tablet\:ext--ml-base:not([style*=margin]) {
        margin-left: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .tablet\:ext--ml-lg:not([style*=margin]) {
        margin-left: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .tablet\:ext-block {
        display: block !important
    }

    .tablet\:ext-inline-block {
        display: inline-block !important
    }

    .tablet\:ext-inline {
        display: inline !important
    }

    .tablet\:ext-flex {
        display: flex !important
    }

    .tablet\:ext-inline-flex {
        display: inline-flex !important
    }

    .tablet\:ext-grid {
        display: grid !important
    }

    .tablet\:ext-inline-grid {
        display: inline-grid !important
    }

    .tablet\:ext-hidden {
        display: none !important
    }

    .tablet\:ext-w-auto {
        width: auto !important
    }

    .tablet\:ext-w-full {
        width: 100% !important
    }

    .tablet\:ext-max-w-full {
        max-width: 100% !important
    }

    .tablet\:ext-flex-1 {
        flex: 1 1 0% !important
    }

    .tablet\:ext-flex-auto {
        flex: 1 1 auto !important
    }

    .tablet\:ext-flex-initial {
        flex: 0 1 auto !important
    }

    .tablet\:ext-flex-none {
        flex: none !important
    }

    .tablet\:ext-flex-shrink-0 {
        flex-shrink: 0 !important
    }

    .tablet\:ext-flex-shrink {
        flex-shrink: 1 !important
    }

    .tablet\:ext-flex-grow-0 {
        flex-grow: 0 !important
    }

    .tablet\:ext-flex-grow {
        flex-grow: 1 !important
    }

    .tablet\:ext-list-none {
        list-style-type: none !important
    }

    .tablet\:ext-grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-7 {
        grid-template-columns: repeat(7, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-8 {
        grid-template-columns: repeat(8, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-9 {
        grid-template-columns: repeat(9, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-10 {
        grid-template-columns: repeat(10, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-11 {
        grid-template-columns: repeat(11, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr)) !important
    }

    .tablet\:ext-grid-cols-none {
        grid-template-columns: none !important
    }

    .tablet\:ext-flex-row {
        flex-direction: row !important
    }

    .tablet\:ext-flex-row-reverse {
        flex-direction: row-reverse !important
    }

    .tablet\:ext-flex-col {
        flex-direction: column !important
    }

    .tablet\:ext-flex-col-reverse {
        flex-direction: column-reverse !important
    }

    .tablet\:ext-flex-wrap {
        flex-wrap: wrap !important
    }

    .tablet\:ext-flex-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .tablet\:ext-flex-nowrap {
        flex-wrap: nowrap !important
    }

    .tablet\:ext-items-start {
        align-items: flex-start !important
    }

    .tablet\:ext-items-end {
        align-items: flex-end !important
    }

    .tablet\:ext-items-center {
        align-items: center !important
    }

    .tablet\:ext-items-baseline {
        align-items: baseline !important
    }

    .tablet\:ext-items-stretch {
        align-items: stretch !important
    }

    .tablet\:ext-justify-start {
        justify-content: flex-start !important
    }

    .tablet\:ext-justify-end {
        justify-content: flex-end !important
    }

    .tablet\:ext-justify-center {
        justify-content: center !important
    }

    .tablet\:ext-justify-between {
        justify-content: space-between !important
    }

    .tablet\:ext-justify-around {
        justify-content: space-around !important
    }

    .tablet\:ext-justify-evenly {
        justify-content: space-evenly !important
    }

    .tablet\:ext-justify-items-start {
        justify-items: start !important
    }

    .tablet\:ext-justify-items-end {
        justify-items: end !important
    }

    .tablet\:ext-justify-items-center {
        justify-items: center !important
    }

    .tablet\:ext-justify-items-stretch {
        justify-items: stretch !important
    }

    .tablet\:ext-justify-self-auto {
        justify-self: auto !important
    }

    .tablet\:ext-justify-self-start {
        justify-self: start !important
    }

    .tablet\:ext-justify-self-end {
        justify-self: end !important
    }

    .tablet\:ext-justify-self-center {
        justify-self: center !important
    }

    .tablet\:ext-justify-self-stretch {
        justify-self: stretch !important
    }

    .tablet\:ext-p-0:not([style*=padding]) {
        padding: 0 !important
    }

    .tablet\:ext-p-base:not([style*=padding]) {
        padding: var(--wp--style--block-gap, 1.75rem) !important
    }

    .tablet\:ext-p-lg:not([style*=padding]) {
        padding: var(--extendify--spacing--large, 3rem) !important
    }

    .tablet\:ext-px-0:not([style*=padding]) {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .tablet\:ext-px-base:not([style*=padding]) {
        padding-left: var(--wp--style--block-gap, 1.75rem) !important;
        padding-right: var(--wp--style--block-gap, 1.75rem) !important
    }

    .tablet\:ext-px-lg:not([style*=padding]) {
        padding-left: var(--extendify--spacing--large, 3rem) !important;
        padding-right: var(--extendify--spacing--large, 3rem) !important
    }

    .tablet\:ext-py-0:not([style*=padding]) {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .tablet\:ext-py-base:not([style*=padding]) {
        padding-top: var(--wp--style--block-gap, 1.75rem) !important;
        padding-bottom: var(--wp--style--block-gap, 1.75rem) !important
    }

    .tablet\:ext-py-lg:not([style*=padding]) {
        padding-top: var(--extendify--spacing--large, 3rem) !important;
        padding-bottom: var(--extendify--spacing--large, 3rem) !important
    }

    .tablet\:ext-pt-0:not([style*=padding]) {
        padding-top: 0 !important
    }

    .tablet\:ext-pt-base:not([style*=padding]) {
        padding-top: var(--wp--style--block-gap, 1.75rem) !important
    }

    .tablet\:ext-pt-lg:not([style*=padding]) {
        padding-top: var(--extendify--spacing--large, 3rem) !important
    }

    .tablet\:ext-pr-0:not([style*=padding]) {
        padding-right: 0 !important
    }

    .tablet\:ext-pr-base:not([style*=padding]) {
        padding-right: var(--wp--style--block-gap, 1.75rem) !important
    }

    .tablet\:ext-pr-lg:not([style*=padding]) {
        padding-right: var(--extendify--spacing--large, 3rem) !important
    }

    .tablet\:ext-pb-0:not([style*=padding]) {
        padding-bottom: 0 !important
    }

    .tablet\:ext-pb-base:not([style*=padding]) {
        padding-bottom: var(--wp--style--block-gap, 1.75rem) !important
    }

    .tablet\:ext-pb-lg:not([style*=padding]) {
        padding-bottom: var(--extendify--spacing--large, 3rem) !important
    }

    .tablet\:ext-pl-0:not([style*=padding]) {
        padding-left: 0 !important
    }

    .tablet\:ext-pl-base:not([style*=padding]) {
        padding-left: var(--wp--style--block-gap, 1.75rem) !important
    }

    .tablet\:ext-pl-lg:not([style*=padding]) {
        padding-left: var(--extendify--spacing--large, 3rem) !important
    }

    .tablet\:ext-text-left {
        text-align: left !important
    }

    .tablet\:ext-text-center {
        text-align: center !important
    }

    .tablet\:ext-text-right {
        text-align: right !important
    }
}

@media (min-width:1080px) {
    .desktop\:ext-absolute {
        position: absolute !important
    }

    .desktop\:ext-relative {
        position: relative !important
    }

    .desktop\:ext-top-base {
        top: var(--wp--style--block-gap, 1.75rem) !important
    }

    .desktop\:ext-top-lg {
        top: var(--extendify--spacing--large, 3rem) !important
    }

    .desktop\:ext--top-base {
        top: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .desktop\:ext--top-lg {
        top: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .desktop\:ext-right-base {
        right: var(--wp--style--block-gap, 1.75rem) !important
    }

    .desktop\:ext-right-lg {
        right: var(--extendify--spacing--large, 3rem) !important
    }

    .desktop\:ext--right-base {
        right: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .desktop\:ext--right-lg {
        right: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .desktop\:ext-bottom-base {
        bottom: var(--wp--style--block-gap, 1.75rem) !important
    }

    .desktop\:ext-bottom-lg {
        bottom: var(--extendify--spacing--large, 3rem) !important
    }

    .desktop\:ext--bottom-base {
        bottom: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .desktop\:ext--bottom-lg {
        bottom: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .desktop\:ext-left-base {
        left: var(--wp--style--block-gap, 1.75rem) !important
    }

    .desktop\:ext-left-lg {
        left: var(--extendify--spacing--large, 3rem) !important
    }

    .desktop\:ext--left-base {
        left: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .desktop\:ext--left-lg {
        left: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .desktop\:ext-order-1 {
        order: 1 !important
    }

    .desktop\:ext-order-2 {
        order: 2 !important
    }

    .desktop\:ext-m-0:not([style*=margin]) {
        margin: 0 !important
    }

    .desktop\:ext-m-auto:not([style*=margin]) {
        margin: auto !important
    }

    .desktop\:ext-m-base:not([style*=margin]) {
        margin: var(--wp--style--block-gap, 1.75rem) !important
    }

    .desktop\:ext-m-lg:not([style*=margin]) {
        margin: var(--extendify--spacing--large, 3rem) !important
    }

    .desktop\:ext--m-base:not([style*=margin]) {
        margin: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .desktop\:ext--m-lg:not([style*=margin]) {
        margin: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .desktop\:ext-mx-0:not([style*=margin]) {
        margin-left: 0 !important;
        margin-right: 0 !important
    }

    .desktop\:ext-mx-auto:not([style*=margin]) {
        margin-left: auto !important;
        margin-right: auto !important
    }

    .desktop\:ext-mx-base:not([style*=margin]) {
        margin-left: var(--wp--style--block-gap, 1.75rem) !important;
        margin-right: var(--wp--style--block-gap, 1.75rem) !important
    }

    .desktop\:ext-mx-lg:not([style*=margin]) {
        margin-left: var(--extendify--spacing--large, 3rem) !important;
        margin-right: var(--extendify--spacing--large, 3rem) !important
    }

    .desktop\:ext--mx-base:not([style*=margin]) {
        margin-left: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important;
        margin-right: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .desktop\:ext--mx-lg:not([style*=margin]) {
        margin-left: calc(var(--extendify--spacing--large, 3rem) * -1) !important;
        margin-right: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .desktop\:ext-my-0:not([style*=margin]) {
        margin-top: 0 !important;
        margin-bottom: 0 !important
    }

    .desktop\:ext-my-auto:not([style*=margin]) {
        margin-top: auto !important;
        margin-bottom: auto !important
    }

    .desktop\:ext-my-base:not([style*=margin]) {
        margin-top: var(--wp--style--block-gap, 1.75rem) !important;
        margin-bottom: var(--wp--style--block-gap, 1.75rem) !important
    }

    .desktop\:ext-my-lg:not([style*=margin]) {
        margin-top: var(--extendify--spacing--large, 3rem) !important;
        margin-bottom: var(--extendify--spacing--large, 3rem) !important
    }

    .desktop\:ext--my-base:not([style*=margin]) {
        margin-top: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important;
        margin-bottom: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .desktop\:ext--my-lg:not([style*=margin]) {
        margin-top: calc(var(--extendify--spacing--large, 3rem) * -1) !important;
        margin-bottom: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .desktop\:ext-mt-0:not([style*=margin]) {
        margin-top: 0 !important
    }

    .desktop\:ext-mt-auto:not([style*=margin]) {
        margin-top: auto !important
    }

    .desktop\:ext-mt-base:not([style*=margin]) {
        margin-top: var(--wp--style--block-gap, 1.75rem) !important
    }

    .desktop\:ext-mt-lg:not([style*=margin]) {
        margin-top: var(--extendify--spacing--large, 3rem) !important
    }

    .desktop\:ext--mt-base:not([style*=margin]) {
        margin-top: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .desktop\:ext--mt-lg:not([style*=margin]) {
        margin-top: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .desktop\:ext-mr-0:not([style*=margin]) {
        margin-right: 0 !important
    }

    .desktop\:ext-mr-auto:not([style*=margin]) {
        margin-right: auto !important
    }

    .desktop\:ext-mr-base:not([style*=margin]) {
        margin-right: var(--wp--style--block-gap, 1.75rem) !important
    }

    .desktop\:ext-mr-lg:not([style*=margin]) {
        margin-right: var(--extendify--spacing--large, 3rem) !important
    }

    .desktop\:ext--mr-base:not([style*=margin]) {
        margin-right: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .desktop\:ext--mr-lg:not([style*=margin]) {
        margin-right: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .desktop\:ext-mb-0:not([style*=margin]) {
        margin-bottom: 0 !important
    }

    .desktop\:ext-mb-auto:not([style*=margin]) {
        margin-bottom: auto !important
    }

    .desktop\:ext-mb-base:not([style*=margin]) {
        margin-bottom: var(--wp--style--block-gap, 1.75rem) !important
    }

    .desktop\:ext-mb-lg:not([style*=margin]) {
        margin-bottom: var(--extendify--spacing--large, 3rem) !important
    }

    .desktop\:ext--mb-base:not([style*=margin]) {
        margin-bottom: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .desktop\:ext--mb-lg:not([style*=margin]) {
        margin-bottom: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .desktop\:ext-ml-0:not([style*=margin]) {
        margin-left: 0 !important
    }

    .desktop\:ext-ml-auto:not([style*=margin]) {
        margin-left: auto !important
    }

    .desktop\:ext-ml-base:not([style*=margin]) {
        margin-left: var(--wp--style--block-gap, 1.75rem) !important
    }

    .desktop\:ext-ml-lg:not([style*=margin]) {
        margin-left: var(--extendify--spacing--large, 3rem) !important
    }

    .desktop\:ext--ml-base:not([style*=margin]) {
        margin-left: calc(var(--wp--style--block-gap, 1.75rem) * -1) !important
    }

    .desktop\:ext--ml-lg:not([style*=margin]) {
        margin-left: calc(var(--extendify--spacing--large, 3rem) * -1) !important
    }

    .desktop\:ext-block {
        display: block !important
    }

    .desktop\:ext-inline-block {
        display: inline-block !important
    }

    .desktop\:ext-inline {
        display: inline !important
    }

    .desktop\:ext-flex {
        display: flex !important
    }

    .desktop\:ext-inline-flex {
        display: inline-flex !important
    }

    .desktop\:ext-grid {
        display: grid !important
    }

    .desktop\:ext-inline-grid {
        display: inline-grid !important
    }

    .desktop\:ext-hidden {
        display: none !important
    }

    .desktop\:ext-w-auto {
        width: auto !important
    }

    .desktop\:ext-w-full {
        width: 100% !important
    }

    .desktop\:ext-max-w-full {
        max-width: 100% !important
    }

    .desktop\:ext-flex-1 {
        flex: 1 1 0% !important
    }

    .desktop\:ext-flex-auto {
        flex: 1 1 auto !important
    }

    .desktop\:ext-flex-initial {
        flex: 0 1 auto !important
    }

    .desktop\:ext-flex-none {
        flex: none !important
    }

    .desktop\:ext-flex-shrink-0 {
        flex-shrink: 0 !important
    }

    .desktop\:ext-flex-shrink {
        flex-shrink: 1 !important
    }

    .desktop\:ext-flex-grow-0 {
        flex-grow: 0 !important
    }

    .desktop\:ext-flex-grow {
        flex-grow: 1 !important
    }

    .desktop\:ext-list-none {
        list-style-type: none !important
    }

    .desktop\:ext-grid-cols-1 {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-3 {
        grid-template-columns: repeat(3, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-4 {
        grid-template-columns: repeat(4, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-6 {
        grid-template-columns: repeat(6, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-7 {
        grid-template-columns: repeat(7, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-8 {
        grid-template-columns: repeat(8, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-9 {
        grid-template-columns: repeat(9, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-10 {
        grid-template-columns: repeat(10, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-11 {
        grid-template-columns: repeat(11, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-12 {
        grid-template-columns: repeat(12, minmax(0, 1fr)) !important
    }

    .desktop\:ext-grid-cols-none {
        grid-template-columns: none !important
    }

    .desktop\:ext-flex-row {
        flex-direction: row !important
    }

    .desktop\:ext-flex-row-reverse {
        flex-direction: row-reverse !important
    }

    .desktop\:ext-flex-col {
        flex-direction: column !important
    }

    .desktop\:ext-flex-col-reverse {
        flex-direction: column-reverse !important
    }

    .desktop\:ext-flex-wrap {
        flex-wrap: wrap !important
    }

    .desktop\:ext-flex-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .desktop\:ext-flex-nowrap {
        flex-wrap: nowrap !important
    }

    .desktop\:ext-items-start {
        align-items: flex-start !important
    }

    .desktop\:ext-items-end {
        align-items: flex-end !important
    }

    .desktop\:ext-items-center {
        align-items: center !important
    }

    .desktop\:ext-items-baseline {
        align-items: baseline !important
    }

    .desktop\:ext-items-stretch {
        align-items: stretch !important
    }

    .desktop\:ext-justify-start {
        justify-content: flex-start !important
    }

    .desktop\:ext-justify-end {
        justify-content: flex-end !important
    }

    .desktop\:ext-justify-center {
        justify-content: center !important
    }

    .desktop\:ext-justify-between {
        justify-content: space-between !important
    }

    .desktop\:ext-justify-around {
        justify-content: space-around !important
    }

    .desktop\:ext-justify-evenly {
        justify-content: space-evenly !important
    }

    .desktop\:ext-justify-items-start {
        justify-items: start !important
    }

    .desktop\:ext-justify-items-end {
        justify-items: end !important
    }

    .desktop\:ext-justify-items-center {
        justify-items: center !important
    }

    .desktop\:ext-justify-items-stretch {
        justify-items: stretch !important
    }

    .desktop\:ext-justify-self-auto {
        justify-self: auto !important
    }

    .desktop\:ext-justify-self-start {
        justify-self: start !important
    }

    .desktop\:ext-justify-self-end {
        justify-self: end !important
    }

    .desktop\:ext-justify-self-center {
        justify-self: center !important
    }

    .desktop\:ext-justify-self-stretch {
        justify-self: stretch !important
    }

    .desktop\:ext-p-0:not([style*=padding]) {
        padding: 0 !important
    }

    .desktop\:ext-p-base:not([style*=padding]) {
        padding: var(--wp--style--block-gap, 1.75rem) !important
    }

    .desktop\:ext-p-lg:not([style*=padding]) {
        padding: var(--extendify--spacing--large, 3rem) !important
    }

    .desktop\:ext-px-0:not([style*=padding]) {
        padding-left: 0 !important;
        padding-right: 0 !important
    }

    .desktop\:ext-px-base:not([style*=padding]) {
        padding-left: var(--wp--style--block-gap, 1.75rem) !important;
        padding-right: var(--wp--style--block-gap, 1.75rem) !important
    }

    .desktop\:ext-px-lg:not([style*=padding]) {
        padding-left: var(--extendify--spacing--large, 3rem) !important;
        padding-right: var(--extendify--spacing--large, 3rem) !important
    }

    .desktop\:ext-py-0:not([style*=padding]) {
        padding-top: 0 !important;
        padding-bottom: 0 !important
    }

    .desktop\:ext-py-base:not([style*=padding]) {
        padding-top: var(--wp--style--block-gap, 1.75rem) !important;
        padding-bottom: var(--wp--style--block-gap, 1.75rem) !important
    }

    .desktop\:ext-py-lg:not([style*=padding]) {
        padding-top: var(--extendify--spacing--large, 3rem) !important;
        padding-bottom: var(--extendify--spacing--large, 3rem) !important
    }

    .desktop\:ext-pt-0:not([style*=padding]) {
        padding-top: 0 !important
    }

    .desktop\:ext-pt-base:not([style*=padding]) {
        padding-top: var(--wp--style--block-gap, 1.75rem) !important
    }

    .desktop\:ext-pt-lg:not([style*=padding]) {
        padding-top: var(--extendify--spacing--large, 3rem) !important
    }

    .desktop\:ext-pr-0:not([style*=padding]) {
        padding-right: 0 !important
    }

    .desktop\:ext-pr-base:not([style*=padding]) {
        padding-right: var(--wp--style--block-gap, 1.75rem) !important
    }

    .desktop\:ext-pr-lg:not([style*=padding]) {
        padding-right: var(--extendify--spacing--large, 3rem) !important
    }

    .desktop\:ext-pb-0:not([style*=padding]) {
        padding-bottom: 0 !important
    }

    .desktop\:ext-pb-base:not([style*=padding]) {
        padding-bottom: var(--wp--style--block-gap, 1.75rem) !important
    }

    .desktop\:ext-pb-lg:not([style*=padding]) {
        padding-bottom: var(--extendify--spacing--large, 3rem) !important
    }

    .desktop\:ext-pl-0:not([style*=padding]) {
        padding-left: 0 !important
    }

    .desktop\:ext-pl-base:not([style*=padding]) {
        padding-left: var(--wp--style--block-gap, 1.75rem) !important
    }

    .desktop\:ext-pl-lg:not([style*=padding]) {
        padding-left: var(--extendify--spacing--large, 3rem) !important
    }

    .desktop\:ext-text-left {
        text-align: left !important
    }

    .desktop\:ext-text-center {
        text-align: center !important
    }

    .desktop\:ext-text-right {
        text-align: right !important
    }
}