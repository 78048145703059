.questionCards .img-container img {
    width: 100%;
    height: auto;
    border-radius: 20px;
}

.questionCards .img-container img:hover {
    scale: 1.1;
    transition: 0.3s ease-in-out;
}

.questionCards .img-container {
    margin: 10px;
    overflow: hidden;
}

.questionCards .image-box figure {
    margin-top: 20px;
}

.questionCards .news-block-one .inner-box .lower-content {
    position: relative;
    display: block;
    padding: 0px 20px;
    height: 150px;
}