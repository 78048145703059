/* Css For Printpark */

/************ TABLE OF CONTENTS ***************
1. Fonts
2. Reset
3. Global
4. Main Header/style-one/style-two
5. Main Slider/style-one/style-two
6. Intro Section
7. Welcome Section
9. Cta Section
8. Research Fields
10. Testimonial Section
11. Researches Section
12. Team Section
14. Video
15. Fact Counter
16. News Section
19. Clients Section
20. Main Footer
21. Footer Bottom
22. Research Style Two
23. Innovative Solution
24. Discover Section
25. Experience section
26. Chooseus Section
27. News Style Two
28. Page Title
29. Research Page Section
30. Research Details
31. Professor Page Section
32. Professor Details
33. About Section
34. Error Section
35. Blog Page Section
36. Blog Details
37. Blog Sidebar
38. Contact Section



**********************************************/



/*** 

====================================================================
  Reset
====================================================================

 ***/
* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
}


/*** 

====================================================================
  Global Settings
====================================================================

 ***/


body {
  font-size: 17px;
  color: #7b7675;
  line-height: 28px;
  font-weight: 400;
  background: #ffffff;
  font-family: 'Outfit', sans-serif;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  -webkit-font-smoothing: antialiased;
}

@media (min-width:1200px) {
  .container {
    max-width: 1200px;
    padding: 0px 15px;
  }
}

.large-container {
  max-width: 1550px;
  padding: 0px 15px;
  margin: 0 auto;
}

.container-fluid {
  padding: 0px;
}

.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
}

.small-container {
  max-width: 680px;
  margin: 0 auto;
}

.desktopcorevalue {
  margin-left: 50px;
}



.boxed_wrapper {
  position: relative;
  margin: 0 auto;
  overflow: hidden !important;
  width: 100%;
  min-width: 300px;
}


a {
  text-decoration: none;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

a:hover {
  text-decoration: none;
  outline: none;
}

input,
button,
select,
textarea {
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  font-size: 16px;
  background: transparent;
}

::-webkit-input-placeholder {
  color: inherit;
}

::-moz-input-placeholder {
  color: inherit;
}

::-ms-input-placeholder {
  color: inherit;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

input {
  transition: all 500ms ease;
}

button:focus,
input:focus,
textarea:focus {
  outline: none;
  box-shadow: none;
  transition: all 500ms ease;
}

p {
  position: relative;
  font-family: 'Outfit', sans-serif;
  color: #7b7675;
  font-weight: 400;
  margin: 0px;
  transition: all 500ms ease;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-family: 'Outfit', sans-serif;
  font-weight: 400;
  color: #0c0c0c;
  margin: 0px;
  transition: all 500ms ease;
}

/* Preloader */

.handle-preloader {
  align-items: center;
  -webkit-align-items: center;
  display: flex;
  display: -ms-flexbox;
  height: 100%;
  justify-content: center;
  -webkit-justify-content: center;
  position: fixed;
  background: #FA5E5E;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 9999999;
}

.preloader-close {
  position: fixed;
  z-index: 99999999;
  font-size: 26px;
  background: #fff;
  width: 40px;
  height: 40px;
  line-height: 36px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  right: 30px;
  top: 30px;
  color: red;
}

.handle-preloader .animation-preloader {
  position: absolute;
  z-index: 100;
}

.handle-preloader .animation-preloader .spinner {
  animation: spinner 1s infinite linear;
  border-radius: 50%;
  height: 150px;
  margin: 0 auto 45px auto;
  width: 150px;
}

.handle-preloader .animation-preloader .txt-loading {
  text-align: center;
  user-select: none;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:before {
  animation: letters-loading 4s infinite;
  content: attr(data-text-preloader);
  left: 0;
  opacity: 0;
  top: 0;
  position: absolute;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading {
  font-family: 'Outfit', sans-serif;
  font-weight: 500;
  letter-spacing: 15px;
  display: inline-block;
  position: relative;
  font-size: 70px;
  line-height: 70px;
  text-transform: uppercase;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(2):before {
  animation-delay: 0.2s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(3):before {
  animation-delay: 0.4s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(4):before {
  animation-delay: 0.6s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(5):before {
  animation-delay: 0.8s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(6):before {
  animation-delay: 1s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(7):before {
  animation-delay: 1.2s;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:nth-child(8):before {
  animation-delay: 1.4s;
}

.handle-preloader .loader-section {
  background-color: #ffffff;
  height: 100%;
  position: fixed;
  top: 0;
  width: calc(50% + 1px);
}

.preloader .loaded .animation-preloader {
  opacity: 0;
  transition: 0.3s ease-out;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading {
  color: #ffffff;
}

.handle-preloader .animation-preloader .txt-loading .letters-loading:before {
  color: #ffffff;
}

.handle-preloader .animation-preloader .spinner {
  border: 3px solid #ffffff;
  border-top-color: rgba(255, 255, 255, 0.5);
}

/* AnimaciÃ³n del preloader */
@keyframes spinner {
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes letters-loading {

  0%,
  75%,
  100% {
    opacity: 0;
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@media screen and (max-width: 767px) {
  .handle-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }
}

@media screen and (max-width: 500px) {
  .handle-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }

  .handle-preloader .animation-preloader .txt-loading .letters-loading {
    font-size: 30px;
    letter-spacing: 10px;
  }
}


.centred {
  text-align: center;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.special_fonts {
  font-family: 'DM Sans', sans-serif;

}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
  transition-delay: .1s;
  transition-timing-function: ease-in-out;
  transition-duration: .7s;
  transition-property: all;
}

/** button **/


.theme-btn {
  position: relative;
  display: inline-block;
  overflow: hidden;
  vertical-align: middle;
  font-size: 14px;
  line-height: 26px;
  font-weight: 800;
  font-family: 'Outfit', sans-serif;
  text-align: center;
  padding: 14px 30px;
  text-transform: uppercase;
  border-radius: 35px;
  color: #fff !important;
  z-index: 1;
  transition: all 500ms ease;
}

.theme-btn.btn-one {}

.theme-btn:before {
  position: absolute;
  content: '';
  width: 0%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;
  transition: all 500ms ease;
}

.theme-btn:hover:before {
  width: 100%;
}





.row {
  --bs-gutter-x: 30px;
}

.pagination {
  position: relative;
  display: block;
}

.pagination li {
  position: relative;
  display: inline-block;
  margin: 0px 3px;
}

.pagination li:last-child {
  margin: 0px !important;
}

.pagination li a,
.pagination li>span {
  position: relative;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  height: 52px;
  width: 52px;
  line-height: 52px;
  font-family: 'Outfit', sans-serif;
  text-align: center;
  color: #0c0c0c;
  border: 1px dashed #e0dad9;
  border-radius: 50%;
  z-index: 1;
  transition: all 500ms ease;
}

.pagination li a:hover,
.pagination li a.current,
.pagination li.active a,
.pagination li>span {
  color: #fff;
}

.pagination li a:before,
.pagination li>span:before {
  position: absolute;
  content: '';
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  left: -1px;
  top: -1px;
  border-radius: 50%;
  z-index: -1;
  transform: scale(0, 0);
  transition: all 500ms ease;
}

.pagination li a:hover:before,
.pagination li a.current:before,
.pagination li>span:before,
.pagination li.active a:before {
  transform: scale(1, 1);
}

.sec-pad {
  padding: 115px 0px 120px 0px;
}

.sec-pad-2 {
  padding: 120px 0px;
}


.mr-0 {
  margin: 0px !important;
}

.scroll-top {
  width: 50px;
  padding: 5px;
  border: 1px dashed rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  font-size: 30px;
  color: #fff;
  text-align: center;
  transition: all 500ms ease;
}

.scroll-top:hover {}



.sec-title {
  position: relative;
  display: block;
}

.sec-title h6 {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-weight: 800;
  margin-bottom: 14px;
  text-transform: uppercase;
  padding-left: 28px;
}

.sec-title h6:before {
  position: absolute;
  content: "\f112";
  font-family: 'flaticon';
  font-size: 18px;
  left: 0px;
  top: -1px;
}

.sec-title h2 {
  color: #D73439;
  position: relative;
  display: block;
  font-size: 48px;
  line-height: 58px;
  font-weight: 700;
  margin: 0px;
}

.sec-title.light h2 {
  color: #fff;
}

.owl-dots-none .owl-dots,
.owl-nav-none .owl-nav {
  display: none !important;
}

.sec-title p {
  margin-top: 20px;
}





/*** 

====================================================================
                        Home-Page-One
====================================================================

***/


/** main-header **/

.main-header {
  position: relative;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 999;
  width: 100%;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.sticky-header {
  position: fixed;
  opacity: 0;
  visibility: hidden;
  left: 0px;
  top: 0px;
  width: 100%;
  z-index: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.fixed-header .sticky-header {
  z-index: 999;
  opacity: 1;
  visibility: visible;
  -ms-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -op-animation-name: fadeInDown;
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
  -ms-animation-duration: 500ms;
  -moz-animation-duration: 500ms;
  -op-animation-duration: 500ms;
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
  -ms-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  -op-animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -ms-animation-iteration-count: 1;
  -moz-animation-iteration-count: 1;
  -op-animation-iteration-count: 1;
  -webkit-animation-iteration-count: 1;
  animation-iteration-count: 1;
}


/** main-header **/

.main-header {
  position: relative;
  background: #fff;
}

.header-top {
  position: relative;
  padding: 20px 0px;
}

.header-top .outer-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 50px;
}

.header-top .info-list {
  position: relative;
  display: flex;
  align-items: center;
}

.header-top .info-list li {
  position: relative;
  margin-right: 20px;
  padding-left: 46px;
  font-size: 17px;
  line-height: 36px;
  font-weight: 400;
}

.header-top .info-list li:last-child {
  margin: 0px !important;
}

.header-top .info-list li i {
  position: absolute;
  display: inline-block;
  left: 0px;
  top: 0px;
  width: 36px;
  height: 36px;
  line-height: 40px;
  background: #faf1ef;
  text-align: center;
  font-size: 18px;
  color: #fd6145;
  border-radius: 50%;
}

.header-top .info-list li a {
  display: inline-block;
  color: #0c0c0c;
}

.header-top .text {
  position: relative;
  display: inline-block;
  background: #faf1ef;
  padding: 4px 20px;
  border-radius: 30px;
}

.header-top .text p {
  position: relative;
  color: #7b7675;
}

.header-top .text p a {
  display: inline-block;
  color: #7b7675;
}

.header-top .text p a:hover {}

.header-top .text strong {
  font-weight: 400;
  color: #0c0c0c;
}

.header-top .text span {}

.header-top .text2 p i {
  position: absolute;
  left: 0px;
  top: 4px;
  font-size: 20px;
}

.header-top .text2 {
  position: relative;
  display: inline-block;
  background: #faf1ef;
  padding: 4px 20px;
  border-radius: 30px;
}

.header-top .text2 p {
  position: relative;
  color: #7b7675;
}

.header-top .text2 p a {
  display: inline-block;
  color: #7b7675;
}

.header-top .text2 p a:hover {}

.header-top .text2 strong {
  font-weight: 400;
  color: #0c0c0c;
}


.header-top .text2 p i {
  position: absolute;
  left: 0px;
  top: 4px;
  font-size: 20px;
}

.header-top .social-links {
  position: relative;
  display: flex;
  align-items: center;
}

.header-top .social-links li {
  position: relative;
  display: inline-block;
  margin-right: 10px;
}

.header-top .social-links li:last-child {
  margin: 0px !important;
}

.header-top .social-links li span {
  position: relative;
  display: block;
  font-size: 17px;
  line-height: 36px;
  color: #0c0c0c;
}

.header-top .social-links li a {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  font-size: 16px;
  color: #0c0c0c;
  background: #faf1ef;
  text-align: center;
  border-radius: 50%;
}

.header-top .social-links li a:hover {
  color: #fff;
}

.main-header .outer-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 50px 0px 130px;
}

.main-header .outer-box1 {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 50px 0px 130px;
}

.main-header .logo-box {
  position: relative;
  margin-right: 150px;
}

.main-header .outer-box .left-column {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header .outer-box1 .left-column {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header .menu-right-content {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header .menu-right-content .search-box {
  position: relative;
  width: 200px;
  margin-right: 20px;
}

.main-header .menu-right-content .search-box input[type='search'] {
  position: relative;
  display: block;
  width: 100%;
  height: 52px;
  border: 1px solid #eee6e5;
  border-radius: 35px;
  font-size: 17px;
  color: #7b7675;
  padding: 10px 50px 10px 20px;
}

.main-header .menu-right-content .search-box button {
  position: absolute;
  top: 15px;
  right: 20px;
  font-size: 20px;
  color: #0c0c0c;
  cursor: pointer;
  transition: all 500ms ease;
}

.main-header .menu-right-content .search-box input:focus {}

.main-header .menu-right-content .search-box input:focus+button,
.main-header .menu-right-content .search-box button:hover {}

.main-header .header-lower .menu-right-content {
  padding-bottom: 22px;
}

.main-header .menu-right-content .btn-box {
  margin-right: 20px;
}

.main-header .menu-right-content .btn-box .theme-btn {
  padding: 13px 30px;
}

.main-header .menu-right-content .option-box {
  position: relative;
  display: flex;
  align-items: center;
}

.main-header .menu-right-content .option-box li {
  position: relative;
  display: inline-block;
  margin-right: 25px;
}

.main-header .menu-right-content .option-box li:last-child {
  margin: 0px !important;
  padding-left: 25px;
}

.main-header .menu-right-content .option-box li.nav-toggler {
  position: relative;
  display: inline-block;
  font-size: 26px;
  color: #0c0c0c;
  cursor: pointer;
  transition: all 500ms ease;
}

.main-header .menu-right-content .option-box li.nav-toggler:hover {}

.main-header .menu-right-content .option-box li.nav-toggler:before {
  position: absolute;
  content: '';
  border-left: 1px dashed #d8d2d0;
  width: 1px;
  height: 36px;
  left: 0px;
  top: -6px;
}

.main-header .menu-right-content .option-box li a {
  position: relative;
  display: inline-block;
  font-size: 24px;
  color: #0c0c0c;
}

.main-header .menu-right-content .option-box li a:hover {}

.main-header .menu-right-content .option-box li.shop-cart a {
  padding-right: 23px;
}

.main-header .menu-right-content .option-box li a span {
  position: absolute;
  display: inline-block;
  top: 4px;
  right: 0px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 14px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-weight: 700;
  border-radius: 50%;
  text-align: center;
}

.sticky-header .main-menu .navigation>li>a {
  padding-top: 30px;
}

.sticky-header .main-menu .navigation>li.dropdown>a:before {
  top: 28px;
}

.main-header .logo-box {
  position: relative;
  margin-right: 300px;
}

.main-header .logo-box .shape {
  position: absolute;
  left: -81px;
  top: -16px;
  width: 603px;
  height: 71px;
  background-repeat: no-repeat;
  z-index: -1;
}

#shapeimg {
  height: 33px;
  width: 230px;
}

.main-header .logo-box .logo {
  position: relative;
  /* top: 14px; */
  margin: 0px;
}

.logo1 {
  height: 60px;
  width: 445px;
}


/** xs-sidebar **/

.xs-sidebar-group .xs-overlay {
  left: 0%;
  top: 0;
  position: fixed;
  height: 100%;
  width: 20%;
  transform: scaleX(0);
  transform-origin: left center;
  cursor: url(./../images/icons/cross-out.png),
    pointer;
  z-index: 9999999;
  border-right: 1px solid rgba(255, 255, 255, 0.10);
  transition: transform .65s .3s cubic-bezier(0.7, 0, 0.2, 1);
}

.xs-sidebar-group.isActive .xs-overlay {
  transform: scaleX(1);
  transition-delay: .03s;
}

.xs-sidebar-group .xs-overlay-2 {
  left: 20%;
  transition-delay: .06s;
}

.xs-sidebar-group .xs-overlay-3 {
  left: 40%;
  transition-delay: .09s;
}

.xs-sidebar-group .xs-overlay-4 {
  left: 60%;
  transition-delay: .12s;
}

.xs-sidebar-group .xs-overlay-5 {
  left: 80%;
  transition-delay: .15s;
}

.xs-sidebar-group .widget-heading {
  position: absolute;
  top: 50px;
  right: 35px;
  z-index: 1;
}

.xs-sidebar-widget {
  position: fixed;
  right: -100%;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 460px;
  z-index: 999999999;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  transition: all 900ms ease;
  visibility: hidden;
  opacity: 0;
}

.xs-sidebar-group.isActive .xs-sidebar-widget {
  opacity: 1;
  visibility: visible;
  right: 0;
  -webkit-transition: all 0.7s cubic-bezier(0.9, 0.03, 0, 0.96) 0.6s;
  -o-transition: all 0.7s cubic-bezier(0.9, 0.03, 0, 0.96) 0.6s;
  transition: all 0.7s cubic-bezier(0.9, 0.03, 0, 0.96) 0.6s;
}

.sidebar-textwidget {
  padding: 50px 40px 50px 40px;
}

.xs-sidebar-group .close-side-widget {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #0e132d;
  color: #0e132d;
  border-radius: 50%;
  font-weight: 400;
  font-size: 20px;
}

.sidebar-widget-container {
  position: relative;
  opacity: 0;
  visibility: hidden;
}

.xs-sidebar-group.isActive .sidebar-widget-container {
  opacity: 1;
  visibility: visible;
}

.xs-bg-black {
  background-color: #101127;
}

.sidebar-info-contents .content-inner {
  position: relative;
}

.sidebar-info-contents .content-inner .logo {
  padding: 0px 0px 40px;
}

.sidebar-info-contents .content-inner .logo img {
  display: inline-block;
  max-width: 100%;
}

.sidebar-info-contents .content-inner .content-box {
  position: relative;
}

.sidebar-info-contents .content-inner .content-box h4 {
  position: relative;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.sidebar-info-contents .content-inner .content-box p {
  position: relative;
  font-size: 15px;
  margin-bottom: 25px;
}

.sidebar-info-contents .content-inner .content-box .theme-btn-two {
  padding: 10px 50px;
}

.sidebar-info-contents .content-inner .contact-info {
  position: relative;
  margin-top: 60px;
}

.sidebar-info-contents .content-inner .contact-info ul li {
  position: relative;
  display: block;
  font-size: 15px;
  color: #676767;
  margin-bottom: 3px;
}

.sidebar-info-contents .content-inner .contact-info ul li a {
  color: #676767;
}

.sidebar-info-contents .content-inner .contact-info ul li a:hover {}

.sidebar-info-contents .content-inner .contact-info h4 {
  position: relative;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}

.sidebar-info-contents .content-inner .social-box {
  position: relative;
  margin-top: 20px;
  margin-bottom: 30px;
}

.sidebar-info-contents .content-inner .social-box li {
  position: relative;
  display: inline-block;
  margin-right: 6px;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.sidebar-info-contents .content-inner .social-box li a {
  position: relative;
  width: 36px;
  height: 36px;
  color: #75767b;
  z-index: 1;
  font-size: 13px;
  line-height: 36px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  background: transparent;
  border: 1px solid #e5e5e5;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.sidebar-info-contents .content-inner .social-box li a:hover {
  color: #fff;
}



/** main-menu **/

.main-menu .navbar-collapse {
  padding: 0px;
  display: block !important;
}

.main-menu .navigation {
  margin: 0px;
}

.main-menu .navigation>li {
  position: inherit;
  float: left;
  z-index: 2;
  margin: 0px 8px;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation>li:last-child {
  margin-right: 0px !important;
}

.main-menu .navigation>li:first-child {
  margin-left: 0px !important;
}

.main-menu .navigation>li>a {
  position: relative;
  display: block;
  text-align: center;
  font-size: 14px;
  line-height: 30px;
  padding: 10px 30px 30px 0px;
  font-weight: 800;
  font-family: 'Outfit', sans-serif;
  opacity: 1;
  color: #0c0c0c;
  z-index: 1;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-menu .navigation>li.current>a,
.main-menu .navigation>li:hover>a {
  color: hwb(358 20% 16%) !important;

}

.main-menu .navigation>li.active>a {
  color: hwb(358 20% 16%) !important;
}

.navitemheader:active {
  color: hwb(358 20% 16%) !important;
}

.navitemheader:focus {
  color: hwb(358 20% 16%) !important;
}


.main-menu .navigation>li.dropdown>a {
  padding-right: 18px;
}

.main-menu .navigation>li.dropdown>a:before {
  position: absolute;
  content: '\f104';
  font-family: 'flaticon';
  font-size: 18px;
  width: 13px;
  height: 6px;
  top: 9px;
  right: 0px;
  font-weight: 300;
  background-repeat: no-repeat;
}

.main-menu .navigation>li>ul {
  position: absolute;
  left: inherit;
  top: 100%;
  width: 220px;
  margin-top: -20px;
  z-index: 100;
  background: #fff;
  display: none;
  opacity: 0;
  padding: 0px 0px;
  visibility: hidden;
  border-radius: 15px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li {
  position: relative;
  width: 100%;
  padding: 0px 20px;
}

.main-menu .navigation>li>ul>li>a {
  position: relative;
  display: block;
  line-height: 24px;
  font-weight: 400;
  font-size: 17px;
  font-family: 'Outfit', sans-serif;
  color: #7b7675;
  text-align: left;
  padding: 14px 0px;
  text-transform: capitalize;
  transition: all 500ms ease;
  border-bottom: 1px dashed #d8d2d0;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li:last-child a {
  border-bottom: none;
}

.main-menu .navigation>li>ul>li>a:hover {
  padding-left: 18px;
}

.main-menu .navigation>li>ul>li>a:before {
  position: absolute;
  content: '';
  background-image: url(./../images/icons/icon-1.png);
  width: 12px;
  height: 12px;
  left: 0px;
  top: 20px;
  opacity: 0;
  transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li>a:hover:before {
  opacity: 1;
}

.main-menu .navigation>li>ul>li.dropdown>a:after {
  position: absolute;
  content: '\f105';
  font-family: 'Font Awesome 5 Free';
  font-size: 14px;
  right: 0px;
  top: 14px;
  display: block;
  text-align: center;
  z-index: 5;
  font-weight: 900;
  transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li.dropdown.current>a:after,
.main-menu .navigation>li>ul>li.dropdown>a:hover:after {}

.main-menu .navigation>li>ul>li>ul {
  position: absolute;
  left: 100%;
  top: 0%;
  margin-top: 15px;
  margin-left: 10px;
  width: 220px;
  z-index: 100;
  display: none;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
  visibility: hidden;
  border-radius: 15px;
  padding: 10px 0px;
  background: #fff;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li>ul:before {
  position: absolute;
  content: '';
  left: -10px;
  top: 0px;
  width: 10px;
  height: 100%;
}

.main-menu .navigation>li>ul>li>ul.from-right {
  left: auto;
  right: 0px;
}

.main-menu .navigation>li>ul>li>ul>li {
  position: relative;
  width: 100%;
  padding: 0px 20px;
}

.main-menu .navigation>li>ul>li>ul>li:last-child {
  border-bottom: none;
}

.main-menu .navigation>li>ul>li>ul>li>a {
  position: relative;
  display: block;
  line-height: 24px;
  font-weight: 400;
  font-size: 17px;
  font-family: 'Outfit', sans-serif;
  color: #7b7675;
  text-align: left;
  padding: 14px 0px;
  text-transform: capitalize;
  transition: all 500ms ease;
  border-bottom: 1px dashed #d8d2d0;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li>ul>li:last-child>a {
  border-bottom: none;
}

.main-menu .navigation>li>ul>li>ul>li>a:hover {
  padding-left: 18px;
}

.main-menu .navigation>li>ul>li>ul>li>a:before {
  position: absolute;
  content: '';
  background-image: url(./../images/icons/icon-1.png);
  width: 12px;
  height: 12px;
  left: 0px;
  top: 20px;
  opacity: 0;
  transition: all 500ms ease;
}

.main-menu .navigation>li>ul>li>ul>li>a:hover:before {
  opacity: 1;
}

.main-menu .navigation>li>ul>li>ul>li.dropdown>a:after {
  font-family: 'Font Awesome 5 Pro';
  content: "\f105";
  position: absolute;
  right: 20px;
  top: 12px;
  display: block;
  line-height: 24px;
  font-size: 16px;
  font-weight: 900;
  z-index: 5;
}

.main-menu .navigation>li.dropdown:hover>ul,
.main-menu .navigation>li.dropdown:hover>.megamenu {
  visibility: visible;
  opacity: 1;
  margin-top: -10px;
  top: 100%;
}

.main-menu .navigation li>ul>li.dropdown:hover>ul {
  visibility: visible;
  opacity: 1;
  top: 0%;
  margin-top: 0px;
}

.main-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: -32px;
  top: 66px;
  width: 34px;
  height: 30px;
  text-align: center;
  font-size: 18px;
  line-height: 26px;
  color: #3b3b3b;
  cursor: pointer;
  display: none;
  z-index: 5;
  transition: all 500ms ease;
}

.main-menu .navigation li.current.dropdown .dropdown-btn,
.main-menu .navigation li:hover .dropdown-btn {}

.main-menu .navigation li.dropdown ul li.dropdown .dropdown-btn {
  display: none;
}

.menu-area .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  color: #3786ff;
  display: none;
}

.menu-area .mobile-nav-toggler .icon-bar {
  position: relative;
  height: 2px;
  width: 30px;
  display: block;
  margin-bottom: 5px;
  background-color: #1c1c1c;
  -webkit-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.menu-area .mobile-nav-toggler .icon-bar:last-child {
  margin-bottom: 0px;
}


/** megamenu-style **/

.main-menu .navigation>li.dropdown>.megamenu {
  position: absolute;
  width: 100%;
  padding: 30px 50px;
  left: 0px;
}

.main-menu .navigation li.dropdown .megamenu li h4 {
  margin-bottom: 10px;
}


/** mobile-menu **/

.nav-outer .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  color: #3786ff;
  display: none;
}

.mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  padding-right: 30px;
  max-width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  z-index: 999999;
  transition: all 900ms ease;
}

.mobile-menu .navbar-collapse {
  display: block !important;
}

.mobile-menu .nav-logo {
  position: relative;
  padding: 50px 25px;
  text-align: left;
}

.mobile-menu-visible {
  overflow: hidden;
}

.mobile-menu-visible .mobile-menu {
  opacity: 1;
  visibility: visible;
}

.mobile-menu .menu-backdrop {
  position: fixed;
  left: 0%;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: all 900ms ease;
  background-color: #000;
}

.mobile-menu-visible .mobile-menu .menu-backdrop {
  opacity: 0.70;
  visibility: visible;
  right: 100%;
  -webkit-transition: all .8s ease-out 0s;
  -o-transition: all .8s ease-out 0s
}

.mobile-menu .menu-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  background: #0a0807;
  padding: 0px 0px;
  z-index: 5;
  opacity: 0;
  visibility: hidden;
  border-radius: 0px;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  transition: all 900ms ease !important;
}

.mobile-menu-visible .mobile-menu .menu-box {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.7s ease;
  -o-transition: all 0.7s ease;
  transition: all 0.7s ease;
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}

.mobile-menu .close-btn {
  position: absolute;
  right: 25px;
  top: 10px;
  line-height: 30px;
  width: 24px;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
  z-index: 10;
  -webkit-transition: all 0.9s ease;
  -moz-transition: all 0.9s ease;
  -ms-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  transition: all 0.9s ease;
}

.mobile-menu-visible .mobile-menu .close-btn {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.mobile-menu .close-btn:hover {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mobile-menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  float: none;
}

.mobile-menu .navigation li {
  position: relative;
  display: block;
  border-top: 1px solid rgba(255, 255, 255, 0.10);
}

.mobile-menu .navigation:last-child {
  border-bottom: 1px solid rgba(255, 255, 255, 0.10);
}

.mobile-menu .navigation li>ul>li:first-child {
  border-top: 1px solid rgba(255, 255, 255, 0.10);
}

.mobile-menu .navigation li>a {
  position: relative;
  display: block;
  line-height: 24px;
  padding: 10px 25px;
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mobile-menu .navigation li ul li>a {
  font-size: 16px;
  margin-left: 20px;
  text-transform: capitalize;
}

.mobile-menu .navigation li>a:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  border-left: 5px solid #fff;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mobile-menu .navigation li.current>a:before {
  height: 100%;
}

.mobile-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: 6px;
  top: 6px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  color: #ffffff;
  background: rgba(255, 255, 255, 0.10);
  cursor: pointer;
  border-radius: 2px;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 5;
}

.mobile-menu .navigation li.dropdown .dropdown-btn.open {
  color: #ffffff;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.mobile-menu .navigation li>ul,
.mobile-menu .navigation li>ul>li>ul,
.mobile-menu .navigation>li.dropdown>.megamenu {
  display: none;
}

.mobile-menu .social-links {
  position: relative;
  padding: 0px 25px;
}

.mobile-menu .social-links li {
  position: relative;
  display: inline-block;
  margin: 0px 10px 10px;
}

.mobile-menu .social-links li a {
  position: relative;
  line-height: 32px;
  font-size: 16px;
  color: #ffffff;
  -webkit-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mobile-menu .social-links li a:hover {}

div#mCSB_1_container {
  top: 0px !important;
}

.mobile-menu .contact-info {
  position: relative;
  padding: 120px 30px 20px 30px;
}

.mobile-menu .contact-info h4 {
  position: relative;
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 20px;
}

.mobile-menu .contact-info ul li {
  position: relative;
  display: block;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.80);
  margin-bottom: 3px;
}

.mobile-menu .contact-info ul li a {
  color: rgba(255, 255, 255, 0.80);
}

.mobile-menu .contact-info ul li a:hover {}

.mobile-menu .contact-info ul li:last-child {
  margin-bottom: 0px;
}

.main-header .outer-box {
  position: relative;
}

.tabs-box .tab {
  position: relative;
  display: none;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.tabs-box .tab.active-tab {
  display: block;
}

.tabs-box .tab {
  transform: scale(0.9, 0.9) translateY(0px);
}

.tabs-box .tab.active-tab {
  transform: scale(1) translateY(0px);
}

.special-text {
  font-family: 'Carattere', cursive;
}



/** banner-section **/

.banner-section {
  position: relative;
  background: #fff;
  padding: 0px 50px;
}

.banner-section .banner-carousel .slide-item {
  padding: 0px 0px 142px 0px;
  border-radius: 0px 50px 50px 50px;
  overflow: hidden;
}

.banner-carousel .slide-item {
  position: relative;
}

.banner-section .pattern-layer {
  position: absolute;
  left: 0px;
  top: -71px;
  width: 100%;
  height: calc(100% + 71px);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

.banner-carousel .slide-item .image-layer {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 40rem;
  background-repeat: no-repeat;
  background-position: center;
  /* background-size: cover; */
}

.banner-carousel .content-box {
  position: relative;
  width: 100%;
  max-width: 550px;
  z-index: 5;
}

.banner-carousel .content-box h2 {
  position: relative;
  display: block;
  font-size: 43px;
  line-height: 84px;
  font-weight: 700;
  margin-bottom: 12px;
  opacity: 0;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.banner-carousel .active .content-box h2 {
  opacity: 1;
  -webkit-transition-delay: 700ms;
  -moz-transition-delay: 700ms;
  -ms-transition-delay: 700ms;
  -o-transition-delay: 700ms;
  transition-delay: 700ms;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.banner-carousel .content-box h2 span {
  position: relative;
  font-size: 90px;
  color: #F54C4C;
}

.banner-carousel .content-box h2 span::before {
  position: absolute;
  content: '';
  background-image: url(./../images/shape/RED.png);
  width: 300px;
  height: 29px;
  left: 4px;
  bottom: -11px;
  background-repeat: no-repeat;
}

.banner-carousel .content-box p {
  position: relative;
  display: block;
  font-size: 19px;
  line-height: 30px;
  color: #7b7675;
  margin-bottom: 32px;
  opacity: 0;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.banner-carousel .active .content-box p {
  opacity: 1;
  -webkit-transition-delay: 1000ms;
  -moz-transition-delay: 1000ms;
  -ms-transition-delay: 1000ms;
  -o-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.banner-carousel .content-box .btn-box {
  opacity: 0;
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.banner-carousel .active .content-box .btn-box {
  opacity: 1;
  -webkit-transition-delay: 1300ms;
  -moz-transition-delay: 1300ms;
  -ms-transition-delay: 1300ms;
  -o-transition-delay: 1300ms;
  transition-delay: 1300ms;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.owl-nav span {
  font-family: 'flaticon' !important;
}

.banner-section .owl-nav {
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -44px;
}

.banner-section .owl-nav:before {
  position: absolute;
  content: '';
  background: #e1cfe6;
  width: 1px;
  height: 50px;
  left: 27px;
  top: 16px;
}

.banner-section .owl-nav button {
  position: absolute;
  display: inline-block;
  left: 0px;
  width: 56px;
  height: 56px;
  line-height: 56px;
  background: #fff;
  font-size: 30px;
  color: #0c0c0c;
  text-align: center;
  border-radius: 50%;
  transition: all 500ms ease;
}

.banner-section .owl-nav button:hover {
  color: #fff;
}

.banner-section .owl-nav button.owl-prev {
  bottom: 0px;
}

.banner-section .owl-nav button.owl-next {
  top: 80px;
}

.banner-section .owl-nav button.owl-prev:before {
  position: absolute;
  content: 'prev';
  font-size: 14px;
  line-height: 56px;
  font-family: 'Outfit', sans-serif;
  color: #0c0c0c;
  font-weight: 800;
  text-transform: uppercase;
  writing-mode: vertical-lr;
  left: 0px;
  top: -55px;
  transform: rotate(-180deg);
}

.banner-section .owl-nav button.owl-next:before {
  position: absolute;
  content: 'next';
  font-size: 14px;
  line-height: 56px;
  font-family: 'Outfit', sans-serif;
  color: #0c0c0c;
  font-weight: 800;
  text-transform: uppercase;
  writing-mode: vertical-lr;
  left: 0px;
  top: 75px;
  transform: rotate(-180deg);
}


/** service-section **/

.service-section {
  position: relative;
}

.service-block-one .inner-box {
  position: relative;
  display: block;
  border: 1px dashed #d8d2d0;
  border-radius: 25px;
  padding: 132px 30px 31px 40px;
  overflow: hidden;
}

.service-block-one .inner-box .overlay-shape {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  z-index: -1;
  transition: all 500ms ease;
}

.service-block-one .inner-box:hover .overlay-shape {
  opacity: 1;
}

.service-block-one .inner-box .icon-box {
  position: absolute;
  display: inline-block;
  top: 55px;
  right: 58px;
  width: 60px;
  height: 60px;
  line-height: 68px;
  font-size: 30px;
  color: #fd6145;
  text-align: center;
  border-radius: 50%;
}

.service-block-one .inner-box .icon-box .shape {
  position: absolute;
  left: -20px;
  top: -15px;
  width: 101px;
  height: 91px;
  background-repeat: no-repeat;
  z-index: -1;
  -webkit-animation: zoom-fade 6s infinite linear;
  animation: zoom-fade 6s infinite linear;
}

.service-block-one .inner-box .link-btn {
  position: absolute;
  left: 0px;
  top: 39px;
  background: #fff;
  width: 94px;
  height: 60px;
  box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.10);
  border-radius: 0px 30px 30px 0px;
}

.service-block-one .inner-box .link-btn a {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 58px;
  background: #faf1ef;
  text-align: center;
  border-radius: 50%;
  font-size: 30px;
  top: 5px;
  right: 5px;
  cursor: default;
}

.service-block-one .inner-box .link-btn a:hover {
  color: #fff;
}

.service-block-one .inner-box h6 {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  text-transform: uppercase;
}

.service-block-one .inner-box h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 9px;
}

.service-block-one .inner-box h3 a {
  position: relative;
  display: inline-block;
  color: #0c0c0c;
}

.service-block-one .inner-box h3 a:hover {}


/** funfact-section **/

.funfact-section {
  position: relative;
  padding: 120px 0px;
}

.funfact-section .content-box {
  position: relative;
  display: block;
  margin-right: 150px;
  background: #0c0c0c;
  border-radius: 25px;
  padding: 62px 50px 67px 50px;
}

.funfact-section .content-box .text-box p {
  color: #aca7a7;
  margin-bottom: 32px;
}

.funfact-section .content-box .sec-title {
  margin-bottom: 21px;
}

.funfact-section .big-text {
  position: absolute;
  top: -90px;
  opacity: 60%;
  right: -125px;
  font-size: 110px;
  line-height: 100px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-weight: 900;
  text-transform: uppercase;
  text-shadow: 3px 0px 0px #e4dcd8;
  writing-mode: vertical-lr;
}

.funfact-block-one {
  position: relative;
  padding-bottom: 40px;
}

.funfact-block-one .inner-box {
  position: relative;
  background: #F8EFEA !important;
  display: block;
  text-align: center;
  border: 1px dashed #c9c8c3;
  border-radius: 25px;
  padding: 31px 20px 0px 20px;
}

.funfact-block-one .inner-box .count-outer {
  position: relative;
  display: inline-block;
  font-size: 48px;
  line-height: 50px;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
  color: #8B8B8B;
  margin-bottom: 2px;
}

.funfact-block:first-child .funfact-block-one:first-child .inner-box .count-outer {
  color: #fd6145;
}

.funfact-block:first-child .funfact-block-one:last-child .inner-box .count-outer {
  color: #fb991a;
}

.funfact-block:first-child .funfact-block-one:first-child .inner-box {
  margin-bottom: 30px;
}

.funfact-block-one .inner-box .count-outer .symble {
  position: relative;
  font-size: 30px;
  top: -16px;
}

.funfact-block-one .inner-box .icon-box {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 76px;
  background: #fff;
  font-size: 30px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06);
  margin-bottom: -15px;
  border-radius: 50%;
  color: #8B8B8B;
  bottom: -20px;
}

.funfact-block:first-child .funfact-block-one:first-child .inner-box .icon-box {
  color: #fd6145;
}

.funfact-block:first-child .funfact-block-one:last-child .inner-box .icon-box {
  color: #fb991a;
}

.funfact-block-one .inner-box p {
  position: relative;
  display: block;
  margin-bottom: 2px;
}


/** about-section **/

.about-section {
  position: relative;
  padding: 120px 0px;
}

.about-section .image-box {
  position: relative;
  display: block;
  padding: 239px 120px 0px 0px;
  margin-right: 20px;
}

.about-section .image-box img {
  width: 100%;
}

.about-section .image-box .image-1 {
  position: relative;
  border-radius: 25px;
}

.about-section .image-box .image-1 img,
.about-section .image-box .image-2 img {
  border-radius: 25px;
}

.about-section .image-box .image-2 {
  position: absolute;
  top: 0px;
  right: 0px;
  border-radius: 25px;
}

.about-section .image-box .image-3 {
  position: absolute;
  right: 0px;
  bottom: 57px;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  border: 1px dashed #dcd9e4;
}

.about-section .image-box .image-3 img {
  border-radius: 10px;
}

.about-section .image-box .image-4 {
  position: absolute;
  top: 75px;
  left: 0px;
}

.about-section .image-box .image-shape .shape {
  position: absolute;
  border-radius: 50%;
  border: 1px dashed #fc7f5a;
}

.about-section .image-box .image-shape .shape-1 {
  left: -103px;
  top: 0px;
  width: 440px;
  height: 440px;
}

.about-section .image-box .image-shape .shape-2 {
  left: -83px;
  top: 20px;
  width: 400px;
  height: 400px;
}

.about-section .image-box .image-shape .shape-3 {
  left: -63px;
  top: 40px;
  width: 360px;
  height: 360px;
}

.about-section .content-box {
  position: relative;
  display: block;
}

.about-section .content-box .sec-title {
  margin-bottom: 30px;
}

.about-section .content-box .text-box {
  margin-bottom: 40px;
}

.about-section .content-box .text-box p {
  margin-bottom: 32px;
}

.highlights-list li {
  position: relative;
  display: block;
  padding-left: 60px;
  margin-bottom: 15px;
}

.highlights-list li:last-child {
  margin-bottom: 0px;
}

.highlights-list li i {
  position: absolute;
  display: inline-block;
  left: 0px;
  top: 0px;
  width: 52px;
  height: 52px;
  line-height: 58px;
  background: #faf1ef;
  text-align: center;
  font-size: 24px;
  border-radius: 15px;
  text-align: center;
}

.highlights-list li:nth-child(2) i {
  color: #fb991a;
  background: #faeddc;
}

.highlights-list li:last-child i {
  color: #8B8B8B;
  background: #eef9ec;
}

.highlights-list li a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-family: 'Outfit', sans-serif;
  color: #0c0c0c;
  font-weight: 800;
  padding: 12px 20px;
  border: 1px dashed #d8d2d0;
  border-radius: 15px;
  text-transform: uppercase;
}

.highlights-list li a:hover {}

.highlights-list li:nth-child(2) a:hover {
  border-color: #fb991a;
}

.highlights-list li:last-child a:hover {
  border-color: #8B8B8B;
}

.highlights-list li a span {
  position: relative;
  transition: all 500ms ease;
}

/* .highlights-list li a:hover span{
  padding-right: 34px;
} */

/* .highlights-list li a span:before{
  position: absolute;
  content: "\f10e";
  font-family: 'flaticon';
  font-size: 30px;
  top: -3px;
  right: 0px;
  opacity: 0;
  font-weight: 400;
  transition: all 500ms ease;
} */

.highlights-list li a:hover span:before {
  opacity: 1;
}

.highlights-list li:nth-child(2) a span:before {
  color: #fb991a;
}

.highlights-list li:last-child a span:before {
  color: #8B8B8B;
}


/** categories-section **/

.categories-section {
  position: relative;
}

.categories-block-one .inner-box {
  position: relative;
  display: block;
  background: #ffffff;
  border-radius: 25px;
  padding: 32px 20px 0px 20px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
  margin-bottom: 60px;
  overflow: hidden;
  transition: all 500ms ease;
}

.categories-block-one .inner-box .shape {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 139px;
  height: 139px;
  background-repeat: no-repeat;
  transition: all 500ms ease;
}

.categories-block-one .inner-box:hover .shape {
  opacity: 0;
}

.categories-block-one .inner-box .overlay-shape {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 139px;
  height: 139px;
  opacity: 0;
  background-repeat: no-repeat;
  transition: all 500ms ease;
}

.categories-block-one .inner-box:hover .overlay-shape {
  opacity: 1;
}

.categories-block-one .inner-box:hover {
  transform: translateY(-10px);
}

.categories-block-one .inner-box h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 8px;
}

.categories-block-one .inner-box h3 a {
  position: relative;
  display: inline-block;
  color: #0c0c0c;
}

.categories-block-one .inner-box h3 a:hover {}

.categories-block-one .inner-box p {
  display: block;
  margin-bottom: 32px;
}

.categories-block-one .inner-box .image-box {
  position: relative;
  display: inline-block;
  max-width: 220px;
  width: 100%;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 40px !important;
}

.categories-block-one .inner-box .image-box img {
  width: 100%;
  /* border-radius: 50%; */
  transition: all 500ms ease;
}

.categories-block-one .inner-box .image-box:before,
.categories-block-one .inner-box .image-box:after {
  position: absolute;
  content: '';
  background: #fff;
  width: 5px;
  height: 100%;
  top: 0px;
  z-index: 1;
}

.categories-block-one .inner-box .image-box:before {
  left: 0px;
}

.categories-block-one .inner-box .image-box:after {
  right: 0px;
}

.categories-block-one .inner-box .link-box {
  position: relative;
  display: block;
  padding: 17px 0px 18px 0px;
}

.categories-block-one .inner-box .link-box:before {
  position: absolute;
  content: '';
  border-top: 1px dashed #d8d2d0;
  width: calc(100% + 40px);
  height: 1px;
  left: -20px;
  top: 0px;
}

.categories-block-one .inner-box .link-box a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Outfit', sans-serif;
  color: #0c0c0c;
  font-weight: 800;
  text-transform: uppercase;
  padding-left: 32px;
}

.categories-block-one .inner-box .link-box a:hover {}

.categories-block-one .inner-box .link-box a:before {
  position: absolute;
  content: "\f10e";
  font-family: 'flaticon';
  font-size: 24px;
  top: -1px;
  left: 0px;
  font-weight: 400;
  transition: all 500ms ease;
}


/** project-section **/

.project-section {
  position: relative;
  padding: 115px 0px 90px 0px;
}

.sec-title .btn-box {
  position: absolute;
  right: 0px;
  bottom: 12px;
}

.project-block-one .inner-box {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.project-block-one .inner-box:before {
  position: absolute;
  content: '';
  border: 1px dashed #d8d2d0;
  border-radius: 25px;
  width: calc(100% + 30px);
  height: calc(100% + 16px);
  left: -15px;
  bottom: -1px;
  opacity: 0;
  transition: all 500ms ease;
}

.project-block-one .inner-box:hover:before {
  opacity: 1;
}

.project-block-one .inner-box .image-box {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 25px;
}

.project-block-one .inner-box .image-box img {
  width: 100%;
  border-radius: 25px;
  transition: all 500ms ease;
}

.project-block-one .inner-box:hover .image-box img {
  transform: scale(1.05);
}

.project-block-one .inner-box .content-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
}

.project-block-one .inner-box h6 {
  position: absolute;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  padding: 6px 15px 2px 15px;
  border-radius: 25px;
  left: 0px;
  top: 0px;
  opacity: 0;
  transition: all 500ms ease;
}

.project-block-one .inner-box:hover h6 {
  opacity: 1;
  left: 20px;
  top: 20px;
}

.project-block-one .inner-box h3 {
  position: absolute;
  left: 0px;
  bottom: -20px;
  width: 100%;
  text-align: center;
  background: #fff;
  padding: 17px 15px;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  border-radius: 0px 0px 5px 5px;
  opacity: 0;
  transition: all 500ms ease;
}

.project-block-one .inner-box:hover h3 {
  bottom: 0px;
  opacity: 1;
}

.project-block-one .inner-box h3 a {
  display: inline-block;
  color: #0c0c0c;
}

.project-block-one .inner-box h3 a:hover {}

.project-block-one .inner-box .link-btn {
  position: absolute;
  right: 20px;
  bottom: 88px;
}

.project-block-one .inner-box .link-btn a {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 48px;
  background: #fff;
  text-align: center;
  font-size: 30px;
  color: #0c0c0c;
  border-radius: 50%;
  font-weight: 400;
  transform: scale(0, 0);
}

.project-block-one .inner-box .link-btn a:hover {
  color: #fff;
}

.project-block-one .inner-box:hover .link-btn a {
  transform: scale(1);
}

.project-block-one .inner-box .view-btn {
  position: absolute;
  right: 66px;
  bottom: 91px;
}

.project-block-one .inner-box .view-btn a {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 44px;
  background: #fff;
  text-align: center;
  font-size: 16px;
  color: #0c0c0c;
  border-radius: 50%;
  font-weight: 400;
  transform: scale(0, 0);
}

.project-block-one .inner-box .view-btn a:hover {
  color: #fff;
}

.project-block-one .inner-box:hover .view-btn a {
  transform: scale(1);
}

.project-section .sortable-masonry .filters {
  position: relative;
  display: block;
  overflow-x: auto;
  max-width: 1170px;
}

.sortable-masonry .filters-box {
  position: relative;
  margin-bottom: 51px;
}

.project-section .sortable-masonry .filters-box:before {
  position: absolute;
  content: '';
  width: 50px;
  height: 100%;
  top: 0px;
  right: 0px;
  box-shadow: inset -25px 0px 20px 0px rgb(255 255 255 / 90%);
  z-index: 1;
}

.project-section .sortable-masonry .filter-tabs {
  position: relative;
}

.project-section .sortable-masonry .filter-tabs li {
  position: relative;
  display: inline-block;
  float: left;
  margin-right: 11px;
  padding-right: 41px;
  font-size: 20px;
  line-height: 34px;
  font-family: 'Outfit', sans-serif;
  font-weight: 600;
  color: #afa7a7;
  cursor: pointer;
  transition: all 500ms ease;
}

.project-section .sortable-masonry .filter-tabs li:last-child {
  padding: 0px !important;
  margin: 0px !important;
}

.sortable-masonry .filter-tabs li.active {}

.sortable-masonry ::-webkit-scrollbar {
  display: none !important;
}

.project-section .sortable-masonry .filter-tabs li:before {
  position: absolute;
  content: "\f117";
  font-family: 'flaticon';
  font-size: 30px;
  top: 2px;
  right: 0px;
  color: #b0a8a8;
  font-weight: 400;
}

.project-section .sortable-masonry .filter-tabs li:last-child:before {
  display: none;
}

.project-section .sec-title {
  margin-bottom: 42px;
}

.bg-color-1 {
  background-color: #faeddd;
}


/** why-section **/


.why-section {
  position: relative;
  padding: 115px 0px 112px 0px;
}

.why-section .left-content {
  position: relative;
  display: block;
  text-align: right;
}

.why-section .single-item {
  position: relative;
  display: block;
  margin-bottom: 32px;
}

.why-section .single-item:last-child {
  margin-bottom: 0px;
}

.why-section .single-item .icon-box {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 20px;
  margin-bottom: 3px;
}

.left-column .why-section .single-item .icon-box {
  left: 31px;
}

.right-column .why-section .single-item .icon-box {
  right: 31px;
}

.why-section .single-item h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  padding-bottom: 12px;
  margin-bottom: 17px;
}

.why-section .single-item h3:before {
  position: absolute;
  content: '';
  border-bottom: 1px dashed #fd6145;
  width: 70px;
  height: 1px;
  bottom: 0px;
}

.left-column .why-section .single-item h3:before {
  right: 0px;
}

.right-column .why-section .single-item h3:before {
  left: 0px;
}

.left-column .why-section .single-item:nth-child(2) .icon-box {
  color: #8B8B8B;
}

.left-column .why-section .single-item:last-child .icon-box {
  color: #fb991a;
}

.left-column .why-section .single-item:nth-child(2) h3:before {
  border-bottom: 1px dashed #8B8B8B;
}

.left-column .why-section .single-item:last-child h3:before {
  border-bottom: 1px dashed #fb991a;
}

.right-column .why-section .single-item:first-child .icon-box {
  color: #fb991a;
}

.right-column .why-section .single-item:nth-child(2) .icon-box {
  color: #fd6145;
}

.right-column .why-section .single-item:last-child .icon-box {
  color: #8B8B8B;
}

.right-column .why-section .single-item:first-child h3:before {
  border-bottom: 1px dashed #fb991a;
}

.right-column .why-section .single-item:nth-child(2) h3:before {
  border-bottom: 1px dashed #fd6145;
}

.right-column .why-section .single-item:last-child h3:before {
  border-bottom: 1px dashed #8B8B8B;
}

.why-section .image-box {
  position: relative;
  display: block;
  text-align: center;
  /* max-width: 470px; */
  width: 100%;
  margin: 0 auto;
}

.why-section .image-box img {
  width: 100%;
}

.why-section .image-box .image-shape {
  position: absolute;
  left: -20px;
  bottom: 5px;
  width: 510px;
  height: 451px;
  background-repeat: no-repeat;
}

.why-section .image-box .image {
  position: relative;
  display: block;
}

.tabs-box .tab {
  position: relative;
  display: none;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

.tabs-box .tab.active-tab {
  display: block;
}

.tabs-box .tab {
  transform: scale(0.9, 0.9) translateY(0px);
}

.tabs-box .tab.active-tab {
  transform: scale(1) translateY(0px);
}


/** pricing-section **/

.pricing-section {
  position: relative;
  padding: 40px 0px;
}

.pricing-section1 {
  position: relative;
  /*padding: 120px 0px;*/
}

.pricing-section .tab-btn-box {
  position: relative;
  margin-bottom: 42px;
}

.pricing-section .tab-btn-box .tab-btns {
  position: relative;
  max-width: 300px;
}

.pricing-section .tab-btn-box .tab-btns:before {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 30px;
  width: 70px;
  content: "";
  border-radius: 30px;
  border: 1px dashed #d8d2d0;
}

.pricing-section .tab-btn-box .tab-btns li {
  position: relative;
  display: inline-block;
  float: left;
  min-width: 160px;
  text-align: left;
  font-size: 18px;
  line-height: 30px;
  font-family: 'Outfit', sans-serif;
  color: #afa7a7;
  font-weight: 700;
  cursor: pointer;
  transition: all 500ms ease;
}

.pricing-section .tab-btn-box .tab-btns li.active-btn {
  color: #0c0c0c;
}

.pricing-section .tab-btn-box .tab-btns li:last-child {
  padding-right: 0;
  padding-left: 0px;
  text-align: right;
  margin-left: -35px;
}

.pricing-section .tab-btn-box .tab-btns li.active-btn:before {
  right: 20px;
}

.pricing-section .tab-btn-box .tab-btns li:before {
  position: absolute;
  top: 5px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  content: "";
  right: -20px;
  -webkit-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  transition: all 300ms ease;
}

.pricing-section .tab-btn-box .tab-btns li:last-child:before {
  display: none;
}

.pricing-section .content-box {
  position: relative;
  display: block;
}

.pricing-section .content-box .offer-box {
  position: relative;
  display: block;
  text-align: right;
  font-size: 16px;
  line-height: 16px;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
  margin-bottom: 0px;
}

.pricing-section .content-box .offer-box:before {
  position: absolute;
  content: '';
  background-image: url(./../images/shape/shape-10.png);
  width: 52px;
  height: 50px;
  bottom: 25px;
  right: 20px;
  background-repeat: no-repeat;
}

.pricing-section .sec-title {
  margin-bottom: 21px;
}

.pricing-block-one .pricing-table {
  position: relative;
  display: block;
  background: #fff;
  border: 1px dashed #d8d2d0;
  border-radius: 25px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
  padding: 9px;
}

.pricing-block-one.active-block .pricing-table {}

.pricing-block-one .pricing-table .table-header {
  position: relative;
  display: block;
  background: -webkit-linear-gradient(0deg, #eefaec, #faf1ef 100%);
  border-radius: 25px;
  padding: 28px 24px 15px 20px;
}

.pricing-block-one.active-block .pricing-table .table-header {
  background: #fff;
}

.pricing-block-one .pricing-table .table-header h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  color: #0c0c0c;
  font-weight: 700;
  /*margin-bottom: 18px;*/
}

.pricing-block-one .pricing-table .table-header h2 {
  position: relative;
  display: inline-block;
  font-size: 48px;
  line-height: 50px;
  font-weight: 700;
  left: -12px;
}

.pricing-block-one .pricing-table .table-header h2 .text {
  position: relative;
  font-size: 18px;
  left: -12px;
}

.pricing-block-one .pricing-table .table-header h2 .symble {
  position: relative;
  font-size: 28px;
  top: -15px;
  left: 12px;
}

.pricing-block-one .pricing-table .table-header .theme-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -40px;
  background: #0c0c0c;
}

.pricing-block-one .pricing-table .table-header .theme-btn:before {
  position: absolute;
  content: '';
  width: 0%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: -1;
  transition: all 500ms ease;
}

.pricing-block-one .pricing-table .table-header .theme-btn:hover:before {
  width: 100%;
}

.pricing-block-one .pricing-table .table-header .theme-btn:hover {}

.pricing-block-one {
  position: relative;
  padding-right: 30px;
}

.pricing-block-one .pricing-table .table-header .shape {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  border-radius: 25px;
}

.pricing-block-one .pricing-table .table-content {
  position: relative;
  padding: 10px 20px 0px 20px;
  margin-top: 59px;
}

.pricing-block-one .pricing-table .feature-list li {
  position: relative;
  display: block;
  font-size: 17px;
  line-height: 28px;
  color: #0c0c0c;
  margin-bottom: 12px;
}

.pricing-block-one .pricing-table .feature-list li.light {
  color: #c9c1c1;
}

.pricing-block-one .pricing-table .feature-list li:before {
  position: absolute;
  content: "\f00c";
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 10px;
  color: #fff;
  width: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  border-radius: 2px;
  top: 7px;
  right: 0px;
}

.pricing-block-one .pricing-table .feature-list li.light:before {
  background: #c9c1c1;
}

.pricing-block-one.active-block .pricing-table .feature-list li {
  color: #fff;
}

.pricing-block-one.active-block .pricing-table .feature-list li:before {
  background: #fff;
}


/** testimonial-section **/

.testimonial-section {
  position: relative;
}

.testimonial-block-one .inner-box {
  position: relative;
  display: block;
  border: 1px dashed #4f4f4f;
  border-radius: 25px;
  padding: 31px 40px 39px 40px;
}

.testimonial-block-one .inner-box h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  color: #fff;
  font-weight: 700;
}

.testimonial-block-one .inner-box h3.title-text {
  margin-bottom: 17px;
  padding-left: 45px;
}

.testimonial-block-one .inner-box h3.title-text:before {
  position: absolute;
  content: "\f11a";
  font-family: 'flaticon';
  font-size: 105px;
  left: -39px;
  top: 0px;
  font-weight: 400;
}

.testimonial-block-one .inner-box p {
  display: block;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 27px;
  color: #aca7a7;
}

.testimonial-block-one .inner-box .author-box {
  position: relative;
  display: block;
  padding-left: 80px;
  padding-right: 110px;
}

.testimonial-block-one .inner-box .author-box .author-thumb {
  position: absolute;
  display: inline-block;
  left: 0px;
  top: 6px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  padding: 3px;
}

.testimonial-block-one .inner-box .author-box .author-thumb img {
  width: 100%;
  border-radius: 50%;
}

.testimonial-block-one .inner-box .author-box .author-thumb:before {
  position: absolute;
  content: '';
  width: 60px;
  height: 60px;
  border-radius: 50%;
  left: -2px;
  top: -2px;
  z-index: -1;
}

.testimonial-block-one .inner-box .author-box .author-thumb:after {
  position: absolute;
  content: '';
  width: 56px;
  height: 56px;
  border-radius: 50%;
  left: 0px;
  top: 0px;
  background: #0c0c0c;
  z-index: -1;
}

.testimonial-block-one .inner-box .author-box .designation {
  position: relative;
  display: block;
  font-size: 17px;
  margin-top: 3px;
}

.testimonial-block-one .inner-box .author-box .rating-box {
  position: absolute;
  right: 0px;
  bottom: 5px;
  min-width: 97px;
  padding: 0 15px;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-weight: 700;
  border: 1px solid #fb991a;
  border-radius: 50%;
  text-align: center;
}

.testimonial-block-one .inner-box .author-box .rating-box i {
  position: relative;
  font-size: 16px;
  color: #fb991a;
  margin-right: 5px;
  top: 1px;
}

.testimonial-section .owl-nav {
  position: relative;
  display: block;
  text-align: center;
  margin-top: 60px;
}

.nav-style-one .owl-nav button {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 52px;
  line-height: 52px;
  background: #fff;
  font-size: 30px;
  color: #0c0c0c;
  text-align: center;
  border-radius: 50%;
  margin: 0px 10px;
  transition: all 500ms ease;
}

.nav-style-one .owl-nav button:hover {
  color: #fff;
}

.nav-style-one .owl-nav button.owl-prev {
  transform: rotate(-90deg);
}

.nav-style-one .owl-nav button.owl-next {
  transform: rotate(-90deg);
}

.testimonial-section .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: repeat-x;
  background-position: center;
}


/** news-section **/

.news-section {
  position: relative;
}

.news-block-one .inner-box {
  position: relative;
  display: block;
  padding: 0px 9px;
  z-index: 1;
}

.news-block-one .inner-box:before {
  position: absolute;
  content: '';
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
  width: 100%;
  height: calc(100% - 20px);
  border: 1px dashed #d8d2d0;
  border-radius: 25px;
  z-index: -1;
  left: 0px;
  bottom: 0px;
}

.news-block-one .inner-box .image-box {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 25px;
}

.news-block-one .inner-box .image-box .image {
  position: relative;
  display: block;
  text-align: center;
  overflow: hidden;
  border-radius: 25px;
}

.news-block-one .inner-box .image-box .image img {
  border-radius: 25px;
  transition: all 500ms ease;
}

.news-block-one .inner-box:hover .image-box .image img {
  transform: scale(1.05);
}

.news-block-one .inner-box .image-box .image-content {
  position: absolute;
  left: 10px;
  bottom: 10px;
  width: calc(100% - 20px);
  background: #fff;
  padding: 5px;
  border-radius: 30px;
  padding-right: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.news-block-one .inner-box .image-box .image-content .post-info {
  position: relative;
  display: flex;
  align-items: center;
}

.news-block-one .inner-box .image-box .image-content h6 {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  padding: 4px 15px;
  border-radius: 30px;
  margin-right: 10px;
  margin-bottom: 0px;
}

.news-block-one .inner-box .image-box .image-content .post-info span {
  position: relative;
  display: block;
  padding-left: 21px;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Outfit', sans-serif;
  color: #7b7675;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 10px;
}

.news-block-one .inner-box .image-box .image-content .post-info span:last-child {
  margin: 0px !important;
}

.news-block-one .inner-box .image-box .image-content .post-info span i {
  position: absolute;
  left: -10px;
  top: -4px;
  font-size: 30px;
  font-weight: 400;
}

.news-block-one .inner-box .image-box .image-content .post-info span a {
  display: inline-block;
  color: #7b7675;
}

.news-block-one .inner-box .image-box .image-content .post-info span a:hover {}

.news-block-one .inner-box .image-box .image-content .share-box {
  position: absolute;
  top: 5px;
  right: 5px;
}

.news-block-one .inner-box .image-box .image-content .share-box .share-icon {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 36px;
  font-size: 14px;
  background: #faf1ef;
  color: #0c0c0c;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  transition: all 500ms ease;
}

.news-block-one .inner-box .image-box .image-content .share-box:hover .share-icon {
  color: #fff;
}

.news-block-one .inner-box .image-box .image-content .share-box .share-icon:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  transform: scale(0, 0);
  border-radius: 50%;
  z-index: -1;
  transition: all 500ms ease;
}

.news-block-one .inner-box .image-box .image-content .share-box:hover .share-icon:before {
  transform: scale(1, 1);
}

.news-block-one .inner-box .image-box .image-content .share-box .social-links {
  position: absolute;
  top: -44px;
  right: 0px;
  width: 130px;
  background: #fff;
  border-radius: 5px;
  padding: 9px 12px 7px 12px;
  visibility: hidden;
  opacity: 0;
  transition: all 500ms ease;
}

.news-block-one .inner-box .image-box .image-content .share-box:hover .social-links {
  visibility: visible;
  opacity: 1;
  top: -64px;
}

.news-block-one .inner-box .image-box .image-content .share-box .social-links:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 15px;
  height: 7px;
  right: 11px;
  bottom: -7px;
  clip-path: polygon(0% 0%, 100% 0%, 50% 100%, 50% 100%, 0% 0%);
}

.news-block-one .inner-box .image-box .image-content .share-box .social-links:after {
  position: absolute;
  content: '';
  background: transparent;
  width: 100%;
  height: 20px;
  right: 0px;
  bottom: -20px;
}

.news-block-one .inner-box .image-box .image-content .share-box .social-links li {
  position: relative;
  display: inline-block;
  float: left;
  margin-right: 21px;
}

.news-block-one .inner-box .image-box .image-content .share-box .social-links li:last-child {
  margin: 0px !important;
}

.news-block-one .inner-box .image-box .image-content .share-box .social-links li:before {
  position: absolute;
  content: '';
  background: #c9c1c1;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  top: 12px;
  right: -13px;
}

.news-block-one .inner-box .image-box .image-content .share-box .social-links li:last-child:before {
  display: none;
}

.news-block-one .inner-box .image-box .image-content .share-box .social-links li a {
  position: relative;
  display: inline-block;
  font-size: 20px;
  color: #afa7a7;
}

.news-block-one .inner-box .image-box .image-content .share-box .social-links li a:hover {}

.news-block-one .inner-box .lower-content {
  position: relative;
  display: block;
  padding: 23px 20px 0px 20px;
}

.news-block-one .inner-box .lower-content h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 22px;
}

.news-block-one .inner-box .lower-content h3 a {
  display: inline-block;
  color: #0c0c0c;
  height: 7rem;
}

.news-block-one .inner-box .lower-content h3 a:hover {}

.news-block-one .inner-box .lower-content .link-box {
  position: relative;
  display: block;
  padding: 16px 0px 18px 0px;
  border-top: 1px dashed #d8d2d0;
}

.news-block-one .inner-box .lower-content .link-box a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Outfit', sans-serif;
  font-weight: 800;
  color: #0c0c0c;
  text-transform: uppercase;
  padding-left: 30px;
}

.news-block-one .inner-box .lower-content .link-box a i {
  position: absolute;
  left: 0px;
  top: -1px;
  font-size: 24px;
  font-weight: 400;
}

.news-block-one .inner-box .lower-content .link-box a:hover {}


/** faq-section **/

.faq-section {
  position: relative;
}

.faq-section .accordion-inner {
  position: relative;
  display: block;
}

.accordion-box .block {
  position: relative;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.18);
}

.accordion-box .block .acc-content {
  position: relative;
  display: none;
  padding-bottom: 22px;
}

.accordion-box .block .acc-content.current {
  display: block;
}

.faq-section .accordion-inner {
  position: relative;
  display: block;
  padding: 24px 50px 45px 50px;
  border: 1px dashed rgba(0, 0, 0, 0.18);
  border-radius: 25px;
}

.accordion-box .block .acc-btn {
  position: relative;
  display: block;
  padding: 19px 50px 17px 0px;
  cursor: pointer;
}

.accordion-box .block .acc-btn h5 {
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

.accordion-box .block .acc-btn .icon-box {
  position: absolute;
  top: 20px;
  right: 0px;
  color: #7d7877;
}

.accordion-box .block .acc-btn .icon-box:before {
  position: absolute;
  content: "\f11e";
  font-family: 'flaticon';
  right: 0px;
  top: -2px;
  font-size: 20px;
  font-weight: 400;
}

.accordion-box .block .acc-btn.active .icon-box:before {
  content: "\f11d";
  font-size: 14px;
  top: 0px;
}

.faq-section .accordion-inner .link-box a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Outfit', sans-serif;
  font-weight: 800;
  color: #0c0c0c;
  text-transform: uppercase;
  padding-left: 30px;
}

.faq-section .accordion-inner .link-box a i {
  position: absolute;
  left: 0px;
  top: -1px;
  font-size: 24px;
  font-weight: 400;
}

.faq-section .accordion-inner .link-box a:hover {}

.faq-section .accordion-inner .link-box {
  margin-top: 31px;
}

.nice-select {
  position: relative;
  display: block;
  width: 100%;
}

.nice-select:before {
  position: absolute;
  content: "\f104";
  font-family: 'flaticon';
  font-size: 16px;
  top: 1px;
  right: 0px;
}

.faq-section .form-inner {
  position: relative;
  display: block;
  background: #fff;
  padding: 50px 50px 25px 50px;
  border-radius: 25px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06);
}

.faq-section .form-inner .form-group {
  position: relative;
  display: block;
  margin-bottom: 26px;
  padding-left: 150px;
}

.faq-section .form-inner .form-group:last-child {
  margin-bottom: 0px;
}

.faq-section .form-inner .form-group label {
  position: absolute;
  left: 0px;
  top: 0px;
  display: block;
  font-size: 16px;
  line-height: 42px;
  font-family: 'Outfit', sans-serif;
  color: #0c0c0c;
  font-weight: 700;
}

.faq-section .form-inner .form-group label span {
  position: relative;
  display: inline-block;
  width: 108px;
}

.faq-section .form-inner .form-group input[type='text'],
.faq-section .form-inner .form-group input[type='number'],
.faq-section .form-inner .form-group input[type='email'],
.faq-section .form-inner .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 42px;
  font-size: 17px;
  color: #c9c1c1;
  border-bottom: 1px dashed #d8d2d0;
  transition: all 500ms ease;
}

.faq-section .form-inner .form-group input:focus,
.faq-section .form-inner .form-group textarea:focus {
  color: #7b7675;
}

.faq-section .form-inner .form-group .select-box {
  position: relative;
  display: block;
  min-height: 42px;
}

.faq-section .form-inner .form-group .nice-select {
  position: relative;
  display: block;
  font-size: 17px;
  color: #c9c1c1;
  border-bottom: 1px dashed #d8d2d0;
  padding: 0px;
  line-height: 42px;
}

.faq-section .form-inner .form-group textarea {
  resize: none;
}

.faq-section .form-inner .form-group.message-btn {
  padding-left: 0px;
}

.faq-section .form-inner .form-group .theme-btn {
  width: 100%;
  margin-top: 2px;
}


/** innovation-section **/

.innovation-section {
  position: relative;
  display: block;
  padding: 0px 97px;
}

.innovation-section:before {
  position: absolute;
  content: '';
  background: -webkit-linear-gradient(0deg, #faeddc, #eff9ec 100%);
  width: 100%;
  height: 50%;
  left: 0px;
  top: 0px;
}

.innovation-section .inner-container {
  position: relative;
  background: -webkit-linear-gradient(0deg, #fd6146, #fb991a 100%);
  padding: 115px 0px 115px 0px;
  border-radius: 25px;
  overflow: hidden;
}

.innovation-section .sec-title h6 {
  color: #fff;
}

.innovation-section .sec-title {
  margin-bottom: 0px;
  padding-right: 30px;
}

.innovation-section .sec-title:before {
  position: absolute;
  content: '';
  border-right: 1px dashed rgba(255, 255, 255, 0.50);
  width: 1px;
  height: calc(100% + 44px);
  top: -20px;
  right: 20px;
}

.innovation-section .content-box {
  position: relative;
  display: block;
}

.innovation-section .content-box .single-item {
  position: relative;
  display: block;
  margin-bottom: 35px;
  padding-left: 80px;
}

.innovation-section .content-box .single-item:last-child {
  margin-bottom: 0px;
}

.innovation-section .content-box .single-item h3 {
  display: block;
  font-size: 24px;
  line-height: 34px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 3px;
}

.innovation-section .content-box .single-item p {
  position: relative;
  display: block;
  color: #fff;
}

.innovation-section .content-box .single-item .icon-box {
  position: absolute;
  left: 0px;
  top: 2px;
  width: 60px;
  height: 60px;
  line-height: 68px;
  background: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 30px;
}

.innovation-section .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.innovation-section .image-layer .image-1 {
  position: absolute;
  left: 70px;
  top: 0px;
}

.innovation-section .image-layer .image-2 {
  position: absolute;
  left: 0px;
  bottom: 0px;
}

.innovation-section .image-layer .image-3 {
  position: absolute;
  left: 615px;
  top: 70px;
}

.innovation-section .image-layer .image-4 {
  position: absolute;
  right: 0px;
  bottom: 85px;
}

.innovation-section .image-layer .image-5 {
  position: absolute;
  right: 295px;
  bottom: 22px;
}

.float-bob-y {
  animation-name: float-bob-y;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float-bob-y;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: float-bob-y;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob-y;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob-y;
  -o-animation-duration: 2s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

.float-bob-x {
  animation-name: float-bob-x;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float-bob-x;
  -webkit-animation-duration: 15s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: float-bob-x;
  -moz-animation-duration: 15s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float-bob-x;
  -ms-animation-duration: 15s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float-bob-x;
  -o-animation-duration: 15s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}


/** clients-section **/

.clients-section {
  position: relative;
  padding: 114px 120px 120px 120px;
}

.clients-section .inner-container {
  position: relative;
}

.clients-section .inner-container .owl-stage-outer {
  position: relative;
  border-right: 1px dashed #d8d2d0;
  border-left: 1px dashed #d8d2d0;
}

.clients-section .clients-logo {
  position: relative;
  display: block;
}

.clients-section .clients-logo img {
  position: relative;
  display: block;
  width: auto;
  max-width: 100%;
  margin: 0 auto;
  transition: all 500ms ease;
}

.clients-section .clients-logo img:hover {
  transform: scale(1.02);
}

.clients-section .clients-logo:before {
  position: absolute;
  content: '';
  border-right: 1px dashed #d8d2d0;
  width: 1px;
  height: 100%;
  top: 0px;
  right: -15px;
}

.clients-section .title-box {
  position: relative;
  display: block;
  margin-bottom: 47px;
}

.clients-section .title-box h3 {
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 8px;
}

.clients-section .owl-nav {
  position: relative;
  display: block;
  margin-top: 47px;
}

.clients-section .nav-style-one .owl-nav button {
  width: auto;
  height: auto;
  line-height: 26px;
  background: transparent;
  margin: 0px 5px;
}

.clients-section .nav-style-one .owl-nav button:hover {}


/** main-footer **/

.main-footer {
  position: relative;
  background: #0c0c0c;
}

.main-footer .footer-top {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 0px 10px 0px;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.15);
}

.main-footer .footer-logo {
  position: relative;
  display: block;
  margin-bottom: 22px;
}

.main-footer .copyright p {
  color: #aca7a7;
}

.main-footer .copyright p a {
  display: inline-block;
}

.main-footer .copyright p a:hover {
  color: #fff;
}

.main-footer .social-links li {
  position: relative;
  display: inline-block;
  float: left;
  margin-right: 10px;
}

.main-footer .social-links li:last-child {
  margin: 0px !important;
}

.main-footer .social-links li a {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px dashed rgba(255, 255, 255, 0.15);
  border-radius: 50%;
  font-size: 18px;
  color: #acafaf;
  text-align: center;
  z-index: 1;
}

.main-footer .social-links li a:hover {
  color: #fff;
  border-color: transparent;
}

.main-footer .social-links li a:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  transform: scale(0, 0);
  z-index: -1;
  transition: all 500ms ease;
}

.main-footer .social-links li a:hover:before {
  transform: scale(1, 1);
}

.main-footer .social-links li h5 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 40px;
  color: #fff;
  font-weight: 600;
  padding-right: 10px;
}

.main-footer .social-links {
  margin-bottom: 22px;
}

.main-footer .footer-nav li {
  position: relative;
  display: inline-block;
  float: left;
  margin-right: 23px;
}

.main-footer .footer-nav li:last-child {
  margin: 0px;
}

.main-footer .footer-nav li:before {
  position: absolute;
  content: '';
  background: #606060;
  width: 2px;
  height: 2px;
  right: -14px;
  bottom: 9px;
}

.main-footer .footer-nav li:last-child:before {
  display: none;
}

.main-footer .footer-nav li a {
  position: relative;
  display: inline-block;
  color: #aca7a7;
}

.main-footer .footer-nav li a:hover {}

.main-footer .widget-section {
  position: relative;
  padding: 60px 0px 40px 0px;
}

.main-footer .newsletter-widget .icon-box {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 54px;
  line-height: 62px;
  font-size: 30px;
  color: #fff;
  text-align: center;
  border-radius: 15px;
  margin-bottom: 23px;
}

.main-footer .widget-title {
  position: relative;
  display: block;
  margin-bottom: 25px;
}

.main-footer .widget-title h3 {
  font-size: 24px;
  line-height: 34px;
  color: #fff;
  font-weight: 700;
}

.main-footer .widget-section p,
.main-footer .widget-section a {
  color: #aca7a7;
}

.main-footer .newsletter-widget p {
  margin-bottom: 22px;
}

.main-footer .newsletter-widget .subscribe-form .form-group {
  position: relative;
  margin-bottom: 12px;
}

.main-footer .newsletter-widget .subscribe-form .form-group input[type='email'] {
  position: relative;
  display: block;
  width: 100%;
  height: 40px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 40px;
  font-size: 17px;
  color: #aca7a7;
  padding: 10px 0px 10px 20px;
}

.main-footer .newsletter-widget .subscribe-form .form-group button[type='submit'] {
  position: absolute;
  display: inline-block;
  width: 46px;
  height: 46px;
  line-height: 52px;
  top: 4px;
  right: 4px;
  font-size: 30px;
  color: #fff;
  background: #0c0c0c;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  transition: all 500ms ease;
}

.main-footer .newsletter-widget {
  margin-top: 8px;
}

.main-footer .newsletter-widget .subscribe-form .form-group input:focus {}

.main-footer .newsletter-widget .subscribe-form .form-group input:focus+button,
.main-footer .newsletter-widget .subscribe-form .form-group button:hover {}

.main-footer .newsletter-widget .text {
  display: block;
  color: #fff;
}

.main-footer .links-widget {
  position: relative;
  display: block;
  margin-left: 70px;
}

.main-footer .links-widget .links-list li {
  position: relative;
  display: block;
  margin-bottom: 12px;
}

.main-footer .links-widget .links-list li:last-child {
  margin-bottom: 0px;
}

.main-footer .links-widget .links-list li a {
  position: relative;
  display: inline-block;
  padding-left: 30px;
}

.main-footer .links-widget .links-list li a:hover {}

.main-footer .links-widget .links-list li a:before {
  position: absolute;
  content: "\f00c";
  font-family: 'Font Awesome 6 Free';
  font-weight: 700;
  font-size: 10px;
  color: #0c0c0c;
  background: #606060;
  width: 14px;
  height: 14px;
  line-height: 14px;
  text-align: center;
  border-radius: 2px;
  left: 0px;
  top: 8px;
  transition: all 500ms ease;
}

.main-footer .links-widget .links-list li a:hover:before {}

.main-footer .links-widget .widget-title {
  margin-bottom: 23px !important;
}

.main-footer .promotion-widget .inner-box {
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  padding: 33px 30px 40px 30px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 8px;
}

.main-footer .promotion-widget .inner-box:before {
  position: absolute;
  content: '';
  background: #0c0c0c;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.4;
}

.main-footer .promotion-widget .inner-box .shape {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right bottom;
}

.main-footer .promotion-widget .inner-box h6 {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.main-footer .promotion-widget .inner-box h2 {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 40px;
  color: #fff;
  font-weight: 800;
  margin-bottom: 20px;
}

.main-footer .promotion-widget .inner-box .theme-btn {
  background: #0c0c0c;
  padding: 11px 30px;
}

.main-footer .promotion-widget .inner-box .theme-btn:hover {}

.main-footer .promotion-widget .inner-box .icon-box {
  position: absolute;
  right: 20px;
  bottom: 20px;
}



/*** 

====================================================================
                        Home-Page-two
====================================================================

***/

/** header-style-two **/

.header-top-two {
  position: relative;
  width: 100%;
  padding: 8.5px 0px;
}

.header-top-two .outer-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 40px;
}

.header-top-two .left-column {
  position: relative;
  padding-left: 32px;
}

.header-top-two .left-column .icon-box {
  position: absolute;
  left: 0px;
  top: 4px;
  font-size: 22px;
  color: #fff;
}

.header-top-two .left-column p {
  color: #ffffff;
}

.header-top-two .left-column p a {
  position: relative;
  display: inline-block;
  color: #fff;
  padding-left: 30px;
  margin-left: 7px;
}

.header-top-two .left-column p a i {
  position: absolute;
  left: 0px;
  top: 3px;
  font-size: 24px;
}

.header-top-two .left-column p a:hover {
  text-decoration: underline;
}

.header-top-two .right-column {
  position: relative;
  display: block;
  padding-left: 25px;
}

.header-top-two .right-column .icon-box {
  position: absolute;
  left: 0px;
  top: -1px;
}

.header-top-two .right-column .select-box {
  position: relative;
  min-height: 28px;
}

.header-top-two .right-column .nice-select {
  font-size: 17px;
  color: #ffffff;
  line-height: 28px;
  font-weight: 400;
  padding: 0px;
  padding-right: 28px;
}

.header-top-two .right-column .nice-select .list li {
  color: #444;
}

.header-top-two .right-column .nice-select .list {
  min-width: 210px;
  left: inherit;
  right: 0px;
}

.header-style-two .header-lower {
  position: relative;
  width: 100%;
}

.header-style-two .header-lower .outer-container {
  position: absolute;
  left: 0px;
  top: 60px;
  width: 100%;
}

.header-style-two .header-lower .logo-box {
  position: absolute;
  left: 40px;
  top: -54px;
  margin-right: 0px;
}

.header-style-two .header-lower .support-box {
  position: absolute;
  top: -47px;
  right: 40px;
  text-align: right;
  padding-right: 60px;
}

.header-style-two .header-lower .support-box .icon-box {
  position: absolute;
  top: 7px;
  right: 0px;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 44px;
  font-size: 20px;
  text-align: center;
  border-radius: 50%;
}

.header-style-two .header-lower .support-box h4 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}

.header-style-two .header-lower .support-box h4 a {
  display: inline-block;
}

.header-style-two .header-lower .support-box h4 a:hover {
  text-decoration: underline;
}

.header-style-two .header-lower .support-box h6 {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
  text-transform: uppercase;
}

.header-style-two .header-lower .main-menu .navigation>li>a {
  color: #fff;
}

.header-style-two .header-lower .outer-box {
  padding: 0px;
}

.header-style-two .header-lower .menu-right-content {
  padding-bottom: 0px;
}

.header-style-two .main-menu .navigation>li>a {
  padding-top: 20px;
  padding-bottom: 20px;
}

.header-style-two .main-menu .navigation>li.dropdown>a:before {
  top: 19px;
}

.header-style-two .header-lower .menu-right-content .option-box li a {
  color: #fff;
}

.main-header .menu-right-content .search-inner {
  position: relative;
}

.main-header .menu-right-content .search-inner:before {
  position: absolute;
  content: '';
  border-left: 1px dashed rgba(255, 255, 255, 0.40);
  width: 1px;
  height: 36px;
  left: 0px;
  top: -6px;
}

.main-header .menu-right-content .search-inner .search-box-outer {
  position: relative;
  display: inline-block;
  font-size: 24px;
  color: #fff;
  cursor: pointer;
  transition: all 500ms ease;
}

.header-style-two .menu-right-content .btn-box .theme-btn {
  padding: 10px 30px;
}

.header-style-two .menu-right-content .btn-box {
  margin-right: 24px;
}

.header-style-two .menu-right-content .option-box {
  top: 4px;
}

/*** Search Popup ***/

.search-popup {
  position: fixed;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;
  overflow: auto;
  background: rgba(0, 0, 0, 0.80);
  -webkit-transform: translateY(101%);
  -ms-transform: translateY(101%);
  transform: translateY(101%);
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
}

.search-popup.popup-visible {
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  visibility: visible;
  opacity: 1;
}

.search-popup .popup-inner {
  width: 100%;
  background: #fff;
  height: 100%;
}

.search-popup .upper-box {
  position: relative;
  padding: 70px 70px;
  z-index: 1;
}

.search-popup .overlay-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: block;
}

.search-popup .close-search {
  position: relative;
  font-size: 22px;
  color: #141417;
  cursor: pointer;
  z-index: 5;
  top: 11px;
  transition: all 500ms ease;
}

.search-popup .close-search:hover {
  color: red;
}

.search-popup .search-form {
  position: relative;
  width: 100%;
  padding: 100px 0px 250px 0px;
}

.search-popup .search-form .form-group {
  position: relative;
  margin: 0px;
}

.search-popup .search-form fieldset input[type="search"] {
  position: relative;
  height: 90px;
  padding: 20px 0px;
  background: #ffffff;
  line-height: 30px;
  font-size: 20px;
  color: #808080;
  border: none;
  font-weight: 400;
  border-radius: 0px;
  padding-right: 50px;
  border-bottom: 1px solid #e5e5e5;
}

.search-popup .search-form fieldset button[type="submit"] {
  position: absolute;
  top: 35px;
  right: 0px;
  font-size: 22px;
  color: #141417;
  cursor: pointer;
  transition: all 500ms ease;
}

.search-popup .search-form fieldset input[type="search"]:focus {
  border-color: #141417;
}

.search-popup .form-control:focus {
  box-shadow: none !important;
}

.header-style-two .sticky-header .menu-right-content .search-inner .search-box-outer {
  color: #0c0c0c;
}

.header-style-two .sticky-header .menu-right-content .search-inner:before {
  border-color: rgba(0, 0, 0, 0.15);
}

.header-style-two .sticky-header .outer-box {
  padding: 0px;
}


/** banner-style-two **/

.banner-style-two {
  position: relative;
}

.banner-style-two .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 142px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  z-index: 2;
}

.banner-style-two .shape .shape-1 {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 101px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
  z-index: 2;
}

.banner-style-two .shape .shape-2 {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 50%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: left top;
  z-index: 1;
}

.banner-style-two .shape .shape-3 {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 50%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: bottom right;
  z-index: 1;
}

.banner-style-two .slide-item {
  position: relative;
  padding: 277px 0px 235px 0px;
}

.banner-style-two .banner-carousel .slide-item:before {
  position: absolute;
  content: '';
  background: #000;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.1;
  z-index: 1;
}

.banner-style-two .banner-carousel .slide-item.dark-bg:before {
  opacity: 0.5;
}

.banner-style-two .banner-carousel .slide-item .image-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 8000ms linear;
  -moz-transition: all 8000ms linear;
  -ms-transition: all 8000ms linear;
  -o-transition: all 8000ms linear;
  transition: all 8000ms linear;
}

.banner-style-two .banner-carousel .active .slide-item .image-layer {
  -webkit-transform: scale(1.25);
  -ms-transform: scale(1.25);
  transform: scale(1.25);
}

.banner-style-two .owl-nav {
  position: absolute;
  left: 50%;
  bottom: 40px;
  transform: translateX(-50%);
  z-index: 5;
}

.banner-carousel .content-box h6 {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
  font-weight: 800;
  text-transform: uppercase;
  padding-left: 28px;
  margin-bottom: 14px;
  opacity: 0;
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
  transform: translateY(-10px);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.banner-carousel .content-box h6:before {
  position: absolute;
  content: "\f112";
  font-family: 'flaticon';
  font-size: 18px;
  left: 0px;
  top: -1px;
}

.banner-carousel .active .content-box h6 {
  opacity: 1;
  -webkit-transition-delay: 1000ms;
  -moz-transition-delay: 1000ms;
  -ms-transition-delay: 1000ms;
  -o-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.banner-style-two .banner-carousel .content-box h2 {
  font-size: 72px;
  line-height: 80px;
  color: #fff;
  font-weight: 800;
  margin-bottom: 19px;
}

.banner-style-two .banner-carousel .content-box {
  max-width: 700px;
}

.banner-style-two .banner-carousel .content-box p {
  color: #fff;
}


/** categories-style-two **/

.categories-style-two {
  position: relative;
}

.categories-style-two .owl-carousel .owl-stage-outer {
  overflow: visible;
}

.categories-style-two .owl-carousel .owl-stage-outer .owl-item {
  opacity: 0;
  visibility: hidden;
}

.categories-style-two .owl-carousel .owl-stage-outer .owl-item.active {
  opacity: 1;
  visibility: visible;
}

.categories-block-two .inner-box {
  position: relative;
  display: block;
  overflow: hidden;
  background: #fff;
  border-radius: 25px;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.06);
  padding: 33px 30px 0px 30px;
}

.categories-block-two .inner-box h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 31px;
}

.categories-block-two .inner-box h3 a {
  display: inline-block;
  color: #0c0c0c;
}

.categories-block-two .inner-box h3 a:hover {}

.categories-block-two .inner-box .image-box {
  position: relative;
  display: block;
  border-radius: 25px;
  border: 1px dashed #d8d2d0;
  margin-bottom: 31px !important;
}

.categories-block-two .inner-box .image-box img {
  width: 100%;
  border-radius: 25px;
}

.categories-block-two .inner-box p {
  display: block;
  margin-bottom: 32px;
}

.categories-block-two .inner-box .link-btn {
  position: relative;
  display: block;
  padding: 17px 0px 18px 0px;
}

.categories-block-two .inner-box .link-btn:before {
  position: absolute;
  content: '';
  border-top: 1px dashed #d8d2d0;
  left: -30px;
  top: 0px;
  width: calc(100% + 60px);
  height: 1px;
}

.categories-block-two .inner-box .link-btn a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Outfit', sans-serif;
  font-weight: 800;
  color: #0c0c0c;
  text-transform: uppercase;
  padding-left: 30px;
}

.categories-block-two .inner-box .link-btn a i {
  position: absolute;
  left: 0px;
  top: -1px;
  font-size: 24px;
  font-weight: 400;
}

.categories-block-two .inner-box .link-btn a:hover {}

.categories-style-two .owl-nav {
  position: absolute;
  left: 0px;
  top: 50%;
  width: 100%;
}

.categories-style-two .owl-nav button {
  width: 55px;
  height: 55px;
  line-height: 55px;
  border: 1px dashed #d1c8c4;
  background: transparent;
  margin: 0px;
}

.categories-style-two .owl-nav button.owl-prev {
  position: absolute;
  left: -87px;
  top: -27px;
}

.categories-style-two .owl-nav button.owl-next {
  position: absolute;
  right: -87px;
  top: -27px;
}


/** working-section **/

.working-section {
  position: relative;
  padding: 115px 0px 112px 0px;
}

.working-section .sec-title .btn-box a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 46px;
  font-family: 'Outfit', sans-serif;
  color: #0c0c0c;
  font-weight: 800;
  text-transform: uppercase;
  padding-left: 60px;
}

.working-section .sec-title .btn-box a i {
  position: absolute;
  display: inline-block;
  left: 0px;
  top: 0px;
  width: 46px;
  height: 46px;
  line-height: 56px;
  text-align: center;
  font-size: 30px;
  background: #faf1ef;
  text-align: center;
  border-radius: 50%;
}

.working-block-one .inner-box {
  position: relative;
  display: block;
  text-align: center;
}

.working-block-one .inner-box .icon-box {
  position: relative;
  display: block;
  margin-bottom: 44px;
}

.working-block-one .inner-box .icon-box .icon {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 100px;
  line-height: 110px;
  font-size: 36px;
  text-align: center;
  z-index: 1;
}

.working-block:nth-child(2) .working-block-one .inner-box .icon-box .icon {
  color: #fb991a;
}

.working-block:nth-child(3) .working-block-one .inner-box .icon-box .icon {
  color: #8B8B8B;
}

.working-block:last-child .working-block-one .inner-box .icon-box .icon {
  color: #fd6145;
}

.working-block-one .inner-box .icon-box .icon:before {
  position: absolute;
  content: '';
  background: #faf1ef;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 7px;
  transform: skewX(-10deg);
  z-index: -1;
}

.working-block:nth-child(2) .working-block-one .inner-box .icon-box .icon:before {
  background: #faeddc;
}

.working-block:nth-child(3) .working-block-one .inner-box .icon-box .icon:before {
  background: #eef9ec;
}

.working-block:last-child .working-block-one .inner-box .icon-box .icon:before {
  background: #faf1ef;
}

.working-block-one .inner-box .icon-box .icon:after {
  position: absolute;
  content: '';
  border: 1px dashed #d8d2d0;
  width: 50px;
  height: 70px;
  right: -7px;
  bottom: -10px;
  border-radius: 5px;
  transform: skewX(-10deg);
  z-index: -2;
}

.working-block-one .inner-box .icon-box h5 {
  position: absolute;
  display: inline-block;
  right: 18px;
  bottom: -10px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 1px dashed #d8d2d0;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 800;
}

.working-block:nth-child(even) .working-block-one .inner-box .icon-box h5 {
  bottom: inherit;
  top: 0px;
}

.working-block-one .inner-box h3 {
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 13px;
}


/** service-style-two **/

.service-style-two {
  position: relative;
}

.service-style-two .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: repeat-x;
  background-position: center;
}

.service-block-two {
  max-width: 370px;
  margin: 0 auto;
}

.service-block-two .inner-box {
  position: relative;
  display: block;
  overflow: hidden;
  border: 1px dashed #4f4f4f;
  border-radius: 25px;
  padding: 29px 29px 0px 29px;
  margin-bottom: 60px;
}

.service-block-two .inner-box .image-box {
  position: relative;
  display: block;
}

.service-block-two .inner-box .image-box img {
  width: 100%;
}

.service-block-two .inner-box .lower-content {
  position: relative;
  padding-top: 33px;
}

.service-block-two .inner-box .lower-content h6 {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.service-block-two .inner-box .lower-content h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 24px;
  font-weight: 700;
  margin-bottom: 14px;
}

.service-block-two .inner-box .lower-content h3 a {
  display: inline-block;
  color: #fff;
}

.service-block-two .inner-box .lower-content h3 a:hover {}

.service-block-two .inner-box .lower-content p {
  color: #aca7a7;
  margin-bottom: 32px;
}

.service-block-two .inner-box .link-btn {
  position: relative;
  display: block;
  padding: 17px 0px;
}

.service-block-two .inner-box .link-btn:before {
  position: absolute;
  content: '';
  border-top: 1px dashed #4f4f4f;
  width: calc(100% + 60px);
  height: 1px;
  left: -30px;
  top: 0px;
}

.service-block-two .inner-box .link-btn a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Outfit', sans-serif;
  font-weight: 800;
  color: #fff;
  text-transform: uppercase;
  padding-left: 30px;
}

.service-block-two .inner-box .link-btn a i {
  position: absolute;
  left: 0px;
  top: -1px;
  font-size: 24px;
  font-weight: 400;
}

.service-block-two .inner-box .link-btn a:hover {}


/** weare-section **/

.weare-section {
  position: relative;
  padding: 90px 0px 120px 0px;
}

.weare-section .image-box {
  position: relative;
  display: block;
  margin-left: -130px;
  padding-right: 72px;
  padding-bottom: 48px;
  margin-right: 20px;
}

.weare-section .image-box .image {
  position: relative;
  display: block;
}

.weare-section .image-box .image img {
  width: 100%;
  margin-left: 6.5pc;
}

.weare-section .image-box .text-box {
  position: absolute;
  right: 0px;
  bottom: 40px;
  width: 250px;
  background: #faeddc;
  border-radius: 25px;
  padding: 27px 40px 25px 40px;
  text-align: center;
}

.weare-section .image-box .text-box h2 {
  display: block;
  font-size: 48px;
  line-height: 50px;
  font-weight: 700;
}

.weare-section .image-box .text-box h2 span {
  position: relative;
  font-size: 28px;
  top: -16px;
}

.weare-section .image-box .image-shape .shape-1 {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 566px;
  height: 549px;
  background-repeat: no-repeat;
}

.weare-section .image-box .image-shape .shape-2 {
  position: absolute;
  top: 67px;
  right: 137px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: -webkit-linear-gradient(-90deg, #fdcf93, #b5e8ae 100%);
}

.weare-section .image-box .image-shape .shape-3 {
  position: absolute;
  bottom: -25px;
  right: 380px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: -webkit-linear-gradient(-90deg, #fb981b, #64cf59 100%);
  opacity: 0.5;
}

.weare-section .content-box {
  position: relative;
  display: block;
}

.weare-section .content-box .sec-title {
  margin-bottom: 38px;
}

.weare-section .content-box h4 {
  position: relative;
  display: inline-block;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  padding: 10px 30px;
  border: 1px dashed #fd6145;
  border-radius: 15px;
  margin-bottom: 32px;
}

.weare-section .content-box h4 span {}

.weare-section .content-box p {
  display: block;
  margin-bottom: 29px;
}

.weare-section .content-box .list-item li {
  position: relative;
  display: block;
  float: left;
  font-size: 17px;
  color: #0c0c0c;
  padding-left: 42px;
  margin-bottom: 12px;
  font-weight: 500;
}

.weare-section .content-box .list-item li:before {
  position: absolute;
  content: "\f126";
  font-family: 'flaticon';
  font-size: 24px;
  left: 0px;
  top: 0px;
}

.weare-section .content-box .list-item {
  padding-bottom: 30px;
}

.weare-section .content-box h3 {
  position: relative;
  display: block;
  font-size: 22px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 11px;
}

.weare-section .content-box h3 span {
  font-size: 17px;
  font-family: 'Outfit', sans-serif;
  color: #7b7675;
  font-weight: 400;
}


/** team-section **/

.team-section {
  position: relative;
  background: -webkit-linear-gradient(0deg, #eff9ec, #faf1ef 100%);
}

.team-block-one .inner-box {
  position: relative;
  display: block;
  margin-bottom: 52px;
}

.team-block-one .inner-box .image-box {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 25px;
}

.team-block-one .inner-box .image-box img {
  width: 100%;
  border-radius: 25px;
  transition: all 500ms ease;
}

.team-block-one .inner-box:hover .image-box img {
  transform: scale(1.05);
}

.team-block-one .inner-box .share-box {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 1;
}

.team-block-one .inner-box .share-box .share-icon {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 56px;
  font-size: 24px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  transition: all 500ms ease;
}

.team-block-one .inner-box .share-box .share-icon:before {
  position: absolute;
  content: '';
  background: -webkit-linear-gradient(0deg, rgba(253, 97, 69, 0.85), rgba(245, 119, 65, 0.85) 100%);
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: -1;
}

.team-block-one .inner-box .share-box .share-icon:after {
  position: absolute;
  content: '';
  background: -webkit-linear-gradient(0deg, #fd6146, #f6951a 100%);
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  z-index: -1;
  transform: scale(0, 0);
  transition: all 500ms ease;
}

.team-block-one .inner-box .share-box:hover .share-icon:after {
  transform: scale(1, 1);
}

.team-block-one .inner-box .share-box .social-links {
  position: absolute;
  right: 0px;
  bottom: 60px;
  transform-origin: bottom center;
  transform: scaleY(0);
  visibility: hidden;
  transition: all 500ms ease;
}

.team-block-one .inner-box .share-box:hover .social-links {
  visibility: visible;
  transform: scaleY(1);
}

.team-block-one .inner-box .share-box .social-links:before {
  position: absolute;
  content: '';
  background: transparent;
  width: 100%;
  height: 10px;
  left: 0px;
  bottom: -10px;
}

.team-block-one .inner-box .share-box .social-links li {
  position: relative;
  display: block;
  margin-bottom: 10px;
}

.team-block-one .inner-box .share-box .social-links li:last-child {
  margin-bottom: 0px;
}

.team-block-one .inner-box .share-box .social-links li a {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 46px;
  line-height: 46px;
  font-size: 18px;
  color: #aba6a6;
  background: #fff;
  text-align: center;
  border-radius: 50%;
}

.team-block-one .inner-box .share-box .social-links li a:hover {}

.team-block-one .inner-box .lower-content {
  position: relative;
  display: block;
  padding-top: 22px;
}

.team-block-one .inner-box .lower-content h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 5px;
}

.team-block-one .inner-box .lower-content h3 a {
  display: inline-block;
  color: #131215;
}

.team-block-one .inner-box .lower-content h3 a:hover {}

.team-block-one .inner-box .lower-content h6 {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
  text-transform: uppercase;
}


/** project-style-two **/

.project-style-two {
  position: relative;
  padding-top: 115px;
}

.project-block-two .inner-box {
  position: relative;
  display: block;
  border-radius: 25px;
  overflow: hidden;
}

.project-block-two .inner-box .image-box {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 25px;
}

.project-block-two .inner-box .image-box img {
  width: 100%;
  border-radius: 25px;
  transition: all 500ms ease;
}

.project-block-two .inner-box:hover .image-box img {
  transform: scale(1.05);
}

.project-block-two .inner-box .link-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}

.project-block-two .inner-box .link-btn a {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 58px;
  background: #fff;
  text-align: center;
  font-size: 30px;
  color: #0c0c0c;
  border-radius: 50%;
  font-weight: 400;
  transform: scale(0, 0);
}

.project-block-two .inner-box .link-btn a:hover {
  color: #fff;
}

.project-block-two .inner-box:hover .link-btn a {
  transform: scale(1);
}

.project-block-two .inner-box .view-btn {
  position: absolute;
  right: 80px;
  top: 20px;
}

.project-block-two .inner-box .view-btn a {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 54px;
  background: #fff;
  text-align: center;
  font-size: 16px;
  color: #0c0c0c;
  border-radius: 50%;
  font-weight: 400;
  transform: scale(0, 0);
}

.project-block-two .inner-box .view-btn a:hover {
  color: #fff;
}

.project-block-two .inner-box:hover .view-btn a {
  transform: scale(1);
}

.project-block-two .inner-box .text-box {
  position: absolute;
  left: 20px;
  bottom: 0px;
  width: 285px;
  background: #fff;
  border-radius: 15px;
  padding: 25px 20px 21px 30px;
  opacity: 0;
  transition: all 500ms ease;
}

.project-block-two .inner-box:hover .text-box {
  bottom: 20px;
  opacity: 1;
}

.project-block-two .inner-box .text-box h6 {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.project-block-two .inner-box .text-box h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
}

.project-block-two .inner-box .text-box h3 a {
  position: relative;
  display: inline-block;
  color: #0c0c0c;
}

.project-block-two .inner-box .text-box h3 a:hover {}

.project-style-two .owl-carousel .owl-stage-outer {
  overflow: visible;
}

.project-style-two .owl-nav {
  position: absolute;
  top: -112px;
  right: -10px;
}

.project-style-two .owl-nav button {
  background: transparent;
  border: 1px dashed #e0dad9;
}


/** testimonial-style-two **/

.testimonial-style-two {
  position: relative;
  background: -webkit-linear-gradient(0deg, #faeddc, #eef9ec 100%);
}

.testimonial-block-two .inner-box {
  position: relative;
  display: block;
  background: #0c0c0c;
  border-radius: 25px;
  padding: 93px 190px 94px 390px;
}

.testimonial-block-two .inner-box h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  color: #fff;
  font-weight: 700;
}

.testimonial-block-two .inner-box h3.title-text {
  margin-bottom: 17px;
  padding-left: 45px;
}

.testimonial-block-two .inner-box h3.title-text:before {
  position: absolute;
  content: "\f11a";
  font-family: 'flaticon';
  font-size: 105px;
  left: -39px;
  top: 0px;
  font-weight: 400;
}

.testimonial-block-two .inner-box p {
  display: block;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 27px;
  color: #fff;
}

.testimonial-block-two .inner-box .author-box {
  position: relative;
  display: block;
}

.testimonial-block-two .inner-box .author-box .designation {
  position: relative;
  display: block;
  font-size: 17px;
  margin-top: 3px;
}

.testimonial-block-two .inner-box .author-box .rating-box {
  position: absolute;
  right: 20px;
  bottom: 6px;
  min-width: 97px;
  padding: 0 15px;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-weight: 700;
  border: 1px solid #fb991a;
  border-radius: 50%;
  text-align: center;
}

.testimonial-block-two .inner-box .author-box .rating-box i {
  position: relative;
  font-size: 16px;
  color: #fb991a;
  margin-right: 5px;
  top: 1px;
}

.testimonial-block-two .inner-box .image-box {
  position: absolute;
  left: 70px;
  top: 110px;
}

.testimonial-block-two .inner-box .image-box .thumb-box {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.testimonial-block-two .inner-box .image-box .thumb-box img {
  width: 100%;
  border-radius: 50%;
}

.testimonial-block-two .inner-box .image-box .thumb-box:before {
  position: absolute;
  content: '';
  background: -webkit-linear-gradient(-90deg, #fb991a, #fd6145 100%);
  width: 100%;
  height: calc(100% + 20px);
  left: 0px;
  top: -10px;
  border-radius: 50%;
}

.testimonial-block-two .inner-box .image-box .products-box {
  position: absolute;
  top: -5px;
  right: -77px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.testimonial-block-two .inner-box .image-box .products-box img {
  width: 100%;
  border-radius: 50%;
}

.testimonial-block-two .inner-box .image-box .products-box:before {
  position: absolute;
  content: '';
  border: 1px dashed #fff;
  left: -5px;
  top: -5px;
  width: 110px;
  height: 110px;
  border-radius: 50%;
}

.testimonial-style-two .owl-nav {
  position: absolute;
  top: 50%;
  right: 24px;
  transform: rotate(90deg);
  margin-top: -28px;
}

.testimonial-style-two .owl-nav button {
  background: transparent;
  border: 1px dashed #464646;
  color: #fff;
}


/** shop-section **/

.shop-section {
  position: relative;
}

.shop-block-one .inner-box {
  position: relative;
  display: block;
  background: #fff;
  padding: 9px 9px 0px 9px;
  border: 1px dashed transparent;
  border-radius: 15px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
  transition: all 500ms ease;
}

.shop-block-one .inner-box:hover {
  border-color: #d8d2d0;
}

.shop-block-one .inner-box .image-box {
  position: relative;
}

.shop-block-one .inner-box .image-box .image {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 15px;
}

.shop-block-one .inner-box .image-box .image img {
  width: 100%;
  border-radius: 15px;
  transition: all 500ms ease;
}

.shop-block-one .inner-box:hover .image-box .image img {
  transform: scale(1.05);
}

.shop-block-one .inner-box .image-box .view-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.shop-block-one .inner-box .image-box .view-btn a {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 42px;
  line-height: 46px;
  background: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 16px;
  color: #0c0c0c;
  transform: scale(0, 0);
}

.shop-block-one .inner-box:hover .image-box .view-btn a {
  transform: scale(1, 1);
}

.shop-block-one .inner-box .image-box .view-btn a:hover {
  color: #fff;
}

.shop-block-one .inner-box .lower-content {
  position: relative;
  display: block;
  padding: 0px 10px;
}

.shop-block-one .inner-box .text-box {
  position: relative;
  display: block;
  padding: 12px 0px;
  border-bottom: 1px dashed #dcd5d4;
  text-align: center;
}

.shop-block-one .inner-box .text-box .category {
  position: relative;
  display: block;
  margin-bottom: 3px;
}

.shop-block-one .inner-box .text-box h4 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}

.shop-block-one .inner-box .text-box h4 a {
  display: inline-block;
  color: #0c0c0c;
}

.shop-block-one .inner-box .text-box h4 a:hover {}

.shop-block-one .inner-box .text-box .rating-box li {
  position: relative;
  display: inline-block;
  font-size: 12px;
  color: #fb991a;
  margin: 0px 1px;
}

.shop-block-one .inner-box .lower-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
  transition: all 500ms ease;
}

.shop-block-one .inner-box:hover .lower-box {
  opacity: 0;
}

.shop-block-one .inner-box .price-box h5 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

.shop-block-one .inner-box .price-box h5 del {
  font-size: 16px;
  color: #afa7a7;
}

.shop-block-one .inner-box .price-box p {
  font-size: 16px;
  line-height: 22px;
}

.shop-block-one .inner-box .cart-btn button {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 42px;
  line-height: 46px;
  background: #faf1ef;
  text-align: center;
  border-radius: 50%;
  font-size: 20px;
  color: #0c0c0c;
  transition: all 500ms ease;
}

.shop-block-one .inner-box .cart-btn button:hover {
  color: #fff;
}

.shop-block-one .inner-box .option-list {
  position: absolute;
  left: 0px;
  bottom: 9px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1px 10px 10px 10px;
  transform: scale(0, 0);
  transition: all 500ms ease;
}

.shop-block-one .inner-box:hover .option-list {
  transform: scale(1, 1);
}

.shop-block-one .inner-box .option-list li a {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 42px;
  line-height: 46px;
  background: #faf1ef;
  text-align: center;
  font-size: 20px;
  color: #0c0c0c;
  border-radius: 50%;
}

.shop-block-one .inner-box .option-list li:nth-child(2) a {
  font-size: 24px;
}

.shop-block-one .inner-box .option-list li a:hover {
  color: #fff;
}

.shop-block-one .inner-box .option-list li a span {
  position: absolute;
  left: 0px;
  top: -30px;
  width: 128px;
  background: #0c0c0c;
  font-size: 16px;
  line-height: 22px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-weight: 700;
  text-align: center;
  padding: 4px 10px;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: all 500ms ease;
}

.shop-block-one .inner-box .option-list li a:hover span {
  top: -40px;
  opacity: 1;
  visibility: visible;
}

.shop-block-one .inner-box .option-list li a span:before {
  position: absolute;
  content: '';
  background: #0c0c0c;
  width: 12px;
  height: 6px;
  left: 15px;
  bottom: -6px;
  clip-path: polygon(0% 0%, 100% 0%, 50% 100%, 50% 100%, 0% 0%);
}

.shop-block-one .inner-box .option-list li.btn-box .theme-btn {
  padding: 8px 20px;
}

.shop-section .tab-btn-box {
  position: relative;
  display: block;
  margin-bottom: 60px;
}

.shop-section .tab-btn-box .tab-btns li {
  position: relative;
  display: inline-block;
  float: left;
  margin-right: 10px;
  font-size: 18px;
  line-height: 28px;
  font-family: 'Outfit', sans-serif;
  color: #0c0c0c;
  font-weight: 700;
  border: 1px dashed #d8d2d0;
  border-radius: 40px;
  padding: 10px 24px;
  cursor: pointer;
  transition: all 500ms ease;
}

.shop-section .tab-btn-box .tab-btns li:last-child {
  margin: 0px !important;
}

.shop-section .tab-btn-box .tab-btns li.active-btn {
  background: #faf1ef;
  border-color: #faf1ef;
}


/** subscribe-section **/

.subscribe-section {
  position: relative;
  overflow: hidden;
  padding: 115px 0px 112px 0px;
}

.subscribe-section .bg-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
}

.subscribe-section .bg-layer:before {
  position: absolute;
  content: '';
  background: #000;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.25;
}

.subscribe-section .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.subscribe-section .inner-box {
  position: relative;
}

.subscribe-section .inner-box p {
  color: #aca7a7;
}

.subscribe-section .inner-box .form-inner {
  position: relative;
  max-width: 570px;
  width: 100%;
  margin: 0 auto;
  padding: 42px 15px 12px 15px;
}

.subscribe-section .inner-box .form-inner .form-group {
  position: relative;
  margin: 0px;
}

.subscribe-section .inner-box .form-inner .form-group input[type='email'] {
  position: relative;
  display: block;
  width: 100%;
  height: 54px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 40px;
  font-size: 16px;
  color: #a09a9a;
  padding: 10px 170px 10px 30px;
  transition: all 500ms ease;
}

.subscribe-section .inner-box .form-inner .form-group button[type='submit'] {
  position: absolute;
  top: 0px;
  right: 0px;
}

.subscribe-section .inner-box .form-inner .form-group input:focus {}

.subscribe-section .sec-title {
  margin-bottom: 20px;
}


/** footer-style-two **/

.footer-style-two {
  background: -webkit-linear-gradient(0deg, #faf1ee, #faecdb 100%);
}

.footer-style-two .widget-title h3 {
  color: #0c0c0c;
}

.footer-style-two .links-widget .links-list li a {
  color: #0c0c0c;
}

.footer-style-two .links-widget .links-list li a:before {
  background: #ccc2ba;
  color: #fff;
}

.main-footer .logo-widget {
  position: relative;
  display: block;
  margin-top: 7px;
}

.main-footer .logo-widget .footer-logo {
  position: relative;
  display: block;
  margin-bottom: 32px;
}

.main-footer .logo-widget p {
  color: #7b7675;
  margin-bottom: 15px;
}

.main-footer .logo-widget h5 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 22px;
}

.main-footer .logo-widget button[type='button'] {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  padding: 13px 28px 13px 56px;
  border-radius: 40px;
}

.main-footer .logo-widget button i {
  position: absolute;
  display: inline-block;
  left: 6px;
  top: 5px;
  width: 40px;
  height: 40px;
  line-height: 46px;
  font-size: 18px;
  color: #e7e7e7;
  background: #0c0c0c;
  text-align: center;
  border-radius: 50%;
}

.main-footer .contact-widget .single-item {
  position: relative;
  display: block;
  padding-left: 40px;
  margin-bottom: 16px;
}

.main-footer .contact-widget .single-item:last-child {
  margin-bottom: 0px;
}

.main-footer .contact-widget .single-item .icon-box {
  position: absolute;
  left: 0px;
  top: 6px;
  font-size: 24px;
}

.main-footer .contact-widget .single-item h6 {
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
  text-transform: uppercase;
}

.main-footer .contact-widget .single-item p {
  display: block;
  color: #7b7675;
}

.main-footer .contact-widget .single-item p a {
  color: #7b7675;
}

.main-footer .contact-widget .single-item p a:hover {}

.footer-style-two .widget-section {
  padding: 113px 0px 110px 0px;
}

.footer-style-two .widget-section {
  position: relative;
}

.footer-style-two .widget-section .shape {
  position: absolute;
  left: -125px;
  top: 62px;
  width: 443px;
  height: 443px;
  border-radius: 50%;
  background: -webkit-linear-gradient(0deg, #fff, #faf0ec 100%);
}

.footer-bottom {
  position: relative;
  width: 100%;
  background: #0c0c0c;
  padding: 20px 0px;
}

.footer-bottom .bottom-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-card {
  position: relative;
  display: flex;
  align-items: center;
}

.footer-card li {
  margin-right: 10px;
}

.footer-card li:last-child {
  margin: 0px !important;
}

.footer-card li a {
  position: relative;
  display: inline-block;
}

.scroll-top.home-2 {
  bottom: 120px;
  background: #fff;
  border-color: #fff;
  color: #0c0c0c;
}

.scroll-top.home-2:hover {
  color: #fff;
}



/*** 

====================================================================
                        Home-Page-Three
====================================================================

***/


/** header-style-three **/

.header-style-three {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
}

.header-top-three {
  position: relative;
  width: 100%;
  padding: 11px 0px;
}

.header-top-three .top-inner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-top-three .top-inner p {
  position: relative;
  display: block;
  color: #fff;
  padding-left: 28px;
}

.header-top-three .top-inner p a {
  color: #fff;
}

.header-top-three .top-inner p a:hover {
  text-decoration: underline;
}

.header-top-three .top-inner p i {
  position: absolute;
  left: 0px;
  top: 4px;
  font-size: 20px;
  color: #fff;
}

.header-top-three .top-inner button {
  display: inline-block;
  color: #fff;
  line-height: 20px;
  border-bottom: 1px solid #fff;
  transition: all 500ms ease;
}

.header-style-three .outer-box {
  padding: 0px;
}

.header-style-three .main-menu .navigation>li>a {
  padding-top: 20px;
  padding-bottom: 20px;
}

.header-style-three .main-menu .navigation>li.dropdown>a:before {
  top: 19px;
}

.header-style-three .logo-box .logo {
  top: 0px;
}

.header-style-three .logo-box {
  margin-right: 0px;
}

.header-style-three .header-lower .menu-right-content {
  padding-bottom: 0px;
  top: 4px;
}

.header-style-three .menu-right-content .search-inner .search-box-outer {
  color: #0c0c0c;
  font-size: 22px;
}

.header-style-three .menu-right-content .search-inner .search-box-outer:hover {}

.header-style-three .menu-right-content .search-inner .search-box-outer {
  padding-right: 26px;
}

.header-style-three .menu-right-content .search-inner .search-box-outer:before {
  position: absolute;
  content: '';
  border-right: 1px dashed #d1d1d1;
  width: 1px;
  height: 36px;
  top: -7px;
  right: 0px;
}

.header-style-three .menu-right-content .option-box li.rating-box a {
  padding-right: 23px;
}

.header-style-three .menu-right-content .option-box li.rating-box a span {
  background: #fb991a;
}

.header-style-three .menu-right-content .option-box li:last-child {
  padding-left: 0px;
}

.header-style-three {
  background: transparent;
}

.header-style-three .outer-box {
  background: #fff;
  z-index: 1;
}

.header-style-three .outer-box:before {
  position: absolute;
  content: '';
  background: #fff;
  width: calc(100% + 80px);
  height: 100%;
  left: -40px;
  top: 0px;
  z-index: -1;
  border-radius: 0px 0px 15px 15px;
}


/** banner-style-three **/

.banner-style-three {
  position: relative;
  background: -webkit-linear-gradient(0deg, #faf1ef, #eef9ec 100%);
  padding: 0px;
}

.banner-style-three .banner-carousel .slide-item {
  position: relative;
  padding: 290px 0px 70px 0px;
}

.banner-style-three .owl-nav {
  left: inherit;
  right: 157px;
}

.banner-style-three .outer-container {
  position: relative;
  padding: 0px 300px 0px 100px;
}

.banner-style-three .content-box h5 {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 26px;
  font-weight: 800;
  text-transform: uppercase;
  padding: 6px 100px 4px 0px;
  z-index: 1;
  margin-bottom: 27px;
}

.banner-style-three .content-box h5:before {
  position: absolute;
  content: '';
  background: -webkit-linear-gradient(0deg, #fb991a, #f8f2ef 100%);
  left: -100px;
  top: 0px;
  width: calc(100% + 100px);
  height: 100%;
  z-index: -1;
}

.banner-style-three .banner-carousel .content-box h2 {
  font-size: 72px;
  line-height: 82px;
  margin-bottom: 34px;
}

.banner-style-three .banner-carousel .content-box h2 span {
  position: relative;
  display: inline-block;
  color: #fd6145;
}

.banner-style-three .banner-carousel .content-box h2 span:before {
  display: none;
}

.banner-style-three .banner-carousel .content-box h2 span:after {
  position: absolute;
  content: '';
  background-image: url(./../images/shape/shape-21.png);
  width: 150px;
  height: 28px;
  right: -170px;
  bottom: 0px;
  background-repeat: no-repeat;
}

.banner-style-three .content-box {
  float: left;
}

.banner-style-three .inner-box {
  position: relative;
  float: right;
  text-align: left;
}

.banner-style-three .inner-box p {
  position: relative;
  display: block;
  padding-top: 33px;
  margin-bottom: 20px;
}

.banner-style-three .inner-box p:before {
  position: absolute;
  content: '';
  border-top: 1px dashed #fd6145;
  width: 100px;
  height: 1px;
  left: 0px;
  top: 0px;
}

.banner-style-three .inner-box .video-btn a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 46px;
  font-family: 'Outfit', sans-serif;
  color: #0c0c0c;
  font-weight: 800;
  text-transform: uppercase;
  padding-left: 60px;
  margin-bottom: 60px;
}

.banner-style-three .inner-box .video-btn a i {
  position: absolute;
  display: inline-block;
  left: 0px;
  top: 0px;
  width: 46px;
  height: 46px;
  line-height: 56px;
  text-align: center;
  font-size: 30px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
}

.banner-style-three .inner-box .products-image {
  position: relative;
  display: block;
  max-width: 210px;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
}

.banner-style-three .inner-box .products-image img {
  width: 100%;
  border-radius: 10px;
}

.banner-style-three .content-box .image-box {
  position: absolute;
  right: -153px;
  bottom: -46px;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
}

.banner-style-three .content-box .image-box img {
  width: 100%;
  border-radius: 10px;
}

.banner-style-three .pattern {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.banner-style-three .banner-image {
  position: absolute;
  left: 50%;
  bottom: 0px;
  opacity: 0;
  transform: translateY(10px) translateX(-50%);
  -webkit-transition: all 1000ms ease;
  -moz-transition: all 1000ms ease;
  -ms-transition: all 1000ms ease;
  -o-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.banner-style-three .active .banner-image {
  opacity: 1;
  -webkit-transition-delay: 1000ms;
  -moz-transition-delay: 1000ms;
  -ms-transition-delay: 1000ms;
  -o-transition-delay: 1000ms;
  transition-delay: 1000ms;
  transform: translateY(0) translateX(-50%);
}


/** categories-style-three **/

.categories-style-three {
  position: relative;
  padding: 115px 0px 112px 0px;
}

.categories-style-three .inner-container {
  position: relative;
  margin: 0px 10px;
}

.categories-style-three .categories-block {
  padding: 0px 0px;
}

.categories-block-three .inner-box {
  position: relative;
  display: block;
}

.categories-block-three .inner-box h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 16px;
}

.categories-block-three .inner-box h3 a {
  display: inline-block;
  color: #0c0c0c;
}

.categories-block-three .inner-box h3 a:hover {}

.categories-block-three .inner-box .image-box {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  margin: 0 auto;
}

.categories-block-three .inner-box .image-box img {
  width: 100%;
  border-radius: 50%;
  transition: all 500ms ease;
}

.categories-block-three .inner-box .image-box:before,
.categories-block-three .inner-box .image-box:after {
  position: absolute;
  content: '';
  background: #fff;
  width: 15px;
  height: 100%;
  top: 0px;
  z-index: 1;
}

.categories-block-three .inner-box .image-box:before {
  left: 0px;
}

.categories-block-three .inner-box .image-box:after {
  right: 0px;
}

.categories-block-three .inner-box .image-inner {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 17px;
}

.categories-block-three .inner-box .image-inner .link-box {
  position: absolute;
  left: 50%;
  bottom: -200px;
  transform: translateX(-50%);
  opacity: 0;
  transition: all 500ms ease;
}

.categories-block-three .inner-box:hover .image-inner .link-box {
  bottom: -135px;
  opacity: 1;
}

.categories-block-three .inner-box .image-inner .link-box a {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 200px;
  background: #0c0c0c;
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  color: #0c0c0c;
  padding-top: 18px;
  border-radius: 50%;
  font-size: 30px;
  color: #fff;
}

.categories-block-three .inner-box .image-inner .link-box a:hover {}


/** offers-section **/

.offers-section {
  position: relative;
}

.offers-block-one .inner-box {
  position: relative;
  display: block;
  background: #537f7e;
  border-radius: 25px;
  padding: 33px 50px 40px 40px;
}

.offers-block-one .inner-box h6 {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 10px;
}

.offers-block-one .inner-box h2 {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 40px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 13px;
}

.offers-block-one .inner-box P {
  display: block;
  color: #fff;
  margin-bottom: 22px;
}

.offers-block-one .inner-box a {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 52px;
  line-height: 60px;
  background: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 30px;
  color: #0c0c0c;
  z-index: 1;
}

.offers-block-one .inner-box a:hover {
  color: #fff;
}

.offers-block-one .inner-box a:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 50%;
  transform: scale(0, 0);
  z-index: -1;
  transition: all 500ms ease;
}

.offers-block-one .inner-box a:hover:before {
  transform: scale(1, 1);
}

.offers-block-one .inner-box h5 {
  position: absolute;
  display: inline-block;
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  text-align: center;
  border-radius: 50%;
  top: 50px;
  right: 50px;
}

.offers-block-one .inner-box .shape {
  position: absolute;
  top: 30px;
  right: 0px;
  width: 371px;
  height: 264px;
  background-repeat: no-repeat;
}

.offers-block-one .inner-box .image-box {
  position: absolute;
  top: 60px;
  right: 70px;
}

.offers-block:last-child .offers-block-one .inner-box {
  background: #66689f;
}

.shop-section.home-2 .tab-btn-box .tab-btns li {
  float: none;
  margin-right: 6px;
}

.shop-section .more-btn {
  margin-top: 60px;
}


/** highlights-section **/

.highlights-section {
  position: relative;
  background: -webkit-linear-gradient(0deg, #eef9ec, #faf1ef 100%);
}

.highlights-section:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 100%;
  height: 50%;
  left: 0px;
  top: 0px;
}

.highlights-section .inner-container {
  position: relative;
  display: block;
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.06);
  padding: 38px 40px 37px 40px;
  border-radius: 25px;
}

.highlights-section .single-item {
  position: relative;
  display: block;
  padding-left: 80px;
}

.highlights-section .single-item .icon-box {
  position: absolute;
  display: inline-block;
  left: 0px;
  top: 2px;
  width: 60px;
  height: 60px;
  line-height: 68px;
  font-size: 30px;
  background: #faf1ef;
  text-align: center;
  border-radius: 50%;
}

.highlights-section .single-column:nth-child(2) .single-item .icon-box {
  background: #faeddc;
  color: #fb991a;
}

.highlights-section .single-column:last-child .single-item .icon-box {
  background: #eff9ec;
  color: #8B8B8B;
}

.highlights-section .single-item h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 3px;
}

.highlights-section .single-item:before {
  position: absolute;
  content: '';
  border-right: 1px dashed #d1d1d1;
  width: 1px;
  height: 60px;
  top: 1px;
  right: 0px;
}

.highlights-section .single-column:last-child .single-item:before {
  display: none;
}


/** working-style-two **/

.working-style-two {
  position: relative;
  background: -webkit-linear-gradient(0deg, #eef9ec, #faf1ef 100%);
}

.working-style-two .inner-container {
  position: relative;
}

.working-style-two .border-shape {
  position: absolute;
  left: 0px;
  top: 25px;
  right: 0px;
  max-width: 750px;
  width: 100%;
  margin: 0px auto;
  border-top: 1px dashed #c2c5be;
}

.working-block-two .inner-box {
  position: relative;
  display: block;
  margin-bottom: 52px;
}

.working-block-two .inner-box h5 {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 800;
  margin-bottom: 81px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.04);
}

.working-block-two .inner-box h5:before {
  position: absolute;
  content: '';
  border-left: 1px dashed #c2c5be;
  width: 1px;
  height: 44px;
  left: 25px;
  top: 50px;
}

.working-block-two .inner-box h5:after {
  position: absolute;
  content: '';
  width: 12px;
  height: 12px;
  left: 19px;
  top: 99px;
  border-radius: 50%;
}

.working-block-two .inner-box .image-box {
  position: relative;
  display: block;
  border-radius: 25px;
  padding: 9px;
  border: 1px dashed #bec2ba;
  margin-bottom: 33px !important;
}

.working-block-two .inner-box .image-box img {
  width: 100%;
  border-radius: 25px;
}

.working-block-two .inner-box h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 8px;
}


/** chooseus-section **/

.chooseus-section {
  position: relative;
  padding: 116px 0px 115px 0px;
}

.chooseus-section .sec-title {
  margin-bottom: 0px;
}

.chooseus-section .title-box {
  margin-bottom: 48px;
}

.chooseus-section .inner-container {
  position: relative;
  padding-bottom: 60px;
}

.chooseus-section .inner-container .pattern-layer .pattern-1 {
  position: absolute;
  left: -60px;
  top: 0px;
  width: calc(100% + 120px);
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 25px;
}

.chooseus-section .inner-container .pattern-layer .pattern-2 {
  position: absolute;
  left: 420px;
  bottom: 80px;
  width: 123px;
  height: 119px;
  background-repeat: no-repeat;
}

.chooseus-section .inner-container:before {
  position: absolute;
  content: '';
  background: #0c0c0c;
  width: calc(100% + 120px);
  height: 100%;
  left: -60px;
  top: 0px;
  border-radius: 25px;
}

.chooseus-section .image-box {
  position: relative;
  display: inline-block;
  padding-top: 57px;
}

.chooseus-section .image-box .image {
  position: relative;
  display: block;
}

.chooseus-section .image-box .image img {
  width: 100%;
}

.chooseus-section .feature-box {
  position: absolute;
}

.chooseus-section .feature-box.one {
  left: 217px;
  top: 50px;
}

.chooseus-section .feature-box .icon-box {
  position: relative;
  display: inline-block;
  width: 42px;
  height: 42px;
  line-height: 48px;
  font-size: 20px;
  color: #0c0c0c;
  background: #fff;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 500ms ease;
}

.chooseus-section .feature-box:hover .icon-box {
  color: #fff;
}

.chooseus-section .feature-box .text {
  position: absolute;
  top: -15px;
  right: 40px;
  width: 140px;
  background: #fff;
  padding: 12px 10px 13px 20px;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  transition: all 500ms ease;
}

.chooseus-section .feature-box:hover .text,
.chooseus-section .feature-box.active .text {
  right: 60px;
  visibility: visible;
  opacity: 1;
}

.chooseus-section .feature-box .text h5 {
  display: block;
  font-size: 18px;
  line-height: 24px;
  font-weight: 700;
}

.chooseus-section .feature-box .text:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 9px;
  height: 19px;
  right: -9px;
  top: 50%;
  transform: translateY(-50%);
  clip-path: polygon(0% 0%, 100% 50%, 0% 100%, 0% 100%, 0% 0%);
}

.chooseus-section .feature-box.one .shape {
  position: absolute;
  top: 47px;
  left: 20px;
  width: 76px;
  height: 96px;
  background-repeat: no-repeat;
}

.chooseus-section .feature-box.two {
  top: 140px;
  right: 8px;
}

.chooseus-section .feature-box.three {
  left: 100px;
  bottom: 0px;
}

.chooseus-section .feature-box.two .shape {
  position: absolute;
  top: 23px;
  right: 45px;
  width: 75px;
  height: 70px;
  background-repeat: no-repeat;
}

.chooseus-section .feature-box.three .shape {
  position: absolute;
  left: 25px;
  bottom: 47px;
  width: 76px;
  height: 96px;
  background-repeat: no-repeat;
}

.chooseus-section .content-box {
  position: relative;
  display: block;
  margin-top: -43px;
}

.chooseus-section .content-box .single-item {
  position: relative;
  display: block;
  background: #fff;
  border-radius: 25px;
  padding: 34px 30px 32px 80px;
  margin-bottom: 30px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.10);
}

.chooseus-section .content-box .single-item:last-child {
  margin-bottom: 0px;
}

.chooseus-section .content-box .single-item .icon-box {
  position: absolute;
  left: 30px;
  top: 40px;
  font-size: 30px;
}

.chooseus-section .content-box .single-item:nth-child(2) .icon-box {
  color: #fb991a;
}

.chooseus-section .content-box .single-item:last-child .icon-box {
  color: #8B8B8B;
}

.chooseus-section .content-box .single-item h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  padding-bottom: 12px;
  margin-bottom: 17px;
}

.chooseus-section .content-box .single-item h3:before {
  position: absolute;
  content: '';
  border-top: 1px dashed #fd6145;
  width: 70px;
  height: 1px;
  left: 0px;
  bottom: 0px;
}

.chooseus-section .content-box .single-item:nth-child(2) h3:before {
  border-top: 1px dashed #fb991a;
}

.chooseus-section .content-box .single-item:last-child h3:before {
  border-top: 1px dashed #8B8B8B;
}


/** service-style-three **/

.service-style-three {
  position: relative;
}

.service-style-three:before {
  position: absolute;
  content: '';
  background: -webkit-linear-gradient(0deg, #faecdb, #faf1ee 100%);
  width: 100%;
  height: 112px;
  left: 0px;
  bottom: 0px;
}

.service-style-three .services-list {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.service-style-three .services-list li {
  position: relative;
  margin: 0px 8px;
}

.service-style-three .service-block .inner-box {
  position: relative;
  display: block;
  padding-right: 105px;
  margin-bottom: 34px;
  cursor: pointer;
}

.service-style-three .service-block .inner-box .image-box {
  position: absolute;
  top: -29px;
  right: 0px;
  transform: scale(0.42);
  border-radius: 50%;
  transition: all 500ms ease;
}

.service-style-three .active-btn .service-block .inner-box .image-box {
  transform: scale(1);
}

.service-style-three .service-block .inner-box .image-box img {
  width: 100%;
  border-radius: 50%;
}

.service-style-three .service-block .inner-box h5 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 800;
  color: #c9c1c1;
  margin-bottom: 20px;
  transition: all 500ms ease;
}

.service-style-three .active-btn .service-block .inner-box h5 {}

.service-style-three .service-block .inner-box h3 {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  color: #afa7a7;
}

.service-style-three .active-btn .service-block .inner-box h3 {
  color: #0c0c0c;
}

.service-style-three .content-box {
  position: relative;
  display: block;
  background: #0c0c0c;
  padding: 42px 150px 50px 150px;
  border-radius: 25px;
}

.service-style-three .content-box:before {
  position: absolute;
  content: '';
  background-image: url(./../images/shape/shape-30.png);
  width: 44px;
  height: 24px;
  left: 50%;
  margin-left: -22px;
  top: 0px;
  background-repeat: no-repeat;
}

.service-style-three .content-box p {
  color: #aca7a7;
  margin-bottom: 22px;
}

.service-style-three .tab-btn-box {
  position: relative;
  padding-bottom: 36px;
}


/** testimonial-style-three **/

.testimonial-style-three {
  position: relative;
  padding: 120px 0px 212px 0px;
}

.testimonial-style-three .sec-title {
  margin-bottom: 0px;
}

.testimonial-style-three .sec-title p {
  margin-bottom: 37px;
}

.testimonial-style-three .sec-title h5 {
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
  text-transform: uppercase;
}

.testimonial-style-three .sec-title h5 a {
  position: relative;
  display: inline-block;
  color: #0c0c0c;
  padding-left: 27px;
}

.testimonial-style-three .sec-title h5 a i {
  position: absolute;
  left: 0px;
  top: -1px;
  font-size: 24px;
}

.testimonial-style-three .sec-title h5 a:hover {}

.testimonial-block-three .inner-box {
  position: relative;
  display: block;
  background: #fff;
  padding: 42px 30px 33px 30px;
  border-radius: 25px;
}

.testimonial-block-three .inner-box .author-box {
  position: relative;
  display: block;
  margin-bottom: 19px;
}

.testimonial-block-three .inner-box .author-box .author-thumb {
  position: relative;
  display: inline-block;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  padding: 3px;
  margin-bottom: 18px !important;
}

.testimonial-block-three .inner-box .author-box .author-thumb img {
  width: 100%;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.testimonial-block-three .inner-box .author-box .author-thumb:before {
  position: absolute;
  content: '';
  width: 60px;
  height: 60px;
  border-radius: 50%;
  left: -2px;
  top: -2px;
}

.testimonial-block-three .inner-box .author-box .author-thumb:after {
  position: absolute;
  content: '';
  width: 56px;
  height: 56px;
  border-radius: 50%;
  left: 0px;
  top: 0px;
  background: #fff;
}

.testimonial-block-three .inner-box .author-box .designation {
  position: relative;
  display: block;
  font-size: 17px;
}

.testimonial-block-three .inner-box .rating-box {
  position: absolute;
  right: 30px;
  top: 40px;
  min-width: 97px;
  height: 50px;
  line-height: 50px;
  font-size: 20px;
  font-family: 'Outfit', sans-serif;
  color: #0c0c0c;
  font-weight: 700;
  border: 1px solid #fb991a;
  border-radius: 50%;
  text-align: center;
  padding: 0 10px;
}

.testimonial-block-three .inner-box .rating-box i {
  position: relative;
  font-size: 16px;
  color: #fb991a;
  margin-right: 5px;
  top: 1px;
}

.testimonial-block-three .inner-box h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 3px;
}

.testimonial-block-three .inner-box .quote-box {
  position: absolute;
  top: 110px;
  right: 30px;
}

.testimonial-style-three .content-box {
  position: relative;
  margin-right: -400px;
}

.testimonial-style-three .content-box:before {
  position: absolute;
  content: '';
  background: #faf3ef;
  width: 370px;
  height: 100%;
  top: 0px;
  right: 0px;
  z-index: 2;
  border-radius: 25px;
  opacity: 0.5;
}

.testimonial-style-three .owl-nav {
  position: absolute;
  left: 0px;
  bottom: -20px;
  max-width: 770px;
  width: 100%;
  margin: 0 auto;
  text-align: right;
}

.testimonial-style-three .owl-nav:before {
  position: absolute;
  content: '';
  background: #e4dad8;
  width: 534px;
  height: 1px;
  top: 26px;
  right: 154px;
}

.testimonial-style-three .owl-nav button {
  background: transparent;
  border: 1px dashed #cfc7c2;
}

.testimonial-style-three .owl-dots {
  position: absolute;
  left: 70px;
  bottom: -40px;
  min-height: 60px;
  counter-reset: slides-num;
}

.testimonial-style-three .owl-dots::after {
  position: absolute;
  right: 8px;
  top: -1px;
  content: "0" counter(slides-num);
  display: inline-block;
  font-size: 16px;
  color: #afa7a7;
  font-weight: 800;
  font-family: 'Outfit', sans-serif;
}

.testimonial-style-three .owl-dots:before {
  position: absolute;
  content: '';
  right: 35px;
  top: 7px;
  width: 1px;
  height: 12px;
  background: #a09ba9;
  transform: rotate(10deg);
}

.testimonial-style-three .owl-dots button span {
  display: none !important;
}

.testimonial-style-three .owl-dots button {
  position: absolute;
  top: -4px;
  right: 55px;
  counter-increment: slides-num;
  font-size: 24px;
  font-weight: 800;
  font-family: 'Outfit', sans-serif;
}

.testimonial-style-three .owl-dots button.active:before {
  position: absolute;
  content: counter(slides-num);
}

.testimonial-style-three .owl-dots button.active:after {
  position: absolute;
  content: '0';
  top: 0px;
  right: 0px;
}


/** news-style-two **/

.news-style-two {
  position: relative;
}

.news-style-two .single-item-carousel {
  max-width: 770px;
  margin: 0 auto;
}

.news-style-two .owl-carousel .owl-stage-outer {
  overflow: visible;
}

.news-block-two .inner-box {
  position: relative;
  display: block;
  padding-left: 350px;
}

.news-block-two .inner-box:before {
  position: absolute;
  content: '';
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
  width: calc(100% - 25px);
  height: 100%;
  top: 0px;
  right: 0px;
  border: 1px dashed #d8d2d0;
  border-radius: 25px;
}

.news-block-two .inner-box .image-box {
  position: absolute;
  left: 0px;
  top: 25px;
  width: 350px;
  border-radius: 25px;
  overflow: hidden;
}

.news-block-two .inner-box .image-box img {
  width: 100%;
  border-radius: 25px;
  transition: all 500ms ease;
}

.news-block-two .inner-box:hover .image-box img {
  transform: scale(1.05);
}

.news-block-two .inner-box .image-box h6 {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  padding: 5px 15px 3px 15px;
  border-radius: 30px;
}

.news-block-two .inner-box .content-box {
  position: relative;
  padding: 35px 40px 0px 40px;
}

.news-block-two .inner-box .post-info {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 11px;
}

.news-block-two .inner-box .post-info li {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Outfit', sans-serif;
  color: #7b7675;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 32px;
}

.news-block-two .inner-box .post-info li:last-child {
  margin: 0px !important;
}

.news-block-two .inner-box .post-info li a {
  display: inline-block;
  color: #7b7675;
}

.news-block-two .inner-box .post-info li a:hover {}

.news-block-two .inner-box .post-info li:before {
  position: absolute;
  content: "\f117";
  font-family: 'flaticon';
  font-size: 30px;
  top: -1px;
  right: -30px;
  font-weight: 400;
}

.news-block-two .inner-box .post-info li:last-child:before {
  display: none;
}

.news-block-two .inner-box h3 {
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 14px;
}

.news-block-two .inner-box h3 a {
  display: inline-block;
  color: #0c0c0c;
}

.news-block-two .inner-box h3 a:hover {}

.news-block-two .inner-box p {
  display: block;
  padding-bottom: 32px;
  border-bottom: 1px dashed #d8d2d0;
}

.news-block-two .inner-box .content-box .lower-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 0px 16px 0px;
}

.news-block-two .inner-box .content-box .lower-box h6 {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
  text-transform: uppercase;
}

.news-block-two .inner-box .content-box .lower-box h6 a {
  display: inline-block;
  color: #0c0c0c;
  padding-left: 28px;
}

.news-block-two .inner-box .content-box .lower-box h6 a:hover {}

.news-block-two .inner-box .content-box .lower-box h6 a i {
  position: absolute;
  left: 0px;
  top: -1px;
  font-size: 24px;
  font-weight: 400;
}

.news-block-two .inner-box .content-box .share-box a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #0c0c0c;
}

.news-block-two .inner-box .content-box .share-box a:hover {}

.news-style-two .owl-nav {
  position: relative;
  display: block;
  text-align: center;
  margin-top: 60px;
}

.news-style-two .owl-nav button {
  border: 1px dashed #d8d2d0;
  background: transparent;
}


/** apps-section **/

.apps-section {
  position: relative;
  background: -webkit-linear-gradient(0deg, #faf1ef, #eef9ec 100%);
  overflow: hidden;
}

.apps-section .content-box {
  position: relative;
  display: block;
}

.apps-section .content-box .btn-box {
  position: relative;
  display: flex;
  align-items: center;
}

.apps-section .content-box .btn-box .theme-btn.btn-one {
  margin-right: 10px;
}

.apps-section .content-box .btn-box .theme-btn i {
  position: relative;
  font-size: 20px;
  margin-right: 10px;
  top: 2px;
}

.theme-btn.btn-two {
  background: #0c0c0c;
}

.theme-btn.btn-two:hover {}

.apps-section .pattern-layer {
  position: absolute;
  left: 100px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.apps-section .content-box .sec-title {
  margin-bottom: 32px;
}

.apps-section .image-layer {
  position: absolute;
  left: 280px;
  bottom: 0px;
}



/** footer-style-three **/

.footer-style-three .widget-section {
  padding: 100px 0px 120px 0px;
}

.footer-style-three .logo-widget {
  position: relative;
  display: block;
}

.footer-style-three .logo-widget .single-item {
  position: relative;
  margin-bottom: 25px;
}

.footer-style-three .logo-widget .single-item h6 {
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.footer-style-three .logo-widget .single-item p {
  color: #aca7a7;
}

.footer-style-three .logo-widget .single-item p a:hover {}

.footer-style-three .social-links-two li {
  position: relative;
  display: inline-block;
  float: left;
  margin-right: 10px;
}

.footer-style-three .social-links-two li:last-child {
  margin: 0px !important;
}

.footer-style-three .social-links-two li a {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #fff;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
}

.footer-style-three .social-links-two li:first-child a {
  background: #3b5998;
}

.footer-style-three .social-links-two li:nth-child(2) a {
  background: #55acee;
}

.footer-style-three .social-links-two li:nth-child(3) a {
  background: #3f729b;
}

.footer-style-three .social-links-two li:nth-child(4) a {
  background: #aca7a7;
}

.footer-style-three .social-links-two li:last-child a {
  background: #cd201f;
}

.footer-style-three .links-widget {
  margin-left: 0px;
}

.footer-style-three .footer-bottom {
  background: #141414;
}

.footer-style-three .map-inner {
  position: relative;
  display: block;
}

.footer-style-three .map-inner iframe {
  position: relative;
  width: 100%;
  height: 254px;
  border-radius: 25px;
  overflow: hidden;
}

.footer-style-three .tab-buttons li {
  position: relative;
  display: block;
  margin-bottom: 12px;
}

.footer-style-three .tab-buttons li:last-child {
  margin-bottom: 0px;
}

.footer-style-three .tab-buttons li span {
  position: relative;
  display: inline-block;
  font-size: 17px;
  color: #aca7a7;
  cursor: pointer;
  transition: all 500ms ease;
}

.footer-style-three .tab-buttons li.active-btn span {
  padding-right: 30px;
  color: #fff;
}

.footer-style-three .tab-buttons li span:before {
  position: absolute;
  content: "\f10e";
  font-family: 'flaticon';
  font-size: 24px;
  top: 0px;
  right: 0px;
  font-weight: 400;
  opacity: 0;
  transition: all 500ms ease;
}

.footer-style-three .tab-buttons li.active-btn span:before {
  opacity: 1;
}

.footer-style-three .tabs-box .widget-title {
  margin-bottom: 24px;
}

.footer-style-three .tabs-box .tabs-content .link-box {
  position: relative;
  display: block;
  text-align: right;
  margin-bottom: 32px;
}

.footer-style-three .tabs-box .tabs-content .link-box h6 {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
  text-transform: uppercase;
}

.footer-style-three .tabs-box .tabs-content .link-box h6 a {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  color: #fff;
}

.footer-style-three .tabs-box .tabs-content .link-box h6 a:hover {}

.footer-style-three .tabs-box .tabs-content .link-box h6 a i {
  position: absolute;
  left: 0px;
  top: -1px;
  font-size: 24px;
  font-weight: 400;
}




/*** 

====================================================================
                        Inner-Pages
====================================================================

***/


/** page-title **/

.page-title {
  position: relative;
  padding: 130px 50px 132px 50px;
}

.page-title .bg-layer {
  position: absolute;
  left: 50px;
  top: 0px;
  width: calc(100% - 100px);
  height: 100%;
  background-repeat: no-repeat;
  border-radius: 0px 50px 50px 50px;

}

.page-title .bg-layer:before {
  position: absolute;
  background: linear-gradient(92.6deg, #faeddd 22.74%, rgba(255, 140, 140, 0) 78.06%);

  content: '';
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  border-radius: 0px 50px 50px 50px;
}

.page-title h1 {
  position: relative;
  display: block;
  font-size: 48px;
  line-height: 58px;
  font-weight: 700;
  margin-bottom: 6px;
}

.page-title .bread-crumb li {
  position: relative;
  display: inline-block;
  font-size: 17px;
  color: #7b7675;
  padding-right: 16px;
  margin-right: 5px;
  margin-bottom: 70px;
}

.page-title .bread-crumb li span {
  color: #0c0c0c;
}

.page-title .bread-crumb li:before {
  position: absolute;
  content: '\f105';
  font-family: 'Font Awesome 6 Free';
  font-size: 14px;
  color: #7b7675;
  right: 0px;
  top: 1px;
  font-weight: 900;
  transition: all 500ms ease;
}

.page-title .bread-crumb li:last-child:before {
  display: none;
}

.page-title .bread-crumb li:last-child {
  padding: 0px !important;
  margin: 0px !important;
}

.page-title .bread-crumb li a {
  position: relative;
  display: inline-block;
  color: #7b7675;
}

.page-title .bread-crumb li a:hover {}


/** about-style-two **/

.about-style-two {
  position: relative;
  padding: 120px 0px;
}

.about-style-two .video-inner {
  position: relative;
  display: block;
  margin-left: -90px;
  padding-right: 54px;
  padding-bottom: 48px;
}

.about-style-two .video-inner .inner-box {
  position: relative;
  display: block;
  padding: 211px 0px 210px 0px;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
}

.about-style-two .video-inner .inner-box .video-btn {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  line-height: 110px;
  font-size: 30px;
  background: #fff;
  text-align: center;
  border-radius: 50%;
}

.about-style-two .video-inner .inner-box .video-btn:before,
.about-style-two .video-inner .inner-box .video-btn:after {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation-delay: .9s;
  animation-delay: .9s;
  content: "";
  position: absolute;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.about-style-two .video-inner .inner-box .video-btn:after {
  -webkit-animation-delay: .6s;
  animation-delay: .6s;
}

@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 30px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.about-style-two .video-inner .shape-box .shape-1 {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 597px;
  height: 549px;
  background-repeat: no-repeat;
}

.about-style-two .video-inner .shape-box .shape-2 {
  position: absolute;
  right: 0px;
  bottom: 58px;
  width: 146px;
  height: 130px;
  background-repeat: no-repeat;
}

.about-style-two .video-inner .shape-box .shape-3 {
  position: absolute;
  left: 390px;
  top: 6px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: #fb991a;
  opacity: 0.5;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}

.about-style-two .video-inner .shape-box .shape-4 {
  position: absolute;
  left: 210px;
  bottom: -21px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #8B8B8B;
  opacity: 0.5;
  -webkit-animation: zoom-fade 4s infinite linear;
  animation: zoom-fade 4s infinite linear;
}

.about-style-two .content-box {
  position: relative;
  display: block;
}

.about-style-two .content-box .inner-box .single-item {
  position: relative;
  display: block;
}

.about-style-two .content-box .inner-box .single-item .icon-box {
  position: relative;
  display: inline-block;
  font-size: 40px;
  margin-bottom: 38px;
}

.about-style-two .content-box .inner-box .single-item .icon-box .shape {
  position: absolute;
  left: -10px;
  top: 10px;
  width: 59px;
  height: 50px;
  background-repeat: no-repeat;
  z-index: -1;
}

.about-style-two .content-box .inner-box .single-item h3 {
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  padding-bottom: 13px;
  margin-bottom: 22px;
}

.about-style-two .content-box .inner-box .single-item h3:before {
  position: absolute;
  content: '';
  border-bottom: 1px dashed #dcd5d4;
  width: 70px;
  height: 1px;
  left: 0px;
  bottom: 0px;
}

.about-style-two .content-box .sec-title p {
  margin-top: 30px;
}

.about-style-two .content-box .sec-title {
  margin-bottom: 32px;
}

.service-style-two.service-page {
  padding-bottom: 60px;
}

.service-style-two .owl-nav {
  position: absolute;
  left: 0px;
  top: 50%;
  width: 100%;
}

.service-style-two .owl-nav button {
  width: 55px;
  height: 55px;
  line-height: 55px;
  border: 1px dashed #fff;
  background: transparent;
  margin: 0px;
  color: #fff;
}

.service-style-two .owl-nav button.owl-prev {
  position: absolute;
  left: -87px;
  top: -27px;
}

.service-style-two .owl-nav button.owl-next {
  position: absolute;
  right: -87px;
  top: -27px;
}

.service-section.service-page-2 .service-block-one .inner-box {
  margin-bottom: 30px;
}

.service-section.service-page-2 {
  padding-bottom: 90px;
}


/** service-details **/

.service-details {
  position: relative;
  padding: 45px 0px;
}

.service-sidebar {
  position: relative;
  display: block;
  margin-right: 20px;
}

.service-sidebar .category-widget {
  position: relative;
  display: block;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: 25px;
  overflow: hidden;
}

.service-sidebar .category-widget h3 {
  position: relative;
  display: block;
  font-size: 22px;
  line-height: 32px;
  color: #fff;
  font-weight: 700;
  padding: 20px 20px 18px 53px;
}

.service-sidebar .category-widget h3:before {
  position: absolute;
  content: "\f112";
  font-family: 'flaticon';
  font-size: 18px;
  left: 25px;
  top: 19px;
  font-weight: 400;
}

.service-sidebar .category-widget .category-list {
  position: relative;
  padding: 0px 24px;
  border: 1px dashed #d8d2d0;
  border-top: none;
  border-radius: 0px 0px 25px 25px;
}

.service-sidebar .category-widget .category-list li {
  position: relative;
  display: block;
  padding: 19px 0px 17px 0px;
  border-bottom: 1px dashed #d8d2d0;
}

.service-sidebar .category-widget .category-list li:last-child {
  border-bottom: none;
}

.service-sidebar .category-widget .category-list li a {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-family: 'Outfit', sans-serif;
  color: #afa7a7;
  font-weight: 700;
}

.service-sidebar .category-widget .category-list li a.current,
.service-sidebar .category-widget .category-list li a:hover {
  color: #0c0c0c;
}

.service-sidebar .category-widget .category-list li a:before {
  position: absolute;
  content: "\f132";
  font-family: 'flaticon';
  font-size: 20px;
  top: -1px;
  right: 0px;
  font-weight: 400;
}

.service-sidebar .category-widget {
  margin-bottom: 50px;
}

.service-sidebar .download-widget {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 25px;
  margin-bottom: 50px;
}

.service-sidebar .download-widget .image-box {
  position: relative;
  display: block;
}

.service-sidebar .download-widget .image-box img {
  width: 100%;
}

.service-sidebar .download-widget .content-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 17px 25px;
  background: #0c0c0c;
}

.service-sidebar .download-widget .content-box h4 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 6px;
}

.service-sidebar .download-widget .content-box h6 {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
  text-transform: uppercase;
}

.service-sidebar .download-widget .content-box button {
  position: relative;
  display: inline-block;
  width: 45px;
  height: 45px;
  line-height: 51px;
  background: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 20px;
  transition: all 500ms ease;
}

.service-sidebar .download-widget .content-box button:hover {
  color: #fff;
}

.service-sidebar .banner-widget {
  position: relative;
  display: block;
  background: #fff;
  border: 1px dashed #d8d2d0;
  border-radius: 25px;
  padding: 19px;
}

.service-sidebar .banner-widget .inner-box {
  position: relative;
  display: block;
  padding: 43px 30px 50px 30px;
  text-align: center;
  border-radius: 25px;
}

.service-sidebar .banner-widget .inner-box h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  color: #fff;
  font-weight: 800;
  margin-bottom: 30px;
}

.service-sidebar .banner-widget .inner-box .image-box {
  position: relative;
  display: inline-block;
  margin-bottom: 35px;
}

.service-sidebar .banner-widget .inner-box .image-box img {
  width: 100%;
}

.service-sidebar .banner-widget .inner-box .image-box .image {
  position: relative;
  display: block;
}

.service-sidebar .banner-widget .inner-box .image-box .shape {
  position: absolute;
  left: 5px;
  top: -35px;
  width: 266px;
  height: 207px;
  background-repeat: no-repeat;
}

.service-details-content {
  position: relative;
  display: block;
}

.service-details-content .content-one {
  position: relative;
  margin-bottom: 35px;
}

.service-details-content .content-one .image-box {
  position: relative;
  display: block;
  border-radius: 25px;
  margin-bottom: 40px;
}

.service-details-content .content-one .image-box img {
  width: 100%;
  border-radius: 25px;
}

.service-details-content h2 {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  /* margin-bottom: 22px; */
}

.service-details-content .content-one p {
  margin-bottom: 16px;
}

.service-details-content .content-one h5 {
  position: relative;
  display: block;
  font-size: 17px;
  line-height: 28px;
  font-weight: 700;
}

.service-details-content .content-two .single-item {
  position: relative;
  display: block;
  padding-left: 88px;
  margin-bottom: 36px;
}

.service-details-content .content-two .single-item .icon-box {
  position: absolute;
  display: inline-block;
  left: 0px;
  top: 1px;
  font-size: 42px;
  width: 58px;
  text-align: center;
}

.service-details-content .content-two .single-item .icon-box .overlay-icon {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  text-align: center;
  opacity: 0;
  transition: all 500ms ease;
}

.service-details-content .content-two .single-item:hover .icon-box .overlay-icon {
  opacity: 1;
}

.service-details-content .content-two .single-item .icon-box .icon {
  position: relative;
  transition: all 500ms ease;
}

.service-details-content .content-two .single-item:hover .icon-box .icon {
  opacity: 0;
}

.service-details-content .content-two .single-item .icon-box .shape {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 59px;
  height: 50px;
  background-repeat: no-repeat;
}

.service-details-content .content-two .single-item .inner-box {
  position: relative;
  padding-bottom: 22px;
  border-bottom: 1px solid #eee6e5;
  margin-bottom: 25px;
}

.service-details-content .content-two .single-item .inner-box h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  padding-bottom: 13px;
  margin-bottom: 21px;
}

.service-details-content .content-two .single-item .inner-box h3:before {
  position: absolute;
  content: '';
  border-bottom: 1px dashed #dcd5d4;
  width: 70px;
  height: 1px;
  left: 0px;
  bottom: 0px;
}

.service-details-content .content-two .single-item .link-box h6 {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
  text-transform: uppercase;
}

.service-details-content .content-two .single-item .link-box h6 a {
  display: inline-block;
  color: #0c0c0c;
  padding-left: 30px;
}

.service-details-content .content-two .single-item .link-box h6 a:hover {}

.service-details-content .content-two .single-item .link-box h6 a i {
  position: absolute;
  left: 0px;
  top: -1px;
  font-size: 24px;
}

.service-details-content .content-two {
  padding-bottom: 20px;
}

.service-details-content .content-three h2 {
  margin-bottom: 12px;
}

.service-details-content .content-three .single-item .image-box {
  position: relative;
  display: block;
  padding: 0px 20px;
  margin-top: 7px;
}

.service-details-content .content-three .single-item .image-box .image {
  border-radius: 25px;
}

.service-details-content .content-three .single-item .image-box img {
  width: 100%;
  border-radius: 25px;
}

.service-details-content .content-three .single-item .image-box .image-1 {
  position: relative;
  z-index: 1;
}

.service-details-content .content-three .single-item .image-box .image-2 {
  position: absolute;
  left: 23px;
  top: 0px;
  transform: rotate(8deg);
}

.service-details-content .content-three .owl-carousel .owl-stage-outer {
  overflow: visible;
}

.service-details-content .content-three .owl-carousel .owl-stage-outer .owl-item {
  opacity: 0;
}

.service-details-content .content-three .owl-carousel .owl-stage-outer .owl-item.active {
  opacity: 1;
}

.service-details-content .content-three .text-box {
  position: relative;
  margin-bottom: 44px;
}

.service-details-content .content-three .content-box {
  position: relative;
  display: block;
}

.service-details-content .content-three .content-box h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  padding-bottom: 13px;
  margin-bottom: 21px;
}

.service-details-content .content-three .content-box h3:before {
  position: absolute;
  content: '';
  border-bottom: 1px dashed #dcd5d4;
  width: 70px;
  height: 1px;
  left: 0px;
  bottom: 0px;
}

.service-details-content .content-three .content-box p {
  margin-bottom: 14px;
}

.service-details-content .content-three .content-box .list-item li {
  position: relative;
  display: block;
  font-size: 17px;
  color: #7b7675;
  padding-left: 32px;
  margin-bottom: 8px;
}

.service-details-content .content-three .content-box .list-item li:last-child {
  margin-bottom: 0px;
}

.service-details-content .content-three .content-box .list-item li:before {
  position: absolute;
  content: "\f136";
  font-family: 'flaticon';
  font-size: 24px;
  left: 0px;
  top: 2px;
}

.service-details-content .content-three .nav-style-one .owl-nav button {
  width: auto;
  height: auto;
  background: transparent;
  border-radius: 0px;
  margin: 0px 5px;
}

.service-details-content .content-three .nav-style-one .owl-nav button:hover {}

.service-details-content .content-three .nav-style-one .owl-nav {
  margin-left: 150px;
  margin-top: 9px;
}

.service-details-content .content-three {
  margin-bottom: 42px;
}

.service-details-content .content-four .text-box {
  margin-bottom: 32px;
}

.service-details-content .content-four .text-box h2 {
  margin-bottom: 12px;
}

.service-details-content .accordion-box .block {
  border: 1px dashed rgba(0, 0, 0, 0.18);
  margin-bottom: 20px;
  border-radius: 15px;
}

.service-details-content .accordion-box .block:last-child {
  margin-bottom: 0px;
}

.service-details-content .accordion-box .block .acc-btn {
  padding-left: 65px;
  padding-right: 30px;
}

.service-details-content .accordion-box .block .acc-content {
  border-top: 1px dashed rgba(0, 0, 0, 0.18);
  padding: 22px 30px 22px 30px;
}

.service-details-content .accordion-box .block .acc-btn .icon-box {
  right: inherit;
  left: 43px;
  top: 17px;
}

.service-details-content .accordion-box .block .acc-btn {
  padding-top: 15px;
}



/** shop-page-section **/

.shop-page-section {
  position: relative;
  padding: 120px 0px;
}

.wrapper.list .shop-list-content {
  display: block;
}

.wrapper.list .shop-grid-content {
  display: none
}

.wrapper.grid .shop-list-content {
  display: none;
}

.wrapper.grid .shop-grid-content {
  display: block
}

.shop-page-section .item-shorting {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
  border: 1px dashed #d8d2d0;
  border-radius: 15px;
  padding: 11px 20px;
}

.shop-page-section .item-shorting .menu-box {
  position: relative;
  display: flex;
  align-items: center;
}

.shop-page-section .item-shorting .menu-box button {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 42px;
  font-size: 18px;
  color: #0c0c0c;
  background: #faf1ef;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  margin: 0px 5px;
  transition: all 500ms ease;
}

.shop-page-section .item-shorting .menu-box button.on {
  color: #fff;
}

.shop-page-section .item-shorting .select-box {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 28px;
}

.shop-page-section .item-shorting .select-box .nice-select {
  font-size: 17px;
  color: #0c0c0c;
  padding-right: 33px;
}

.shop-page-section .item-shorting .select-box .nice-select:before {
  top: 2px;
  color: #7b7675;
}

.shop-page-section .item-shorting .select-box .nice-select .list {
  min-width: 130px;
  left: inherit !important;
}

.shop-page-section .shop-block-one .inner-box {
  margin-bottom: 30px;
}

.shop-page-section .pagination-wrapper {
  padding-top: 30px;
}


.shop-sidebar {
  position: relative;
  display: block;
}

.shop-sidebar .search-widget .form-group {
  position: relative;
  margin-bottom: 42px;
}

.shop-sidebar .search-widget .form-group input[type='search'] {
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  border: 1px dashed #d8d2d0;
  border-radius: 15px;
  padding: 10px 50px 10px 15px;
  font-size: 17px;
  color: #7b7675;
  transition: all 500ms ease;
}

.shop-sidebar .search-widget .form-group button[type='submit'] {
  position: absolute;
  top: 19px;
  right: 20px;
  font-size: 20px;
  color: #0c0c0c;
  cursor: pointer;
  transition: all 500ms ease;
}

.shop-sidebar .search-widget .form-group input:focus {}

.shop-sidebar .search-widget .form-group input:focus+button,
.shop-sidebar .search-widget .form-group button:hover {}

.shop-sidebar .widget-title {
  position: relative;
  display: block;
  margin-bottom: 15px;
  padding-left: 28px;
}

.shop-sidebar .widget-title:before {
  position: absolute;
  content: "\f112";
  font-family: 'flaticon';
  font-size: 18px;
  left: 0px;
  top: 4px;
}

.shop-sidebar .widget-title h3 {
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
}

.shop-sidebar .category-widget .category-list li {
  position: relative;
  display: block;
  padding: 12px 0px 7px 0px;
  border-bottom: 1px dashed #dcd5d4;
}

.shop-sidebar .category-widget .category-list li:last-child {
  border-bottom: none;
}

.shop-sidebar .category-widget .category-list li a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Outfit', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  color: #0c0c0c;
}

.shop-sidebar .category-widget .category-list li a:hover {}

.shop-sidebar .category-widget .category-list li a:hover span {}

.shop-sidebar .category-widget .category-list li a span {
  color: #afa7a7;
  transition: all 500ms ease;
}

.shop-sidebar .category-widget {
  margin-bottom: 30px;
}

.shop-sidebar .filter-widget .widget-title {
  margin-bottom: 37px;
}

.range-slider .title {
  position: relative;
}

.range-slider .title:before {
  position: absolute;
  content: '$';
  font-family: 'Outfit', sans-serif;
  left: 1px;
  top: 3px;
  font-size: 16px;
  line-height: 24px;
  color: #0c0c0c;
  font-weight: 700;
}

.range-slider .price-box h6 {
  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
}

.range-slider .input {
  color: #919191;
  max-width: 75px;
}

.range-slider .input input {
  background: none;
  font-size: 16px;
  line-height: 28px;
  font-family: 'Outfit', sans-serif;
  color: #0c0c0c;
  font-weight: 700;
  text-align: right;
  width: 67px;
}

.range-slider .ui-widget.ui-widget-content {
  height: 4px;
  border: none;
  margin-bottom: 19px;
  background: #eee6e5;
  border-radius: 2px;
}

.range-slider .ui-slider .ui-slider-range {
  top: 0px;
  height: 4px;
}

.range-slider .ui-widget-content .ui-state-default {
  top: -5px;
  width: 14px;
  height: 14px;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 0px;
}

.range-slider .ui-widget-content .ui-state-default:before {
  position: absolute;
  content: '';
  background: #fff;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  left: 4px;
  top: 4px;
}

.range-slider .theme-btn-one {
  padding: 10px 24px 6px 23px;
  box-shadow: none;
}

.range-slider .filter-content {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.range-slider .filter-content .price-box {
  position: relative;
  display: flex;
}

.range-slider .filter-content .filter-btn {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Outfit', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  padding-bottom: 0px;
}

.range-slider .filter-content .filter-btn:before {
  position: absolute;
  content: '';
  width: 20px;
  height: 2px;
  left: 0px;
  bottom: 0px;
}

.shop-sidebar .filter-widget {
  margin-bottom: 36px;
}

.shop-sidebar .post-widget .post {
  position: relative;
  display: block;
  padding-left: 90px;
  margin-bottom: 28px;
}

.shop-sidebar .post-widget .post:last-child {
  margin-bottom: 0px;
}

.shop-sidebar .post-widget .post .post-thumb {
  position: absolute;
  left: 0px;
  top: 5px;
  width: 70px;
  height: 70px;
  border-radius: 15px;
}

.shop-sidebar .post-widget .post .post-thumb img {
  width: 100%;
  border-radius: 15px;
}

.shop-sidebar .post-widget .post h4 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  margin-bottom: 11px;
}

.shop-sidebar .post-widget .post h4 a {
  display: inline-block;
  color: #0c0c0c;
}

.shop-sidebar .post-widget .post h4 a:hover {}

.shop-sidebar .post-widget .post h5 {
  position: relative;
  display: block;
  font-size: 18px;
  lighting-color: 24px;
  font-weight: 700;
}

.shop-sidebar .post-widget .widget-title {
  margin-bottom: 27px;
}

.shop-sidebar .post-widget {
  margin-bottom: 34px;
}

.shop-sidebar .tags-widget .tags-list li {
  position: relative;
  display: inline-block;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
}

.shop-sidebar .tags-widget .tags-list li a {
  position: relative;
  display: inline-block;
  font-size: 17px;
  line-height: 28px;
  font-family: 'Outfit', sans-serif;
  font-weight: 600;
  color: #7b7675;
  background: #faf1ef;
  border-radius: 5px;
  padding: 4px 15px;
  text-align: center;
}

.shop-sidebar .tags-widget .tags-list li a:hover {
  color: #fff;
}

.shop-sidebar .tags-widget .widget-title {
  margin-bottom: 32px;
}


/** shop-details **/

.shop-details {
  position: relative;
  padding: 120px 0px;
}

.shop-details-content {
  position: relative;
  display: block;
  margin-bottom: 104px;
}

.shop-details-content .products-image-block {
  position: relative;
  display: block;
  margin-right: 30px;
}

.shop-details-content .products-image-block .image-box {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.shop-details-content .products-image-block .image-box .image {
  position: relative;
  display: block;
  border-radius: 25px;
}

.shop-details-content .products-image-block .image-box .image img {
  width: 100%;
  border-radius: 25px;
}

.shop-details-content .products-image-block .image-box .view-btn {
  position: absolute;
  top: 30px;
  right: 30px;
}

.shop-details-content .products-image-block .image-box .view-btn a {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 52px;
  line-height: 58px;
  background: #fff;
  text-align: center;
  font-size: 20px;
  color: #0c0c0c;
  border-radius: 50%;
}

.shop-details-content .products-image-block .image-box .view-btn a:hover {
  color: #fff;
}

.shop-details-content .products-image-block .thumb-box {
  position: relative;
  display: flex;
  align-items: center;
}

.shop-details-content .products-image-block .thumb-box li {
  margin-right: 10px;
}

.shop-details-content .products-image-block .thumb-box li:last-child {
  margin: 0px !important;
}

.shop-details-content .products-image-block .thumb-box li a {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  background: #fff;
  border: 1px dashed #d8d2d0;
  border-radius: 15px;
  padding: 4px;
  z-index: 1;
}

.shop-details-content .products-image-block .thumb-box li a img {
  width: 100%;
  border-radius: 10px;
}

.shop-details-content .products-image-block .thumb-box li a.active {
  border-color: #fd6145;
}

.shop-details-content .content-box {
  position: relative;
  display: block;
  margin-top: -7px;
}

.shop-details-content .content-box .rating {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.shop-details-content .content-box .rating li {
  position: relative;
  display: inline-block;
  margin-right: 5px;
  font-size: 12px;
  color: #fb991a;
}

.shop-details-content .content-box .rating li:last-child {
  margin: 0px !important;
}

.shop-details-content .content-box h2 {
  position: relative;
  display: block;
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  margin-bottom: 21px;
}

.shop-details-content .content-box p {
  display: block;
  margin-bottom: 14px;
}

.shop-details-content .content-box .list-item li {
  position: relative;
  display: block;
  padding-left: 35px;
  margin-bottom: 8px;
}

.shop-details-content .content-box .list-item li:last-child {
  margin-bottom: 0px;
}

.shop-details-content .content-box .list-item li:before {
  position: absolute;
  content: "\f136";
  font-family: 'flaticon';
  font-size: 24px;
  left: 0px;
  top: 2px;
}

.shop-details-content .content-box .list-item {
  margin-bottom: 29px;
}

.shop-details-content .content-box h5 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 7px;
}

.shop-details-content .content-box .nice-select {
  height: 54px;
  line-height: 54px;
  border: 1px dashed #d8d2d0;
  border-radius: 15px;
  padding: 0px 20px;
  font-size: 17px;
  color: #7b7675;
}

.shop-details-content .content-box .nice-select:before {
  right: 18px;
}

.shop-details-content .content-box .single-item {
  position: relative;
  display: block;
  margin-bottom: 14px;
}

.shop-details-content .content-box .single-item .select-box {
  min-height: 54px;
}

.shop-details-content .content-box h3 {
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 33px;
  padding-top: 14px;
}

.shop-details-content .content-box h3 del {
  font-size: 17px;
  font-family: 'Outfit', sans-serif;
  color: #7b7675;
  font-weight: 400;
}

.shop-details-content .content-box .option-list {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 34px;
}

.shop-details-content .content-box .option-list li {
  margin-right: 10px;
}

.shop-details-content .content-box .option-list li:last-child {
  margin: 0px !important;
}

.shop-details-content .content-box .option-list li a {
  position: relative;
  display: inline-block;
  width: 52px;
  height: 52px;
  line-height: 56px;
  background: #faf1ef;
  text-align: center;
  font-size: 24px;
  color: #0c0c0c;
  border-radius: 50%;
}

.shop-details-content .content-box .option-list li a:hover {
  color: #fff;
}

.shop-details-content .content-box .option-list li button {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  padding: 14px 30px;
  border-radius: 35px;
}

.shop-details-content .content-box .social-links {
  position: relative;
  display: flex;
  align-items: center;
}

.shop-details-content .content-box .social-links li {
  margin-right: 10px;
}

.shop-details-content .content-box .social-links a {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 16px;
  border: 1px dashed #d8d2d0;
  border-radius: 50%;
  color: #0c0c0c;
  z-index: 1;
  text-align: center;
}

.shop-details-content .content-box .social-links a:hover {
  color: #fff;
}

.shop-details-content .content-box .social-links a:before {
  position: absolute;
  content: '';
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  left: -1px;
  top: -1px;
  transform: scale(0, 0);
  z-index: -1;
  border-radius: 50%;
  transition: all 500ms ease;
}

.shop-details-content .content-box .social-links a:hover:before {
  transform: scale(1, 1);
}

.shop-details-content .content-box .social-box h5 {
  margin-bottom: 22px;
}


.check-box input {
  display: none;
}

.check-box label {
  position: relative;
  font-size: 17px;
  color: #7b7675;
  padding-left: 26px;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 0px;
}

.check-box label:before {
  position: absolute;
  content: '';
  left: 0px;
  top: 7px;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  border-style: solid;
  border-width: 1px;
  border-color: #d8d2d0;
  background: transparent;
  transition: all 500ms ease;
}

.check-box label:after {
  position: absolute;
  content: "\f136";
  font-family: 'flaticon';
  left: 0px;
  top: 0px;
  width: 16px;
  height: 16px;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  opacity: 0;
  transition: all 500ms ease;
}

.check-box input:checked+label:before {
  background: #fd6145;
  border-color: #fd6145;
}

.check-box input:checked+label:after {
  opacity: 1;
}

.shop-details .product-discription .text-box p {
  position: relative;
  display: block;
  margin-bottom: 14px;
}

.shop-details .product-discription .text-box .list-item li {
  position: relative;
  display: block;
  padding-left: 150px;
  margin-bottom: 9px;
  font-size: 17px;
}

.shop-details .product-discription .text-box .list-item li:last-child {
  margin-bottom: 0px;
}

.shop-details .product-discription .text-box .list-item li h6 {
  position: absolute;
  left: 0px;
  top: 1px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 700;
}

.shop-details .product-discription .text-box .list-item li h6 span {
  position: relative;
  display: inline-block;
  width: 120px;
}

.shop-details .product-discription .text-box .list-item {
  padding-top: 10px;
}

.shop-details .product-discription .tab-btn-box {
  margin-bottom: 50px;
}

.shop-details .product-discription .tab-btn {
  position: relative;
  display: inline-block;
  width: 100%;
  font-size: 18px;
  line-height: 28px;
  font-family: 'Outfit', sans-serif;
  color: #0c0c0c;
  font-weight: 700;
  border: 1px dashed #d8d2d0;
  border-radius: 15px;
  cursor: pointer;
  padding: 15px 30px;
  cursor: pointer;
  transition: all 500ms ease;
}

.shop-details .product-discription .tab-btn.active-btn {
  color: #fff;
  background: #0c0c0c;
  border-color: #0c0c0c;
}

.shop-details .product-discription .customer-comment .comment {
  position: relative;
  display: block;
  padding: 0px 0px 33px 100px;
  border-bottom: 1px dashed #dcd5d4;
  margin-bottom: 35px;
}

.shop-details .product-discription .customer-comment .comment:last-child {
  margin-bottom: 0px;
  border-bottom: none;
}

.shop-details .product-discription .customer-comment .comment .thumb-box {
  position: absolute;
  left: 0px;
  top: 6px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.shop-details .product-discription .customer-comment .comment .thumb-box img {
  width: 100%;
  border-radius: 50%;
}

.shop-details .product-discription .customer-comment .comment h5 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

.shop-details .product-discription .customer-comment .comment h5 span {
  font-size: 16px;
  color: #afa7a7;
}

.shop-details .product-discription .customer-comment .comment .rating {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.shop-details .product-discription .customer-comment .comment .rating li {
  position: relative;
  display: inline-block;
  font-size: 12px;
  color: #fb991a;
  margin-right: 5px;
}

.shop-details .product-discription .customer-comment .comment .rating li:last-child {
  margin: 0px !important;
}

.shop-details .product-discription .customer-comment {
  margin-bottom: 59px;
}

.shop-details .product-discription .comment-writing-box {
  position: relative;
  display: block;
  background: #faf1ef;
  padding: 43px 50px 50px 50px;
  border-radius: 25px;
}

.shop-details .product-discription .comment-writing-box .title-box {
  margin-bottom: 9px;
}

.shop-details .product-discription .comment-writing-box .title-box h2 {
  display: block;
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 7px;
}

.shop-details .product-discription .comment-writing-box .title-box p {
  margin-bottom: 20px;
}

.shop-details .product-discription .comment-writing-box .title-box .rating {
  position: relative;
  display: flex;
  align-items: center;
}

.shop-details .product-discription .comment-writing-box .title-box .rating li {
  font-size: 12px;
  line-height: 28px;
  color: #afa7a7;
  margin-right: 5px;
}

.shop-details .product-discription .comment-writing-box .title-box .rating li:last-child {
  margin: 0px !important;
}

.shop-details .product-discription .comment-writing-box .title-box .rating li p {
  font-size: 17px;
}

.shop-details .product-discription .comment-writing-box .title-box .rating li i {
  position: relative;
  top: -2px;
}

.shop-details .product-discription .comment-writing-box .form-group {
  position: relative;
  display: block;
  margin-bottom: 25px;
}

.shop-details .product-discription .comment-writing-box .form-group input[type='text'],
.shop-details .product-discription .comment-writing-box .form-group input[type='email'],
.shop-details .product-discription .comment-writing-box .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  background: #fff;
  height: 60px;
  font-size: 17px;
  color: #7b7675;
  padding: 10px 20px;
  border: 1px dashed #fff;
  border-radius: 15px;
  transition: all 500ms ease;
}

.shop-details .product-discription .comment-writing-box .form-group input:focus,
.shop-details .product-discription .comment-writing-box .form-group textarea:focus {}

.shop-details .product-discription .comment-writing-box .form-group textarea {
  height: 120px;
  resize: none;
  padding-top: 15px;
}

.shop-details .product-discription {
  margin-bottom: 113px;
}

.shop-details .related-product .title-text {
  position: relative;
  display: block;
  margin-bottom: 40px;
}

.shop-details .related-product .title-text h2 {
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
}



/** cart-section **/

.cart-section {
  position: relative;
  padding: 115px 0px 120px 0px;
}

.cart-outer {
  position: relative;
}

.cart-outer .table-outer {
  position: relative;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 40px;
}

.cart-outer .cart-table {
  width: 100%;
  min-width: 900px;
  border-bottom: 1px solid #eee6e5;
}

.cart-outer .cart-table tbody {
  padding-bottom: 30px;
}

.cart-table .cart-header {
  position: relative;
  width: 100%;
  border: 1px solid #eee6e5;
}

.cart-table thead tr th {
  line-height: 30px;
  padding: 21px 28px 19px 40px;
  font-weight: 700;
  font-size: 18px;
  font-family: 'Outfit', sans-serif;
  color: #0c0c0c;
  border-right: 1px solid #eee6e5;
  text-align: center;
  background: #faf1ef;
}

.cart-table thead tr th .fa {
  font-size: 18px;
}

.cart-table tbody tr td {
  padding: 30px 40px;
}

.cart-table tbody tr .qty .item-quantity {
  border: 1px solid #eee6e5;
}

.cart-table tbody tr .qty .quantity-spinner {
  background: #ffffff;
}

.cart-table tbody tr .prod-column .column-box {
  position: relative;
  min-height: 80px;
  padding-top: 23px;
  padding-left: 135px;
}

.cart-table tbody tr .prod-column .column-box .prod-thumb {
  position: absolute;
  left: -40px;
  top: -30px;
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e2e2e2;
}

.cart-table tbody tr .prod-column .column-box .prod-thumb img {
  display: block;
  max-width: 100%;
}

.cart-table tbody tr .prod-column .column-box h4 {
  position: relative;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
  text-align: left;
  max-width: 200px;
}

.cart-table tbody tr .sub-total {
  font-size: 18px;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
  color: #0c0c0c;
}

.cart-table tbody tr .remove-btn {
  position: relative;
  display: inline-block;
  font-size: 14px;
  color: #afa7a7;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  transition: all 500ms ease;
}

.cart-table tbody tr .remove-btn:hover {}

.cart-table tbody tr td {
  vertical-align: middle;
  border-left: 1px solid #e3e3e3;
  border-bottom: 1px solid #e3e3e3;
  text-align: center;
}

.cart-table tbody tr .total-price {
  font-size: 18px;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
}

.cart-table tbody tr td:last-child {
  border-right: 1px solid #ebebeb;
}

.cart-section .cart-outer .totle-table {
  position: relative;
  margin-bottom: 12px;
}

.cart-section .cart-outer .totle-table .total p {
  color: #1c1c1c;
}

.cart-section .cart-outer .totle-table .total p span {}

.cart-table tbody tr td.qty input {
  width: 50px;
  height: 50px;
  background: #faf1ef;
  text-align: center;
  padding: 10px 10px;
  font-size: 18px;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
  color: #0c0c0c;
  border-radius: 50%;
}

.cart-section .apply-coupon .form-group {
  position: relative;
  float: left;
  margin-right: 20px;
  margin-bottom: 0px;
}

.cart-section .apply-coupon .form-group:last-child {
  margin-right: 0px;
}

.cart-section .apply-coupon .form-group input[type="text"] {
  position: relative;
  display: block;
  border: 1px dashed #d8d2d0;
  width: 250px;
  height: 54px;
  font-size: 18px;
  font-weight: 400;
  color: #827e7d;
  padding: 10px 20px;
  background: #ffffff;
  border-radius: 30px;
  transition: all 500ms ease;
}

.cart-section .apply-coupon .form-group input:focus {}

.cart-section .apply-coupon .form-group button {}

.cart-section .apply-coupon .form-group button:hover {
  color: #fff;
}

.cart-section .btn-box button {
  float: left;
  margin-right: 20px;
}

.cart-section .btn-box button:last-child {
  margin-right: 0px;
}


/** checkout **/

.checkout-page-section {
  position: relative;
  padding: 120px 0px;
}

.checkout-page-section .information-inner {
  position: relative;
  display: block;
  background: #fff;
  padding: 53px 60px 5px 60px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
  border-radius: 25px;
}

.checkout-page-section h3 {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 30px;
}

.checkout-page-section .information-inner .field-input {
  position: relative;
  display: block;
  margin-bottom: 20px;
  min-height: 60px;
}

.checkout-page-section .information-inner .field-input input[type='text'],
.checkout-page-section .information-inner .field-input input[type='email'] {
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  background: #faf1ef;
  border: 1px dashed #faf1ef;
  font-size: 17px;
  color: #7b7675;
  padding: 10px 20px;
  border-radius: 15px;
  transition: all 500ms ease;
}

.checkout-page-section .information-inner .field-input input:focus {}

.checkout-page-section .check-box label {
  color: #afa7a7;
}

.checkout-page-section .information-inner .field-input .nice-select {
  position: relative;
  border: 1px solid #faf1ef !important;
  color: #7b7675;
  background: #faf1ef;
  height: 60px;
  line-height: 60px;
  border-radius: 15px;
  padding: 0px 20px;
  font-size: 17px;
  text-transform: capitalize;
}

.checkout-page-section .information-inner .field-input .nice-select:before {
  right: 15px;
}

.checkout-page-section .information-inner .custom-control.material-checkbox .material-control-indicator {
  top: 7px;
}

.checkout-page-section .information-inner .description {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.checkout-page-section .information-inner .contact-information {
  position: relative;
  padding-bottom: 22px;
}

.checkout-page-section .information-inner .column:last-child {
  min-height: auto;
}

.checkout-page-section .order-summary {
  position: relative;
  display: block;
  border: 1px dashed #d8d2d0;
  padding: 19px 30px 40px 30px;
  border-radius: 25px;
}

.checkout-page-section .order-information {
  position: relative;
  margin-bottom: 94px;
}

.checkout-page-section .accordion .payment-info .field-input {
  position: relative;
  margin-bottom: 20px;
}

.checkout-page-section .accordion .payment-info .field-input input[type='text'] {
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  border: 1px dashed #fff;
  background: #fff;
  font-size: 17px;
  color: #7b7675;
  padding: 10px 20px;
  border-radius: 15px;
  transition: all 500ms ease;
}

.checkout-page-section .accordion .payment-info .field-input input:focus {}

.checkout-page-section .accordion .payment-info .field-input .theme-btn {
  width: 100%;
  padding: 17px 30px;
}

.checkout-page-section .accordion-box .block .acc-content {
  padding: 0px 30px 30px 30px;
}

.checkout-page-section .accordion-box .block .acc-content .payment-info {
  position: relative;
  display: block;
  background-color: #faf1ef;
  padding: 30px 30px 10px 30px;
  border-radius: 15px;
}

.checkout-page-section .accordion-box .block {
  margin-bottom: 20px;
  border: 1px dashed #d8d2d0;
  box-shadow: none !important;
  border-radius: 15px;
}

.checkout-page-section .accordion-box .block:last-child {
  margin-bottom: 0px;
}

.checkout-page-section .payment-method h3 {
  margin-bottom: 32px;
}

.checkout-page-section .accordion-box h4 {
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

.checkout-page-section .accordion-box .block .acc-btn {
  padding: 17px 30px 18px 30px;
  border: none;
  background: transparent !important;
}

.checkout-page-section .accordion-box .block .acc-btn .icon-outer {
  position: absolute;
  top: 21px;
  right: 27px;
  opacity: 1 !important;
  color: #8e8a89;
  font-size: 20px;
}

.checkout-page-section .accordion-box .block .acc-btn.active .icon-outer {
  transform: rotate(45deg);
}

.checkout-page-section .order-summary .inner-box .single-item {
  position: relative;
  display: block;
  padding: 13px 0px 17px 0px;
  border-bottom: 1px dashed #d8d2d0;
  margin-bottom: 6px;
}

.checkout-page-section .order-summary .inner-box .single-item:last-child {
  margin-bottom: 0px;
}

.checkout-page-section .order-summary .inner-box .single-item h4 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 5px;
}

.checkout-page-section .order-summary .inner-box .single-item h5 {
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

.checkout-page-section .order-summary .total-box {
  position: relative;
  display: block;
  padding: 19px 0px;
  border-bottom: 1px dashed #d8d2d0;
}

.checkout-page-section .order-summary .total-box li {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
  font-family: 'Outfit', sans-serif;
  font-weight: 600;
  color: #0c0c0c;
  margin-bottom: 6px;
}

.checkout-page-section .order-summary .total-box li:last-child {
  margin-bottom: 0px;
}

.checkout-page-section .order-summary .total-box li span {
  float: right;
  color: #7b7675;
  font-weight: 500;
}

.checkout-page-section .order-summary .tax-box {
  position: relative;
  display: block;
  padding: 20px 0px 21px 0px;
  margin-bottom: 13px;
}

.checkout-page-section .order-summary .tax-box h5 span {
  float: right;
}

.checkout-page-section .order-summary .theme-btn {
  padding: 14px 40px;
}

.checkout-page-section .order-summary .tax-box h5 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}



/** myaccount-section **/

.myaccount-section {
  position: relative;
  padding: 111px 0px 120px 0px;
}

.myaccount-section h2 {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 31px;
}

.myaccount-section .login-content .form-inner {
  position: relative;
  display: block;
  background: #faf1ef;
  padding: 32px 40px 39px 40px;
  border-radius: 25px;
}

#quebak {
  background-color: #FEF4F0;
}

.myaccount-section .form-inner label {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
  color: #0c0c0c;
  margin-bottom: 13px;
}

.myaccount-section .form-inner .form-group {
  position: relative;
  margin-bottom: 23px;
}

.myaccount-section .form-inner input[type='text'],
.myaccount-section .form-inner input[type='email'],
.myaccount-section .form-inner input[type='password'] {
  position: relative;
  display: block;
  width: 100%;
  height: 54px;
  background: #fff;
  border: 1px dashed #fff;
  font-size: 18px;
  color: #7b7675;
  padding: 10px 20px;
  border-radius: 15px;
  transition: all 500ms ease;
}

.myaccount-section .form-inner input:focus {}

.myaccount-section .form-inner .check-box label {
  position: relative;
  font-size: 17px;
  font-family: 'Outfit', sans-serif;
  color: #7b7675;
  padding-left: 26px;
  display: inline-block;
  cursor: pointer;
  font-weight: 400;
  margin-bottom: 0px;
}

.myaccount-section .form-inner .theme-btn {
  width: 100%;
  padding: 15px 30px;
}

.myaccount-section .form-inner .form-group.message-btn {
  margin-bottom: 17px;
}

.myaccount-section .login-content .form-inner .form-group:last-child {
  margin-bottom: 0px;
}

.myaccount-section .login-content .form-inner .forgot-password button {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  font-family: 'Outfit', sans-serif;
  color: #0c0c0c;
  transition: all 500ms ease;
}

.myaccount-section .login-content .form-inner .forgot-password button:hover {}

.myaccount-section .custom-control.material-checkbox .material-control-indicator {
  background: #fff;
}


.myaccount-section .custom-controls-stacked .description {
  color: #827e7d;
  font-family: 'Manrope', sans-serif;
  font-weight: 400;
}

.myaccount-section .login-content .custom-control.material-checkbox .material-control-indicator {
  border: 1px solid #e2e2e2;
}

.myaccount-section .register-content .form-inner {
  position: relative;
  display: block;
  border: 1px dashed #d8d2d0;
  padding: 32px 40px 12px 40px;
  background: #fff;
  border-radius: 25px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
}

.myaccount-section .register-content .form-inner .message-btn,
.myaccount-section .register-content .custom-controls-stacked {
  padding-top: 10px;
}

.myaccount-section .register-content .form-inner input {
  background: #faf1ef;
  border-color: #faf1ef;
}

.myaccount-section .register-content .custom-control.material-checkbox .material-control-indicator {
  top: 7px;
}



/** blog-grid **/

.blog-grid {
  padding: 0px 0px;
}

.blog-grid .news-block-one .inner-box {
  margin-bottom: 30px;
}

.blog-grid .pagination-wrapper {
  padding-top: 30px;
}


.sidebar-page-container {
  position: relative;
  padding: 20px 0px;
}

.news-block-one .inner-box .lower-content h2 {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 12px;
}

.news-block-one .inner-box .lower-content h2 a {
  display: inline-block;
  color: #0c0c0c;
}

.news-block-one .inner-box .lower-content h2 a:hover {}

.news-block-one .inner-box .lower-content p {
  position: relative;
  display: block;
  margin-bottom: 32px;
}

.sidebar-page-container .news-block-one .inner-box .lower-content {
  padding-top: 33px;
}

.sidebar-page-container .news-block-one .inner-box {
  margin-bottom: 50px;
}


.blog-sidebar {
  position: relative;
  background: #fff;
  padding: 30px;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.12);
  border-radius: 15px;
}

.blog-sidebar .widget-title,
.widget .wp-block-search__label,
.widget h2 {
  position: relative;
  display: block;
  padding-left: 28px;
  margin-bottom: 23px;
}

.blog-sidebar .widget-title:before,
.widget .wp-block-search__label:before,
.widget h2:before {
  position: absolute;
  content: "\f112";
  font-family: 'flaticon';
  font-size: 18px;
  left: 0px;
  top: 1px;
  color: #D73439;
}

.blog-sidebar .widget-title h3,
.widget .wp-block-search__label,
.widget h2 {
  display: block;
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
  font-family: 'Outfit', sans-serif;
  color: #0c0c0c;
}

.search-widget .form-group,
.wp-block-search__inside-wrapper {
  position: relative;
  margin-bottom: 0px;
}

.search-widget .form-group input[type='search'],
.wp-block-search__inside-wrapper input {
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  border: 1px dashed #d8d2d0;
  border-radius: 35px;
  font-size: 17px;
  color: #7b7675;
  padding: 10px 70px 10px 30px;
  transition: all 500ms ease;
}

.wp-block-search__inside-wrapper input {
  padding-right: 100px;
}

.search-widget .form-group button[type='submit'],
.wp-block-search__inside-wrapper button {
  position: absolute;
  display: inline-block;
  min-width: 44px;
  height: 44px;
  line-height: 50px;
  font-size: 20px;
  color: #fff;
  background: #0c0c0c;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  top: 8px;
  right: 8px;
  transition: all 500ms ease;
}

.wp-block-search__inside-wrapper button {
  padding: 0 12px;
  border-radius: 35px;
  line-height: 44px;
}

.blog-sidebar .category-widget .widget-title {
  margin-bottom: 15px;
}

.blog-sidebar .category-widget .category-list li {
  position: relative;
  display: block;
  padding: 8px 0px 11px 0px;
  border-bottom: 1px dashed #dcd5d4;
}

.blog-sidebar .category-widget .category-list li:last-child {
  border-bottom: none;
}

.blog-sidebar .category-widget .category-list li a {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Outfit', sans-serif;
  font-weight: 800;
  text-transform: uppercase;
  color: #0c0c0c;
}

.blog-sidebar .category-widget .category-list li a:hover {}

.blog-sidebar .category-widget .category-list li a:hover span {}

.blog-sidebar .category-widget .category-list li a span {
  color: #afa7a7;
  transition: all 500ms ease;
}

.blog-sidebar .category-widget {
  margin-bottom: 38px;
}

.sidebar-page-container .news-block-one .inner-box {
  padding: 0px 19px;
}

.blog-sidebar .post-widget .post {
  position: relative;
  display: block;
  padding-left: 100px;
  margin-bottom: 26px;
  min-height: 87px;
}

.blog-sidebar .post-widget .post:last-child {
  margin-bottom: 0px;
}

.blog-sidebar .post-widget .post .post-thumb {
  position: absolute;
  left: 0px;
  top: 5px;
  width: 80px;
  height: 80px;
  border-radius: 15px;
}

.blog-sidebar .post-widget .post .post-thumb img {
  width: 100%;
  border-radius: 15px;
}

.blog-sidebar .post-widget .post h6 {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  color: #7b7675;
  padding-left: 21px;
  margin-bottom: 7px;
}

.blog-sidebar .post-widget .post h6:before {
  position: absolute;
  content: "\f117";
  font-family: 'flaticon';
  font-size: 30px;
  left: -10px;
  top: -1px;
  font-weight: 400;
}

.blog-sidebar .post-widget .post h5 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

.blog-sidebar .post-widget .post h5 a {
  display: inline-block;
  color: #0c0c0c;
}

.blog-sidebar .post-widget .post h5 a:hover {}

.blog-sidebar .post-widget .widget-title {
  margin-bottom: 28px;
}

.blog-sidebar .tags-widget .tags-list li {
  position: relative;
  display: inline-block;
  float: left;
  margin-right: 5px;
  margin-bottom: 5px;
}

.blog-sidebar .tags-widget .tags-list li a {
  position: relative;
  display: inline-block;
  font-size: 17px;
  line-height: 28px;
  font-family: 'Outfit', sans-serif;
  font-weight: 600;
  color: #7b7675;
  background: #faf1ef;
  border-radius: 5px;
  padding: 4px 15px;
  text-align: center;
}

.blog-sidebar .tags-widget .tags-list li a:hover {
  color: #fff;
}

.blog-sidebar .tags-widget .widget-title {
  margin-bottom: 33px;
}

.blog-sidebar .tags-widget {
  margin-bottom: 55px;
}

.blog-sidebar .banner-widget {
  position: relative;
  display: block;
}

.blog-sidebar .banner-widget .inner-box {
  position: relative;
  display: block;
  padding: 43px 30px 50px 30px;
  text-align: center;
  border-radius: 15px;
  background: -webkit-linear-gradient(90deg, #faeddc, #eef9ec 100%);
}

.blog-sidebar .banner-widget .inner-box h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 800;
  margin-bottom: 61px;
}

.blog-sidebar .banner-widget .inner-box .image-box {
  position: relative;
  display: inline-block;
  margin-bottom: 35px;
}

.blog-sidebar .banner-widget .inner-box .image-box img {
  width: 100%;
}

.blog-sidebar .banner-widget .inner-box .image-box .image {
  position: relative;
  display: block;
}

.blog-sidebar .banner-widget .inner-box .image-box .shape {
  position: absolute;
  left: 5px;
  top: -35px;
  width: 226px;
  height: 207px;
  background-repeat: no-repeat;
}

.sidebar-page-container .news-block-two .inner-box {
  margin-bottom: 40px;
}

.sidebar-page-container .pagination-wrapper {
  padding-top: 20px;
}


.page-title.style-two h1 {
  margin-bottom: 0px;
}

.page-title.style-two .image-content {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -26px;
  width: calc(100% - 20px);
  background: #fff;
  padding: 10px;
  border-radius: 30px;
  padding-right: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.12);
  z-index: 1;
}

.page-title.style-two .image-content .post-info {
  position: relative;
  display: flex;
  align-items: center;
}

.page-title.style-two .image-content h6 {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  padding: 4px 15px;
  border-radius: 30px;
  margin-right: 10px;
}

.page-title.style-two .image-content .post-info span {
  position: relative;
  display: block;
  padding-left: 21px;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Outfit', sans-serif;
  color: #7b7675;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 10px;
}

.page-title.style-two .image-content .post-info span:last-child {
  margin: 0px !important;
}

.page-title.style-two .image-content .post-info span i {
  position: absolute;
  left: -10px;
  top: -4px;
  font-size: 30px;
  font-weight: 400;
}

.page-title.style-two .image-content .post-info span a {
  display: inline-block;
  color: #7b7675;
}

.page-title.style-two .image-content .post-info span a:hover {}

.page-title.style-two .image-content .share-box {
  position: absolute;
  top: 10px;
  right: 10px;
}

.page-title.style-two .image-content .share-box .share-icon a {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 36px;
  font-size: 14px;
  background: #faf1ef;
  color: #0c0c0c;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
  transition: all 500ms ease;
}

.page-title.style-two .image-content .share-box .share-icon a:hover {
  color: #fff;
}

.blog-details-content {
  position: relative;
  display: block;
}

.blog-details-content .content-one .upper-text {
  position: relative;
  display: block;
  margin-bottom: 42px;
}

.blog-details-content .content-one .upper-text p {
  position: relative;
  display: block;
}

.blog-details-content .content-one .upper-text p:first-child {
  padding-left: 55px;
}

.blog-details-content .content-one .upper-text p span {
  position: absolute;
  left: 0px;
  top: 8px;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 30px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-weight: 700;
  text-align: center;
  border-radius: 3px;
  text-transform: uppercase;
}

.blog-details-content .content-one .upper-text h4 {
  display: block;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  margin-top: 25px;
}

.blog-details-content .content-one .image-box {
  position: relative;
  display: block;
  border-radius: 25px;
  margin-bottom: 42px;
}

.blog-details-content .content-one .image-box img {
  width: 100%;
  border-radius: 25px;
}

.blog-details-content h2 {
  position: relative;
  display: block;
  font-size: 30px;
  line-height: 40px;
  font-weight: 700;
  margin-bottom: 22px;
}

.blog-details-content .content-one {
  margin-bottom: 35px;
}

.blog-details-content .content-two .list-item li {
  position: relative;
  display: block;
  font-size: 17px;
  line-height: 28px;
  color: #0c0c0c;
  padding-left: 42px;
  margin-bottom: 12px;
}

.blog-details-content .content-two .list-item li:last-child {
  margin-bottom: 0px !important;
}

.blog-details-content .content-two .list-item li:before {
  position: absolute;
  content: "\f126";
  font-family: 'flaticon';
  font-size: 24px;
  left: 0px;
  top: 0px;
}

.blog-details-content .content-two .list-item {
  position: relative;
  margin-top: 27px;
  margin-bottom: 31px;
}

.blog-details-content .content-two .image-box {
  position: relative;
  display: block;
  border-radius: 25px;
  margin-top: 42px;
}

.blog-details-content .content-two .image-box img {
  width: 100%;
  border-radius: 25px;
}

.blog-details-content .content-two {
  margin-bottom: 43px;
}

.blog-details-content .content-three blockquote {
  position: relative;
  display: block;
  border-radius: 25px;
  padding: 30px 80px 31px 130px;
  margin: 42px 0px 44px 0px;
  background: -webkit-linear-gradient(90deg, #faf1ef, #ffffff 50%);
}

.blog-details-content .content-three blockquote .icon-box {
  position: absolute;
  left: 40px;
  top: -2px;
  width: 60px;
  height: 55px;
  line-height: 55px;
  text-align: center;
}

.blog-details-content .content-three blockquote .icon-box:before {
  position: absolute;
  content: '';
  width: 30px;
  height: 15px;
  left: 0px;
  bottom: -14px;
  clip-path: polygon(0% 0%, 100% 0%, 0% 100%, 0% 100%, 0% 0%);
}

.blog-details-content .content-three blockquote .icon-box:after {
  position: absolute;
  content: '';
  width: 30px;
  height: 15px;
  right: 0px;
  bottom: -14px;
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 100% 100%, 0% 0%);
}

.blog-details-content .content-three blockquote h4 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 14px;
}

.blog-details-content .content-three blockquote h6 {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  color: #7b7675;
  font-weight: 700;
  text-transform: uppercase;
  padding-left: 20px;
}

.blog-details-content .content-three blockquote h6:before {
  position: absolute;
  content: "\f117";
  font-family: 'flaticon';
  font-size: 30px;
  left: -10px;
  top: -1px;
  font-weight: 400;
}

.blog-details-content .content-three .inner-box .single-item {
  position: relative;
  display: block;
  margin-bottom: 26px;
  padding-left: 45px;
}

.blog-details-content .content-three .inner-box .single-item:last-child {
  margin-bottom: 0px;
}

.blog-details-content .content-three .inner-box .single-item:before {
  position: absolute;
  content: "\f119";
  font-family: 'flaticon';
  font-size: 24px;
  left: 0px;
  top: 4px;
}

.blog-details-content .content-three .inner-box .single-item p {
  position: relative;
  display: block;
}

.blog-details-content .content-three .inner-box .single-item p span {
  position: relative;
  display: inline-block;
  font-size: 20px;
  line-height: 28px;
  font-family: 'Outfit', sans-serif;
  color: #0c0c0c;
  font-weight: 700;
}

.blog-details-content .post-tags-option h5 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  padding-left: 24px;
  margin-bottom: 16px;
}

.blog-details-content .post-tags-option h5 img {
  position: absolute;
  left: 0px;
  top: 8px;
}

.blog-details-content .post-tags-option .tags-list li {
  position: relative;
  display: inline-block;
  float: left;
  margin-right: 5px;
}

.blog-details-content .post-tags-option .tags-list li:last-child {
  margin: 0px !important;
}

.blog-details-content .post-tags-option .tags-list li a {
  position: relative;
  display: inline-block;
  font-size: 17px;
  line-height: 28px;
  font-family: 'Outfit', sans-serif;
  font-weight: 600;
  color: #7b7675;
  background: #faf1ef;
  border-radius: 5px;
  padding: 4px 15px;
  text-align: center;
}

.blog-details-content .post-tags-option .tags-list li a:hover {
  color: #fff;
}

.blog-details-content .post-tags-option {
  position: relative;
  margin-top: 60px;
}

.blog-details-content .author-box {
  position: relative;
  display: block;
  border: 1px dashed #d8d2d0;
  border-radius: 25px;
  padding: 34px 40px 32px 200px;
  margin-top: 60px;
}

.blog-details-content .author-box .author-thumb {
  position: absolute;
  left: 44px;
  top: 45px;
  width: 120px;
  height: 120px;
  background: #fff;
  padding: 4px;
  border-radius: 50%;
}

.blog-details-content .author-box .author-thumb img {
  width: 100%;
  border-radius: 50%;
}

.blog-details-content .author-box .author-thumb:before {
  position: absolute;
  content: '';
  width: 130px;
  height: 130px;
  left: -5px;
  top: -5px;
  border-radius: 50%;
  z-index: -1;
}

.blog-details-content .author-box h6 {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.blog-details-content .author-box h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  margin-bottom: 13px;
}

.blog-details-content .author-box p {
  display: block;
  margin-bottom: 17px;
}

.blog-details-content .author-box h5 {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
}

.blog-details-content .author-box h5 a {
  display: inline-block;
  color: #0c0c0c;
  padding-left: 30px;
}

.blog-details-content .author-box h5 a i {
  position: absolute;
  left: 0px;
  top: -1px;
  font-size: 24px;
  font-weight: 400;
}

.blog-details-content .author-box h5 a:hover {}

.blog-details-content .author-box .social-links {
  position: absolute;
  top: 40px;
  right: 40px;
  display: flex;
  align-items: center;
}

.blog-details-content .author-box .social-links li {
  margin-right: 10px;
}

.blog-details-content .author-box .social-links li:last-child {
  margin: 0px !important;
}

.blog-details-content .author-box .social-links li a {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border: 1px dashed #d8d2d0;
  text-align: center;
  border-radius: 50%;
  font-size: 16px;
  color: #0c0c0c;
  z-index: 1;
}

.blog-details-content .author-box .social-links li a:hover {
  color: #fff;
}

.blog-details-content .author-box .social-links li a:before {
  position: absolute;
  content: '';
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  left: -1px;
  top: -1px;
  border-radius: 50%;
  transform: scale(0, 0);
  z-index: -1;
  transition: all 500ms ease;
}

.blog-details-content .author-box .social-links li a:hover:before {
  transform: scale(1, 1);
}

.blog-details-content .post-nav-btn {
  position: relative;
  display: block;
  margin-top: 60px;
}

.blog-details-content .post-nav-btn .post-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.blog-details-content .post-nav-btn .single-btn {
  position: relative;
  display: block;
}

.blog-details-content .post-nav-btn .single-btn h6 {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 7px;
}

.blog-details-content .post-nav-btn .single-btn h6 a {
  position: relative;
  display: inline-block;
  color: #0c0c0c;
}

.blog-details-content .post-nav-btn .single-btn h6 a:hover {}

.blog-details-content .post-nav-btn .single-btn h6 a i {
  position: absolute;
  display: inline-block;
  font-size: 24px;
  font-weight: 400;
}

.blog-details-content .post-nav-btn .single-btn.prev-btn h6 a {
  padding-left: 30px;
}

.blog-details-content .post-nav-btn .single-btn.prev-btn h6 a i {
  left: 0px;
  top: -5px;
  transform: rotate(180deg);
}

.blog-details-content .post-nav-btn .single-btn.next-btn h6 a {
  padding-right: 30px;
}

.blog-details-content .post-nav-btn .single-btn.next-btn h6 a i {
  right: 0px;
  top: 0px;
}

.blog-details-content .post-nav-btn .single-btn.next-btn {
  text-align: right;
}

.blog-details-content .post-nav-btn .single-btn h5 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 0px;
}

.blog-details-content .post-nav-btn .single-btn h5 a {
  display: inline-block;
  color: #0c0c0c;
}

.blog-details-content .post-nav-btn .single-btn h5 a:hover {}

.blog-details-content .post-nav-btn .btn-box a {
  position: relative;
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Outfit', sans-serif;
  color: #0c0c0c;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  background: #fff;
  padding: 15px 30px;
  border-radius: 5px;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.10);
}

.blog-details-content .post-nav-btn .btn-box a span {
  position: relative;
  padding-left: 30px;
}

.blog-details-content .post-nav-btn .btn-box a span:before {
  position: absolute;
  content: "\f108";
  font-family: 'flaticon';
  font-size: 20px;
  left: 0px;
  top: -4px;
  transition: all 500ms ease;
}

.blog-details-content .post-nav-btn .btn-box a:hover {
  color: #fff;
}

.blog-details-content .post-nav-btn .btn-box a:hover span:before {
  color: #fff;
}

.blog-details-content .comment-box {
  margin-top: 60px;
}

.blog-details-content .comment-box .comment {
  position: relative;
  display: flex;
  gap: 40px;
  margin-bottom: 30px;
}

.blog-details-content .comment-box>.printpark-comment-item:last-child .comment {
  margin-bottom: 0px;
}

.blog-details-content .comment-box .comment .thumb-box {
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.blog-details-content .comment-box .te-comment-wrap {
  width: calc(100% - 120px);
}

.blog-details-content .comment-box .comment .thumb-box img {
  width: 100%;
  border-radius: 50%;
}

.blog-details-content .comment-box .comment h4 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  margin-bottom: 8px;
}

.blog-details-content .comment-box .comment h4 span {
  font-family: 'Outfit', sans-serif;
  font-size: 17px;
  color: #afa7a7;
  font-weight: 400;
}

.blog-details-content .comment-box .comment .reply-btn {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Outfit', sans-serif;
  color: #0c0c0c;
  font-weight: 800;
  text-transform: uppercase;
  gap: 15px;
  margin-top: 15px;
}

.blog-details-content .comment-box .comment .reply-btn:hover {}

.blog-details-content .comment-box .comment .reply-btn img {
  position: absolute;
  left: 0px;
  top: 4px;
}

.blog-details-content .comment-form-area {
  position: relative;
  display: block;
  background: #faf1ef;
  padding: 43px 50px 50px 50px;
  border-radius: 25px;
  margin-top: 60px;
}

.blog-details-content .comment-form-area h2 {
  margin-bottom: 7px;
}

.blog-details-content .comment-form-area p {
  display: block;
  margin-bottom: 28px;
}

.blog-details-content .comment-form-area h2 a,
.blog-details-content .comment-form-area p a {
  color: inherit;
}

.blog-details-content .comment-form-area .form-group {
  position: relative;
  margin-bottom: 20px;
  padding: 0px 10px;
}

.blog-details-content .comment-form-area .form-group:last-child {
  margin-bottom: 0px;
}

.blog-details-content .comment-form-area .form-group input[type='text'],
.blog-details-content .comment-form-area .form-group input[type='email'],
.blog-details-content .comment-form-area .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  font-size: 17px;
  color: #7b7675;
  background: #fff;
  border: 1px dashed #fff;
  border-radius: 15px;
  padding: 10px 20px;
  transition: all 500ms ease;
}

.blog-details-content .comment-form-area .form-group textarea {
  padding-top: 15px;
  height: 120px;
  resize: none;
}

.blog-details-content .comment-form-area .form-group input:focus,
.blog-details-content .comment-form-area .form-group textarea:focus {}

.blog-details-content .comment-form-area form {
  position: relative;
  margin: 0px 5px;
}

.blog-details-content .comment-form-area .check-box {
  padding-bottom: 5px;
}


.chooseus-section .video-inner {
  position: relative;
  display: block;
  padding: 60px 30px 0px 0px;
}

.chooseus-section .video-inner .inner-box {
  position: relative;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 216px 0px;
  text-align: center;
  border-radius: 25px;
  overflow: hidden;
}

.chooseus-section .video-inner .inner-box .btn-box a {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 86px;
  background: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 30px;
}

.chooseus-section .video-inner .inner-box .btn-box a:before,
.chooseus-section .video-inner .inner-box .btn-box a:after {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation-delay: .9s;
  animation-delay: .9s;
  content: "";
  position: absolute;
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}

.about-style-two .video-inner .inner-box .video-btn:after {
  -webkit-animation-delay: .6s;
  animation-delay: .6s;
}


/** mission-section **/


.mission-section {
  position: relative;
  padding: 152px 0px 148px 0px;
}

.mission-section .image-box {
  position: relative;
  display: block;
  padding: 0px 32px;
  margin-right: 56px;
}

.mission-section .image-box .image {
  border-radius: 25px;
}

.mission-section .image-box .image img {
  width: 100%;
  border-radius: 25px;
}

.mission-section .image-box .image-1 {
  position: relative;
  z-index: 1;
}

.mission-section .image-box .image-2 {
  position: absolute;
  left: 32px;
  top: 0px;
  transform: rotate(10deg);
}

.mission-section .owl-carousel .owl-stage-outer {
  overflow: visible;
}

.mission-section .owl-carousel .owl-stage-outer .owl-item {
  opacity: 0;
}

.mission-section .owl-carousel .owl-stage-outer .owl-item.active {
  opacity: 1;
}

.mission-section .owl-nav {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: rotate(90deg);
  margin-top: -17px;
}

.mission-section .owl-nav button {
  background: transparent !important;
  width: auto;
  height: auto;
  line-height: 30px;
  margin: 0px 5px;
}

.mission-section .owl-nav button:hover {}

.mission-section .content-box {
  position: relative;
  display: block;
}

.mission-section .content-box .icon-box {
  position: relative;
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 30px;
  color: #fff;
  text-align: center;
  border-radius: 15px;
  margin-bottom: 43px;
}

.mission-section .content-box h6 {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 26px;
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 7px;
}

.mission-section .content-box h2 {
  position: relative;
  display: block;
  font-size: 48px;
  line-height: 58px;
  font-weight: 700;
  margin-bottom: 30px;
}

.mission-section .content-box p {
  position: relative;
  display: block;
  margin-bottom: 14px;
}

.mission-section .content-box .list-item li {
  position: relative;
  display: block;
  padding-left: 35px;
  margin-bottom: 8px;
}

.mission-section .content-box .list-item li:last-child {
  margin-bottom: 0px;
}

.mission-section .content-box .list-item li:before {
  position: absolute;
  content: "\f136";
  font-family: 'flaticon';
  font-size: 24px;
  left: 0px;
  top: 2px;
}


/** team-style-two **/

.team-style-two {
  position: relative;
  padding: 115px 0px 170px 0px;
}

.team-block-two .inner-box {
  position: relative;
  display: block;
  overflow: hidden;
}

.team-block-two .inner-box .image-box {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 25px;
}

.team-block-two .inner-box .image-box img {
  width: 100%;
  border-radius: 25px;
  transition: all 500ms ease;
}

.team-block-two .inner-box:hover .image-box img {
  transform: scale(1.05);
}

.team-block-two .inner-box h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 34px;
  /* font-weight: 700; */
  color: #fff;
}

.team-block-two .inner-box .text-box {
  /*position: absolute;*/
  margin-top: 20px;
  margin-left: 20px;
  left: 20px;
  bottom: 20px;
  width: calc(100% - 40px);
  padding: 10px 10px;
  text-align: center;
  border-radius: 30px;
  transition: all 500ms ease;
}

.team-block-two .inner-box:hover .text-box {
  opacity: 0;
}

.team-block-two .inner-box .overlay-content {
  position: absolute;
  left: 20px;
  bottom: 20px;
  width: calc(100% - 40px);
  border-radius: 25px;
  padding: 15px 15px 15px 15px;
  z-index: 1;
  transform: scaleY(0);
  transform-origin: bottom center;
  transition: all 500ms ease;
}

.team-block-two .inner-box:hover .overlay-content {
  transform: scaleY(1);
}

.team-block-two .inner-box .overlay-content h3 {
  margin-bottom: 5px;
}

.team-block-two .inner-box .overlay-content h3 a {
  color: #fff;
}

.team-block-two .inner-box .overlay-content h3 a:hover {
  text-decoration: underline;
}

.team-block-two .inner-box .overlay-content .designation {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  /* font-weight: 800; */
  text-transform: uppercase;
  margin-bottom: 7px;
}

.team-block-two .inner-box .overlay-content .social-links li {
  position: relative;
  display: inline-block;
  margin: 0px 15px;
}

.team-block-two .inner-box .overlay-content .social-links li a {
  position: relative;
  display: inline-block;
  font-size: 20px;
  color: #fff;
}

.team-block-two .inner-box .overlay-content .social-links li:before {
  position: absolute;
  content: '';
  background: #e7c9c4;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  top: 11px;
  right: -19px;
}

.team-block-two .inner-box .overlay-content .social-links li:last-child:before {
  display: none;
}

.team-style-two .owl-dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -61px;
  min-height: 40px;
  counter-reset: slides-num;
  margin-left: 37px;
}

.team-style-two .owl-dots::after {
  position: absolute;
  right: 8px;
  top: -1px;
  content: "0" counter(slides-num);
  display: inline-block;
  font-size: 16px;
  color: #afa7a7;
  font-weight: 800;
  font-family: 'Outfit', sans-serif;
}

.team-style-two .owl-dots:before {
  position: absolute;
  content: '';
  right: 35px;
  top: 7px;
  width: 1px;
  height: 12px;
  background: #a09ba9;
  transform: rotate(10deg);
}

.team-style-two .owl-dots button span {
  display: none !important;
}

.team-style-two .owl-dots button {
  position: absolute;
  top: -4px;
  right: 55px;
  counter-increment: slides-num;
  font-size: 24px;
  font-weight: 800;
  font-family: 'Outfit', sans-serif;
}

.team-style-two .owl-dots button.active:before {
  position: absolute;
  content: counter(slides-num);
}

.team-style-two .owl-dots button.active:after {
  position: absolute;
  content: '0';
  top: 0px;
  right: 0px;
}

.team-style-two .owl-nav button {
  background: transparent !important;
  width: auto;
  height: auto;
  line-height: 30px !important;
}

.team-style-two .owl-nav button:hover {}

.team-style-two .owl-nav button.owl-prev {
  top: 52px;
  left: -44px;
}

.team-style-two .owl-nav button.owl-next {
  top: 52px;
  right: -44px;
}

/*** 
=============================================
    Team details Style1 Css
=============================================
***/
.team-details-style1 {
  position: relative;
  display: block;
  background-color: #ffffff;
  padding: 120px 0px 111px;
  z-index: 10;
}

.team-details-style1__img {
  position: relative;
  display: block;
}

.team-details-style1__img .img-box {
  position: relative;
  display: block;
}

.team-details-style1__img .img-box img {
  width: 100%;
}

.team-details-style1__img .contact-info {
  position: relative;
  display: block;
  margin-top: 50px;
}

.team-details-style1__img .contact-info ul {
  position: relative;
  display: block;
}

.team-details-style1__img .contact-info ul li {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.team-details-style1__img .contact-info .inner {
  position: relative;
  display: flex;
  align-items: center;
}

.team-details-style1__img .contact-info .inner .icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: -webkit-linear-gradient(0deg, #fd6146, #fb7a43 50%);
  margin-right: 20px;
}

.team-details-style1__img .contact-info .inner .icon span {
  color: #1057b6;
  position: relative;
  display: inline-block;
  font-size: 20px;
  line-height: 20px;
}

.team-details-style1__img .contact-info .inner a {
  color: #7b7675;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: 0.03em;
  transition: all 200ms linear;
  transition-delay: 0.1s;
}

.team-details-style1__img .contact-info .inner a.mail {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
}

.team-details-style1__img .contact-info .inner .social-links {
  position: relative;
  display: block;
}

.team-details-style1__img .contact-info .inner .social-links a+a {
  margin-left: 20px;
}


.team-details-style1__img .contact-info .inner .social-links a i {
  position: relative;
  display: inline-block;
  font-size: 22px;
  line-height: 22px;
}




.team-details-style1__content {
  position: relative;
  display: block;
  margin-left: 70px;
}

.team-details-style1__content .inner-title {
  position: relative;
  display: block;
  margin-bottom: 20px;
}

.team-details-style1__content .inner-title h2 {
  font-size: 45px;
  line-height: 55px;

}

.team-details-style1__content .inner-title p {
  font-size: 18px;
  line-height: 28px;

}

.team-details-style1__content .inner-title p span {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 1px;
  background-color: #fd6145;
}

.team-details-style1__content .text {
  position: relative;
  display: block;
}

.team-details-style1__content .text p+p {
  position: relative;
  display: block;
  margin-top: 25px;
}


/** statements-section **/

.statements-section {
  position: relative;
  background: #0c0c0c;
}

.statements-section .pattern-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.statements-section .sec-title {
  margin-bottom: 0px;
}

.statements-section .image-box {
  position: relative;
  display: block;
  margin-left: -12px;
  padding-right: 75px;
}

.statements-section .image-box .image {
  position: relative;
  display: block;
  width: 294px;
  height: 294px;
  border-radius: 50%;
}

.statements-section .image-box .image img {
  width: 100%;
  border-radius: 50%;
}

.statements-section .image-box .image:before {
  position: absolute;
  content: '';
  background: #0c0c0c;
  width: 12px;
  height: 100%;
  top: 0px;
  right: 0px;
  z-index: 1;
}

.statements-section .image-box .big-text {
  position: absolute;
  top: 25px;
  right: 47px;
  font-size: 100px;
  line-height: 100px;
  font-family: 'Outfit', sans-serif;
  font-weight: 800;
  z-index: 1;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(255, 255, 255, 1);
}

.statements-section .image-box h3 {
  position: absolute;
  right: 0px;
  bottom: 40px;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  background: #fff;
  padding: 10px 30px;
  border-radius: 40px;
  width: 270px;
  text-align: center;
  z-index: 1;
}

.statements-section .content-box {
  position: relative;
  display: block;
}

.statements-section .content-box p {
  color: #aca7a7;
  margin-bottom: 14px;
}

.statements-section .content-box .list-item li {
  position: relative;
  display: block;
  padding-left: 30px;
  margin-bottom: 8px;
  color: #aca7a7;
}

.statements-section .content-box .list-item li:last-child {
  margin-bottom: 0px;
}

.statements-section .content-box .list-item {
  margin-bottom: 30px;
}

.statements-section .content-box .list-item li:before {
  position: absolute;
  content: "\f136";
  font-family: 'flaticon';
  font-size: 24px;
  left: 0px;
  top: 2px;
}

.statements-section .content-box h6 {
  position: relative;
  display: block;
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
  text-transform: uppercase;
}

.statements-section .content-box h6 a {
  display: inline-block;
  color: #fff;
  padding-left: 30px;
}

.statements-section .content-box h6 a i {
  position: absolute;
  left: 0px;
  top: -1px;
  font-size: 24px;
  font-weight: 400;
}

.statements-section .owl-nav {
  position: absolute;
  left: -144px;
  bottom: 0px;
}

.statements-section .owl-nav button {
  background: transparent;
  border: 1px dashed rgba(255, 255, 255, 0.25);
  color: #fff;
}


.team-section.team-page {
  padding-bottom: 70px;
}

.team-section.team-page .team-block-one .inner-box {
  margin-bottom: 42px;
}


/** project-style-three **/

.project-style-three {
  position: relative;
  padding: 120px 0px;
}

.project-style-three .outer-container {
  position: relative;
  padding: 0px 75px;
}

.project-style-three .filter-tabs li {
  position: relative;
  display: inline-block;
  font-size: 18px;
  line-height: 28px;
  font-family: 'Outfit', sans-serif;
  color: #0c0c0c;
  font-weight: 700;
  border: 1px dashed #d8d2d0;
  border-radius: 35px;
  padding: 12px 30px;
  cursor: pointer;
  margin: 0px 3px;
  transition: all 500ms ease;
}

.project-style-three .filter-tabs li.active {
  background: #faf1ef;
  border-color: #faf1ef;
}

.project-style-three .sortable-masonry .filters-box {
  margin-bottom: 60px;
}

.project-style-three .project-block-two .inner-box {
  margin-bottom: 30px;
}

.project-style-three .more-btn {
  padding-top: 30px;
}


/** project-style-four **/

.project-style-four {
  position: relative;
  padding: 120px 0px;
}

.project-block-three .inner-box {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 25px;
  margin-bottom: 30px;
}

.project-block-three .inner-box .image-box {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 25px;
}

.project-block-three .inner-box .image-box img {
  width: 100%;
  border-radius: 25px;
  transition: all 500ms ease;
}

.project-block-three .inner-box:hover .image-box img {
  transform: scale(1.05);
  opacity: 0.1;
}

.project-block-three .inner-box .content-box {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  transform: scale(0, 0);
  transition: all 500ms ease;
}

.project-block-three .inner-box:hover .content-box {
  transform: scale(1, 1);
}

.project-block-three .inner-box .content-box h6 {
  position: absolute;
  left: 30px;
  top: 23px;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
}

.project-block-three .inner-box .content-box h3 {
  position: absolute;
  font-size: 24px;
  line-height: 34px;
  font-weight: 700;
  left: 30px;
  bottom: 23px;
}

.project-block-three .inner-box .content-box h3 a {
  display: inline-block;
  color: #fff;
}

.project-block-three .inner-box .content-box h3 a:hover {
  text-decoration: underline;
}

.project-block-three .inner-box .view-btn {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.project-block-three .inner-box .view-btn a {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 54px;
  background: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 16px;
  color: #0c0c0c;
}

.project-block-three .inner-box .view-btn a:hover {
  color: #fff;
  background: #0c0c0c;
}

.project-style-four .pagination-wrapper {
  padding-top: 30px;
}


/** project-details **/

.project-details {
  position: relative;
  /*padding: 120px 0px;*/
}

.project-details h2 {
  position: relative;
  display: block;
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  margin-bottom: 21px;
}

.project-details .upper-text {
  position: relative;
  display: block;
  margin-bottom: 65px;
}

.project-details .two-column .image-box {
  position: relative;
  display: block;
  margin-right: 31px;
  margin-top: 7px;
}

.project-details .two-column .image-box img {
  width: 100%;
}

.project-details .two-column .image-box .image-shape .shape-1 {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 370px;
  height: 370px;
  background: #faf1ef;
  border-radius: 50%;
  z-index: -1;
}

.project-details .two-column .image-box .image-shape .shape-2 {
  position: absolute;
  left: 70px;
  bottom: 0px;
  width: 149px;
  height: 120px;
  background-repeat: no-repeat;
}

.project-details .two-column .content-box {
  position: relative;
  display: block;
}

.project-details .two-column .content-box p {
  margin-bottom: 2px;
}

.project-details .two-column .content-box .list-item li {
  position: relative;
  display: block;
  padding-left: 35px;
  margin-bottom: 8px;
}

.project-details .two-column .content-box .list-item li:last-child {
  margin-bottom: 0px;
}

.project-details .two-column .content-box .list-item li:before {
  position: absolute;
  content: "\f136";
  font-family: 'flaticon';
  font-size: 24px;
  left: 0px;
  top: 2px;
}

.project-details .two-column {
  margin-bottom: 80px;
}

.project-details .project-info-box {
  position: relative;
  display: block;
  margin-bottom: 80px;
}

.project-details .project-info-box .info-list li {
  position: relative;
  display: block;
  float: left;
  width: 25%;
  padding-left: 80px;
}

.project-details .project-info-box .info-list li span {
  position: absolute;
  left: 15px;
  top: 1px;
  font-size: 18px;
  line-height: 28px;
  font-family: 'Outfit', sans-serif;
  color: #afa7a7;
  font-weight: 800;
}

.project-details .project-info-box .info-list li h5 {
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 4px;
}

.project-details .project-info-box .info-list li p {
  position: relative;
  display: block;
}

.project-details .project-info-box .info-list li:before {
  position: absolute;
  content: '';
  border-left: 1px dashed #d1d1d1;
  left: 0px;
  top: 0px;
  width: 1px;
  height: 60px;
}

.project-details .project-info-box .info-list li:last-child:after {
  position: absolute;
  content: '';
  border-left: 1px dashed #d1d1d1;
  right: 0px;
  top: 0px;
  width: 1px;
  height: 60px;
}

.project-details .project-image {
  position: relative;
  display: block;
}

.project-details .project-image .image {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 25px;
  margin-bottom: 30px;
}

.project-details .project-image .image:last-child {
  margin-bottom: 0px;
}

.project-details .project-image .image img {
  width: 100%;
  padding: 0px 0px 0px 0px;
  /* border-radius: 25px; */
  transition: all 500ms ease;
}

.project-details .project-image .image:hover img {
  transform: scale(1.05);
}


/** upload-file-section **/

.upload-file-section {
  position: relative;
}

.upload-file-section .inner-box {
  position: relative;
  display: block;
  background: #fff;
  padding: 59px 59px 59px 59px;
  border: 1px dashed #d8d2d0;
  border-radius: 25px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
}

.upload-file-section .inner-box .upper-box .form-group {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.upload-file-section .inner-box .upper-box .form-group input[type='text'],
.upload-file-section .inner-box .upper-box .form-group input[type='email'],
.upload-file-section .inner-box .upper-box .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  background: #faf1ef;
  border-radius: 15px;
  border: 1px dashed #faf1ef;
  font-size: 17px;
  color: #7b7675;
  padding: 10px 20px;
  padding-right: 50px;
  transition: all 500ms ease;
}

.upload-file-section .inner-box .upper-box .form-group textarea {
  height: 180px;
  resize: none;
  padding-top: 15px;
}

.upload-file-section .inner-box .upper-box .form-group input:focus,
.upload-file-section .inner-box .upper-box .form-group textarea:focus {}

.upload-file-section .inner-box .upper-box .form-group .icon {
  position: absolute;
  top: 16px;
  right: 20px;
  font-size: 16px;
  color: #afa7a7;
  z-index: 1;
}

.upload-file-section .inner-box .lower-box {
  position: relative;
  display: block;
  background: #faf1ef;
  padding: 15px 20px 40px 20px;
  border-radius: 15px;
}

.upload-file-section .inner-box .lower-box .upload-box .text-box {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 17px;
}

.upload-file-section .inner-box .lower-box .upload-box .upload-file {
  position: relative;
  display: block;
  background: #fff;
  border: 1px dashed #d8d2d0;
  border-radius: 40px;
  padding: 13px 10px 17px 50px;
}

.upload-file-section .inner-box .lower-box .upload-box .upload-file .icon-box {
  position: absolute;
  left: 19px;
  top: 17px;
  font-size: 24px;
}

.upload-file-section .inner-box .lower-box .upload-box .upload-file p {
  color: #0c0c0c;
}

.upload-file-section .inner-box .lower-box .upload-box .upload-file p span {
  color: #afa7a7;
}

.upload-file-section .inner-box .lower-box .upload-box .input-box input {
  display: none;
}

.upload-file-section .inner-box .lower-box .upload-box .input-box label {
  position: absolute;
  display: inline-block;
  top: 6px;
  right: 6px;
  font-size: 14px;
  line-height: 24px;
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  padding: 11px 20px;
  border-radius: 30px;
  cursor: pointer;
  transition: all 500ms ease;
}

.upload-file-section .inner-box .lower-box .upload-box {
  margin-bottom: 27px;
}

.upload-file-section .inner-box .lower-box .load-content {
  position: relative;
  display: block;
}

.upload-file-section .inner-box .lower-box .load-content .close-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  color: #afa7a7;
  font-size: 14px;
  cursor: pointer;
  z-index: 1;
  transition: all 500ms ease;
}

.upload-file-section .inner-box .lower-box .load-content .close-btn:hover {}

.upload-file-section .inner-box .lower-box .load-content .load-file {
  position: relative;
  padding-left: 65px;
  margin-bottom: 17px;
}

.upload-file-section .inner-box .lower-box .load-content .load-file .icon-box {
  position: absolute;
  display: inline-block;
  left: 0px;
  top: 3px;
  width: 46px;
  height: 46px;
  line-height: 52px;
  background: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 24px;
}

.upload-file-section .inner-box .lower-box .load-content .load-file h5 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
}

.upload-file-section .inner-box .lower-box .load-content .load-file span {
  position: relative;
  display: block;
  font-size: 17px;
  line-height: 24px;
  color: #7b7675;
}

.upload-file-section .inner-box .lower-box .load-content .bar-inner {
  position: relative;
  width: 100%;
  height: 8px;
  background: #ece1de;
  border-radius: 20px;
}

.upload-file-section .inner-box .lower-box .load-content .bar-inner:before {
  position: absolute;
  content: '';
  width: 46%;
  height: 100%;
  left: 0px;
  top: 0px;
  border-radius: 20px;
}

.upload-file-section .inner-box .lower-box .load-content .bar-inner .percentage-box {
  position: absolute;
  top: -40px;
  right: 0px;
  font-size: 17px;
  color: #7b7675;
}

.upload-file-section .inner-box .message-btn {
  margin-top: 30px;
}


/*.pricing-section.style-two{*/
/*  background: -webkit-linear-gradient(0deg, #faecdb, #faf1ee 100%);*/
/*}*/

.pricing-section.style-two .tab-btn-box .tab-btns {
  margin: 0 auto;
}

.pricing-section.style-two .content-box .offer-box {
  max-width: 383px;
  margin: 0 auto;
  text-align: right;
}

.pricing-section.style-two .content-box .offer-box:before {
  display: none;
}



.faq-page-section {
  background: #fff;
  padding: 120px 0px;
}

.faq-page-section .accordion-inner {
  background: #fff;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
}

.faq-page-section .accordion-box .block .acc-btn.active h5 {}

.faq-page-section .accordion-box .block .acc-content {
  padding-top: 22px;
  padding-right: 40px;
}

.faq-page-section .support-box {
  position: relative;
  display: block;
  background: -webkit-linear-gradient(0deg, #eef9ec, #faf1ef 100%);
  padding: 33px 30px 37px 110px;
  border-radius: 25px;
  overflow: hidden;
  margin-top: 50px;
}

.faq-page-section .support-box .icon-box {
  position: absolute;
  display: inline-block;
  left: 40px;
  top: 40px;
  width: 54px;
  height: 54px;
  line-height: 54px;
  text-align: center;
  border-radius: 50%;
}

.faq-page-section .support-box h3 {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 36px;
  font-weight: 800;
}

.faq-page-section .support-box h3 a {
  display: inline-block;
  color: #0c0c0c;
}

.faq-page-section .support-box h3 a:hover {}

.faq-page-section .support-box .shape {
  position: absolute;
  top: 0px;
  right: 10px;
  width: 556px;
  height: 100%;
  background-repeat: no-repeat;
}



/** comingsoon-section **/


.comingsoon-section {
  position: fixed;
  padding: 120px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: -webkit-linear-gradient(0deg, #faecdb, #faf1ee 100%);
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.comingsoon-section .content-box {
  position: relative;
  display: block;
}

.comingsoon-section .big-text {
  position: absolute;
  top: 250px;
  font-size: 180px;
  line-height: 180px;
  font-weight: 900;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.06);
  width: 100%;
  margin: 0 auto;
}

.comingsoon-section .content-box .logo-box {
  position: relative;
  display: block;
  margin-bottom: 49px !important;
}

.comingsoon-section .content-box h2 {
  position: relative;
  display: block;
  font-size: 60px;
  line-height: 70px;
  font-weight: 700;
  margin-bottom: 18px;
}

.comingsoon-section .content-box p {
  display: block;
  margin-bottom: 26px;
}

.comingsoon-section .cs-countdown {
  position: relative;
  display: block;
  margin-bottom: 46px;
}

.comingsoon-section .cs-countdown .count-col {
  position: relative;
  display: inline-block;
  margin: 0px 45px;
}

.comingsoon-section .cs-countdown .count-col:before,
.comingsoon-section .cs-countdown .count-col:after {
  position: absolute;
  content: '';
  width: 10px;
  height: 10px;
  right: -53px;
  border-radius: 50%;
}

.comingsoon-section .cs-countdown .count-col:before {
  top: 40px;
}

.comingsoon-section .cs-countdown .count-col:after {
  bottom: 75px;
}

.comingsoon-section .cs-countdown .count-col:last-child:before,
.comingsoon-section .cs-countdown .count-col:last-child:after {
  display: none;
}

.comingsoon-section .cs-countdown .count-col:last-child {
  margin-bottom: 0px;
}

.comingsoon-section .cs-countdown .count-col span {
  position: relative;
  display: block;
  font-size: 120px;
  line-height: 120px;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
  color: #0c0c0c;
  margin-bottom: 3px;
}

.comingsoon-section .cs-countdown .count-col h6 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 28px;
  color: #7b7675;
  font-weight: 700;
  text-transform: capitalize;
}

.comingsoon-section .content-box .email-box h5 {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  margin-bottom: 22px;
}

.comingsoon-section .content-box form .form-group {
  position: relative;
  display: block;
  max-width: 570px;
  margin: 0 auto;
  margin-bottom: 50px;
}

.comingsoon-section .content-box form .form-group input[type='email'] {
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  background: #fff;
  border-radius: 40px;
  padding: 10px 60px 10px 30px;
  border: 1px solid #fff;
  font-size: 16px;
  color: #aca7a7;
}

.comingsoon-section .content-box form .form-group button[type='submit'] {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 58px;
  background: #0c0c0c;
  font-size: 30px;
  color: #fff;
  text-align: center;
  cursor: pointer;
  top: 5px;
  right: 5px;
  border-radius: 50%;
  transition: all 500ms ease;
}

.comingsoon-section .content-box form .form-group input:focus {}

.comingsoon-section .content-box form .form-group input:focus+button,
.comingsoon-section .content-box form .form-group button[type='submit']:hover {}

.comingsoon-section .content-box .social-links li {
  position: relative;
  display: inline-block;
  margin: 0px 8px;
}

.comingsoon-section .content-box .social-links li a {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border: 1px dashed #cac2b9;
  font-size: 20px;
  color: #afa7a7;
  text-align: center;
  border-radius: 50%;
}

.comingsoon-section .content-box .social-links li a:hover {
  color: #fff;
}



/** error-section **/

.error-section {
  position: fixed;
  padding: 250px 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.error-section .content-box {
  position: relative;
  display: block;
}

.error-section .content-box .image-box {
  position: relative;
  display: block;
  margin-bottom: 43px;
}

.error-section .content-box .image-box h3 {
  position: absolute;
  left: -87px;
  top: 20px;
  width: 100%;
  font-size: 36px;
  line-height: 46px;
  font-weight: 600;
  text-align: left;
}

.error-section .content-box h2 {
  display: block;
  font-size: 36px;
  line-height: 46px;
  font-weight: 600;
  margin-bottom: 20px;
}

.error-section .content-box p {
  font-size: 19px;
  line-height: 30px;
  display: block;
  margin-bottom: 28px;
}

.error-section .scroll-text {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
}

.error-section .scroll-text .text-box-one,
.error-section .scroll-text .text-box-two {
  position: absolute;
  width: 100%;
  min-width: 5500px;
  padding: 20px 0px;
}

.error-section .scroll-text .text-box-one:before {
  position: absolute;
  content: '';
  background: -webkit-linear-gradient(0deg, #eef9ec, #faf1ef 100%);
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  top: 0px;
  right: 0px;
  height: 100%;
}

.error-section .scroll-text .text-box-two:before {
  position: absolute;
  content: '';
  background: -webkit-linear-gradient(0deg, #eef9ec, #faf1ef 100%);
  max-width: 2200px;
  width: 100%;
  left: 0px;
  top: 0px;
  right: 0px;
  height: 100%;
}

.error-section .scroll-text .text-box-one {
  right: 0px;
}

.error-section .scroll-text .text-box-two {
  left: 0px;
}

.error-section .scroll-text .text-inner.text-one {
  -moz-animation: scroll-right 90s linear infinite;
  -webkit-animation: scroll-right 90s linear infinite;
  animation: scroll-right 90s linear infinite;
}

.error-section .scroll-text .text-inner.text-two {
  -moz-animation: scroll-left 90s linear infinite;
  -webkit-animation: scroll-left 90s linear infinite;
  animation: scroll-left 90s linear infinite;
}

@-moz-keyframes scroll-left {
  0% {
    -moz-transform: translateX(0%);
  }

  100% {
    -moz-transform: translateX(-100%);
  }
}

@-webkit-keyframes scroll-left {
  0% {
    -webkit-transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(-100%);
  }
}

@-moz-keyframes scroll-right {
  0% {
    -moz-transform: translateX(0%);
  }

  100% {
    -moz-transform: translateX(100%);
  }
}

@-webkit-keyframes scroll-right {
  0% {
    -webkit-transform: translateX(0%);
  }

  100% {
    -webkit-transform: translateX(100%);
  }
}

.error-section .scroll-text .text-box-one {
  top: 0px;
}

.error-section .scroll-text .text-box-two {
  bottom: 0px;
}

.error-section .scroll-text h3 {
  position: relative;
  display: inline-block;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #272e39;
  margin: 0px 22px;
}

.error-section .image-box {
  position: relative;
  display: block;
}

.error-section .image-box .image {
  position: relative;
  display: inline-block;
}

.error-section .image-box .image img {
  width: 100%;
}

.error-section .image-box .big-text {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 200px;
  line-height: 170px;
  font-family: 'Outfit', sans-serif;
  font-weight: 700;
  color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #eee6e5;
}

.error-section .content-box {
  position: relative;
  display: block;
}

.error-section .content-box h2 {
  position: relative;
  display: block;
  font-size: 72px;
  line-height: 80px;
  font-weight: 700;
  margin-bottom: 27px;
}

.error-section .content-box h3 {
  position: relative;
  display: block;
  font-size: 36px;
  line-height: 46px;
  font-weight: 700;
  margin-bottom: 9px;
}

.error-section .content-box p {
  margin-bottom: 31px;
}

.error-section .content-box .form-inner {
  position: relative;
  margin-bottom: 40px;
}

.error-section .content-box .form-inner .form-group {
  position: relative;
  margin: 0px;
}

.error-section .content-box .form-inner .form-group input[type='email'] {
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  border: 1px dashed #d8d2d0;
  border-radius: 40px;
  font-size: 16px;
  color: #aca7a7;
  padding: 10px 60px 10px 30px;
  transition: all 500ms ease;
}

.error-section .content-box .form-inner .form-group button[type='submit'] {
  position: absolute;
  top: 5px;
  right: 5px;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 58px;
  font-size: 30px;
  color: #fff;
  background: #0c0c0c;
  text-align: center;
  border-radius: 50%;
  transition: all 500ms ease;
}

.error-section .content-box .form-inner .form-group input:focus {}

.error-section .content-box .form-inner .form-group input:focus+button,
.error-section .content-box .form-inner .form-group button:hover {}


/** info-section **/

.info-section {
  position: relative;
  padding-top: 120px;
}

.info-block-one .inner-box {
  position: relative;
  display: block;
  background: #fff;
  border: 1px dashed #d8d2d0;
  border-radius: 15px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.08);
  padding: 46px 30px 42px 30px;
}

.info-block-one .inner-box .icon-box {
  position: relative;
  display: inline-block;
  font-size: 35px;
  margin-bottom: 35px;
  z-index: 1;
}

.info-block-one .inner-box .icon-box:before {
  position: absolute;
  content: '';
  background-image: url(./../images/shape/shape-43.png);
  width: 59px;
  height: 50px;
  left: -15px;
  top: 3px;
  background-repeat: no-repeat;
  z-index: -1;
}

.info-block-one .inner-box p {
  position: relative;
  display: block;
  margin-bottom: 14px;
}

.info-block-one .inner-box h4 {
  position: relative;
  display: block;
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
}

.info-block-one .inner-box h4 a {
  display: inline-block;
  color: #0c0c0c;
}

.info-block-one .inner-box h4 a:hover {}

.info-block-one .inner-box h6 {
  position: relative;
  display: block;
  margin-top: 18px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
  text-transform: uppercase;
}

.info-block-one .inner-box h6 a {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  color: #0c0c0c;
}

.info-block-one .inner-box h6 a:hover {}

.info-block-one .inner-box h6 a i {
  position: absolute;
  left: 0px;
  top: -1px;
  font-size: 24px;
  font-weight: 400;

}


/** contact-section **/

.contact-section {
  position: relative;
}

.contact-section .form-inner {
  position: relative;
  display: block;
  background: #faf1ef;
  padding: 40px 60px;
  border-radius: 25px;
}

.contact-section .form-inner .form-group {
  position: relative;
  margin-bottom: 30px;
}

.contact-section .form-inner .form-group:last-child {
  margin-bottom: 0px;
}

.contact-section .form-inner .form-group input[type='text'],
.contact-section .form-inner .form-group input[type='number'],
.contact-section .form-inner .form-group input[type='email'],
.contact-section .form-inner .form-group textarea {
  position: relative;
  display: block;
  width: 100%;
  height: 60px;
  background: #fff;
  border: 1px dashed #fff;
  border-radius: 15px;
  font-size: 17px;
  color: #7b7675;
  padding: 10px 50px 10px 20px;
  transition: all 500ms ease;
}

.contact-section .form-inner .form-group .icon-box {
  position: absolute;
  top: 15px;
  right: 37px;
  font-size: 16px;
  color: #afabab;
  z-index: 1;
}

.contact-section .form-inner .form-group input:focus,
.contact-section .form-inner .form-group textarea:focus {}

.contact-section .form-inner .form-group textarea {
  resize: none;
  padding-top: 15px;
  height: 180px;
}

.tech_cl {
  color: #000 !important;
}

/* ========================================== */
.card__header {
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  margin-top: 0rem;
  border-radius: calc(var(--curve) * 1px) 0 0 0;
  background-color: var(--surface-color);
  transform: translateY(-100%);
  transition: .2s ease-in-out;
}

.card__arc {
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1;
}

.card__arc path {
  fill: var(--surface-color);
  d: path("M 40 80 c 22 0 40 -22 40 -40 v 40 Z");
}

.card:hover .card__header {
  transform: translateY(0);
}

.card__thumb {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.card__title {
  font-size: 1em;
  margin: 0 0 .3em;
  color: #6A515E;
}

.card__tagline {
  display: block;
  margin: 1em 0;
  font-family: 'Outfit', sans-serif;
  font-size: .8em;
  color: #D7BDCA;
}

.card__status {
  font-size: .8em;
  color: #D7BDCA;
}

.card__description {
  padding: 0 2em 2em;
  margin: 0;
  color: #D7BDCA;
  font-family: 'Outfit', sans-serif;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.current>a {
  color: red;
}

.custom-backdrop {
  background-color: rgba(0, 0, 0, 0.75);
}